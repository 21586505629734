import * as api from '../../../shared/api'

export interface SshKey {
  id: number
  key: string
}

export const list = (): Promise<{ data: SshKey[] }> => {
  return api
    .get<{ data: SshKey[] }>('/frontend/api/ssh_keys')
    .then((response): {
      data: SshKey[]
    } => {
      const sshKeys = response.data.data
      return { data: sshKeys }
    })
}

export const create = (
  sshKey: string
): Promise<{ data: Record<string, unknown> }> => {
  return api
    .post<{ data: Record<string, unknown> }>('/frontend/api/ssh_keys', {
      ssh_key: sshKey,
    })
    .then(() => ({
      data: {},
    }))
}

export const del = (id: number): Promise<{ data: Record<string, unknown> }> => {
  return api
    .del<{ data: Record<string, unknown> }>('/frontend/api/ssh_keys', { id })
    .then(() => ({
      data: {},
    }))
}
