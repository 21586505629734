import React, { FunctionComponent } from 'react'
import { useMutation, useRefresh, useQueryWithStore } from 'ra-core'
import { FORM_ERROR } from 'final-form'
import { Field, Form } from 'react-final-form'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  makeStyles,
} from '@material-ui/core'
import { Canary } from './actions'
import { App } from '../Dashboard/actions'
import {
  extractError,
  extractErrorValue,
} from '../../../shared/errorExtractors'
import Loading from '../../../shared/components/Loading'
import {
  renderTextField,
  renderAutocompleteField,
} from '../../../shared/components/formFields'
import ErrorComponent from '../../../shared/components/ErrorComponent'
import CancelButton from '../../../shared/components/CancelButton'
import SubmitButton from '../../../shared/components/SubmitButton'
import useMaybeNotify from '../../../shared/hooks/useMaybeNotify'
import {
  composeValidators,
  required,
  minValue,
  maxValue,
} from '../../../utils/validators'

const validateCanary = composeValidators([required()])
const validateWeight = composeValidators([
  required(),
  minValue(0, 'Must be 0 or greater'),
  maxValue(100, 'Must be 100 or smaller'),
])

const renderCanaryField = renderAutocompleteField
const renderWeightField = renderTextField({ type: 'number' })

interface Props {
  onSuccess: () => void
  onCancel: () => void
  appId: string
  initialValues?: Canary
}

interface FormData {
  canary: string
  weight: string
}

const useStyles = makeStyles({
  clearIndicator: {
    display: 'none',
  },
  canaryWrapper: {
    position: 'relative',
  },
  loaderWrapper: {
    height: 'unset',
    position: 'absolute',
    right: -10,
    bottom: 5,
  },
  loader: {
    height: '1em !important',
    width: '1em !important',
  },
})

const UpsertDialog: FunctionComponent<Props> = (props) => {
  const { onSuccess, onCancel, appId, initialValues } = props
  const [mutate] = useMutation()
  const notify = useMaybeNotify()
  const refresh = useRefresh()

  const submit = ({ canary, weight }: FormData) => {
    const updatedCanary = {
      canary,
      weight: parseInt(weight, 10),
    }
    return new Promise((resolve) => {
      mutate(
        {
          type: 'update',
          resource: 'canaries',
          payload: { id: appId, data: updatedCanary, previousData: {} },
        },
        {
          onSuccess: () => {
            notify(`Canary ${initialValues ? 'updated' : 'added'}`)
            onSuccess()
            resolve()
            refresh()
          },
          onFailure: ({ body: { errors } }) => {
            resolve({
              [FORM_ERROR]: extractError(errors, ''),
              canary: extractErrorValue(errors, 'canary'),
              weight: extractErrorValue(errors, 'weight'),
            })
          },
        }
      )
    })
  }

  const {
    data: apps,
    loading,
  }: { data?: App[]; loading: boolean } = useQueryWithStore({
    type: 'getList',
    resource: 'apps',
    payload: {},
  })

  const classes = useStyles()

  return (
    <Form
      onSubmit={submit}
      render={({
        handleSubmit,
        submitError,
        hasSubmitErrors,
        hasValidationErrors,
        modifiedSinceLastSubmit,
        pristine,
        submitting,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">
              {`${initialValues ? 'Edit' : 'Add'} Canary`}
            </DialogTitle>
            {submitError && (
              <DialogContent>
                <FormHelperText error>
                  <ErrorComponent>{submitError}</ErrorComponent>
                </FormHelperText>
              </DialogContent>
            )}
            <DialogContent>
              <div className={classes.canaryWrapper}>
                <Field
                  validate={validateCanary}
                  component={renderCanaryField}
                  name="canary"
                  label="Canary App Name"
                  options={
                    apps
                      ?.filter((app) => app.id !== appId)
                      .map((app) => app.id) || []
                  }
                  freeSolo
                  fullWidth
                  initialValue={initialValues?.canary}
                  classes={{ clearIndicator: classes.clearIndicator }}
                />
                {loading && (
                  <Loading
                    classes={{
                      container: classes.loaderWrapper,
                      icon: classes.loader,
                    }}
                  />
                )}
              </div>
            </DialogContent>
            <DialogContent>
              <Field
                validate={validateWeight}
                component={renderWeightField}
                name="weight"
                label="Weight"
                initialValue={`${initialValues?.weight}`}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <CancelButton onClick={onCancel} />
              <SubmitButton
                {...{
                  hasValidationErrors,
                  hasSubmitErrors,
                  modifiedSinceLastSubmit,
                  pristine,
                  submitting,
                }}
                label={initialValues ? 'Update' : 'Add'}
                variant="contained"
                size="small"
              />
            </DialogActions>
          </form>
        )
      }}
    />
  )
}

export default UpsertDialog
