import * as api from '../../../shared/api'

export const updateEmail = (
  email: string,
  password: string
): Promise<Record<string, unknown>> => {
  return api
    .post<{ data: Record<string, string> }>('/frontend/api/users/email', {
      next_email: email,
      current_password: password,
    })
    .then(() => ({ data: {} }))
}

export const changePassword = (
  currentPassword: string,
  newPassword: string
): Promise<{ data: Record<string, unknown> }> => {
  return api
    .post<{ data: Record<string, string> }>('/frontend/api/users/password', {
      current_password: currentPassword,
      new_password: newPassword,
    })
    .then(() => ({
      data: {},
    }))
}

export const upgrade = (token: string, promoCode: string): Promise<Record<string, unknown>> => {
  return api
    .post('/frontend/api/upgrade', {
      promo_code: promoCode,
      stripe_token: token,
    })
    .then((): { data: { id: string } } => {
      return { data: { id: token } }
    })
}
