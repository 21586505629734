import * as api from '../../../shared/api'

interface ServerResponse {
  // eslint-disable-next-line camelcase
  period_start: string
  // eslint-disable-next-line camelcase
  period_end: string
  // eslint-disable-next-line camelcase
  amount_cents: number
  pdf: string
}

type Url = string
type Money = number

export interface Invoice {
  id: string // not really used, but required by react-admin
  periodStart: Date
  periodEnd: Date
  amountCents: Money
  pdf: Url
}

export const pdfs = (): Promise<{ data: Invoice[]; total: number }> => {
  return api
    .get<{ data: ServerResponse[] }>(`/frontend/api/invoices/pdfs`)
    .then((response) => {
      const invoices = response.data.data
      return {
        total: invoices.length,
        data: invoices.map((invoice) => {
          return {
            id: invoice.pdf,
            pdf: invoice.pdf,
            amountCents: invoice.amount_cents,
            periodStart: new Date(invoice.period_start),
            periodEnd: new Date(invoice.period_end),
          }
        }),
      }
    })
}
