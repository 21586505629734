import React, { FunctionComponent } from 'react'
import { useQueryWithStore } from 'ra-core'
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core'
import { Activity } from './actions'
import RollbackDialog from './RollbackDialog'
import Loading from '../../../shared/components/Loading'
import ErrorComponent from '../../../shared/components/ErrorComponent'
import NoData from '../../../shared/components/NoData'
import TableWrapper from '../../../shared/components/TableWrapper'
import DialogButton from '../../../shared/components/DialogButton'
import Field from '../../../shared/components/Field'
import dateFormatter from '../../../utils/formatters/dateFormatters'

interface Props {
  appId: string
}

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    marginTop: 0,
    height: `calc(100% - ${theme.spacing(5)}px)`,
  },
  createdAt: {
    whiteSpace: 'nowrap',
    width: 170,
  },
  action: {
    width: 70,
  },
  info: {
    border: '1px solid rgba(0,0,0,0.5)',
    borderRadius: 4,
    display: 'inline-flex',
    margin: theme.spacing(0.25),
    padding: theme.spacing(0.25, 0.5),
    '& div:first-child': {
      textTransform: 'capitalize',
      paddingBottom: 0,
    },
  },
}))

const rollbackDialogButton = (activity: Activity, appId: string) => {
  const {
    properties: { version },
  } = activity
  if (typeof version === 'number') {
    return (
      <DialogButton label="Rollback">
        {(close) => (
          <RollbackDialog
            onSuccess={() => close()}
            onCancel={() => close()}
            appId={appId}
            version={version}
          >
            {`Are you sure want to rollback to version ${activity.properties.version}?`}
          </RollbackDialog>
        )}
      </DialogButton>
    )
  }
  return <span />
}

const Activities: FunctionComponent<Props> = (props) => {
  const { appId } = props

  const {
    data: activities,
    loading,
    error,
  }: {
    data?: Activity[]
    loading: boolean
    error?: Error
  } = useQueryWithStore({
    type: 'GET_LIST_BY_ID',
    resource: 'activities',
    payload: {
      id: appId,
    },
  })

  const classes = useStyles()

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <ErrorComponent>{error.message}</ErrorComponent>
  }

  if (!(activities && activities.length > 0)) {
    return <NoData message="No activities recorded yet." />
  }

  return (
    <TableWrapper className={classes.tableWrapper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Created At</TableCell>
            <TableCell>Action</TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {activities.map((activity) => (
            <TableRow key={dateFormatter(activity.createdAt)}>
              <TableCell className={classes.createdAt}>
                {dateFormatter(activity.createdAt)}
              </TableCell>
              <TableCell className={classes.action}>
                {activity.action}
              </TableCell>
              <TableCell>
                {Object.keys(activity.properties).map((property) => {
                  let value = activity.properties[property]
                  if (property === 'sha' && typeof value === 'string') {
                    value = value.slice(0, 6)
                  }
                  return (
                    <Field
                      className={classes.info}
                      label={property}
                      isHorizontal
                    >
                      {value}
                    </Field>
                  )
                })}
              </TableCell>
              <TableCell align="right">
                {activity.action === 'release' &&
                  rollbackDialogButton(activity, appId)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  )
}

export default Activities
