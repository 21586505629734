import * as logDrains from './actions'
import {
  CreateLogDrainParams,
  DeleteLogDrainParams,
} from './dataProviderParams'
import { CreateParams } from '../../../shared/dataProvider/interfaces'
import { isGetListByID } from '../../../shared/dataProvider/typeCheckers'
import { Method, Resource, Params } from '../../../shared/dataProvider/types'

const isCreateLogDrain = (
  method: Method,
  resource: Resource,
  params: Params
): params is CreateParams<CreateLogDrainParams> =>
  method === 'CREATE' && resource === 'log_drains'

const isDeleteLogDrain = (
  method: Method,
  resource: Resource,
  params: Params
): params is DeleteLogDrainParams =>
  method === 'DELETE' && resource === 'log_drains'

const provider = (
  method: Method,
  resource: Resource,
  params: Params
): Promise<unknown> => {
  if (isDeleteLogDrain(method, resource, params)) {
    return logDrains.del(params.appId, params.id)
  }
  if (isGetListByID(method, resource, params)) {
    return logDrains.list(params.id)
  }
  if (isCreateLogDrain(method, resource, params)) {
    const { appId, url } = params.data
    return logDrains.create(appId, url)
  }
  throw new Error(`${method} ${resource} not implemented yet`)
}

export default provider
