import * as api from '../../../shared/api'

interface ServerResponse {
  brand: string
  // eslint-disable-next-line camelcase
  exp_month: number
  // eslint-disable-next-line camelcase
  exp_year: number
  last4: number
}

export interface PaymentMethod {
  id: 'ignored' // react-admin checks this
  brand: string
  expMonth: number
  expYear: number
  last4: number
}

export const update = (token: string): Promise<{ data: { id: string } }> => {
  return api
    .put<{ data: { id: string } }>(`/frontend/api/payment_methods`, {
      stripe_token: token,
    })
    .then((): { data: { id: string } } => {
      return { data: { id: token } }
    })
}

export const get = (): Promise<{ data: PaymentMethod | { id: 'ignored' } }> => {
  return api
    .get<{ data: ServerResponse }>('/frontend/api/payment_methods')
    .then((response): { data: PaymentMethod } => {
      return {
        data: {
          id: 'ignored',
          brand: response.data.data.brand,
          expMonth: response.data.data.exp_month,
          expYear: response.data.data.exp_year,
          last4: response.data.data.last4,
        },
      }
    })
    .catch((error) => {
      const _error = error
      if (_error.status === 404) {
        return Promise.resolve({
          data: {
            // kind of annoying, but react-admin requires an id even though
            // we want this to return not found somehow. Is there some better
            // way to indicate not found?
            id: 'ignored',
          },
        })
      }
      return Promise.reject(_error)
    })
}
