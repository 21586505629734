import React, { ReactNode, FunctionComponent } from 'react'
import { ThemeProvider } from '@material-ui/core'
import theme from '../../../theme'
import AppLayout from './AppLayout'

interface Props {
  children: ReactNode
}

const LayoutWrapper: FunctionComponent<Props> = ({ children, ...rest }) => (
  <ThemeProvider theme={theme}>
    <AppLayout {...rest}>{children}</AppLayout>
  </ThemeProvider>
)

export default LayoutWrapper
