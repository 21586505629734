import React, { FunctionComponent } from 'react'
import { AppBar } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import Logo from './AppLogo'
import UserEmail from './UserEmail'

interface Props {
  [key: string]: unknown
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.spacing(7.5),
    paddingLeft: theme.spacing(0.6),
    background: theme.palette.background.default,
    opacity: 0.8,
    boxShadow: 'unset',
    display: 'flex',
    justifyContent: 'center',
  },
  logoRoot: {
    flex: 1,
  },
  logoImage: {
    margin: theme.spacing(0.7),
    width: theme.spacing(10.7),
    verticalAlign: 'middle',
  },
  email: {
    textAlign: 'right',
    marginRight: theme.spacing(1.3),
    color: 'rgba(0,0,0,0.8)',
  },
}))

const MyAppBar: FunctionComponent<Props> = (props) => {
  const classes = useStyles()
  return (
    <AppBar
      color="transparent"
      className={classes.root}
      userMenu={<span />}
      {...props}
    >
      <Logo
        classes={{
          root: classes.logoRoot,
          image: classes.logoImage,
        }}
      />
      <UserEmail className={classes.email} />
    </AppBar>
  )
}

export default MyAppBar
