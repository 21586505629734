import React, { FunctionComponent } from 'react'
import { Notification } from 'react-admin'
import Layout from '../Layout'
import ResetPasswordForm from './ResetPasswordForm'

const ResetPassword: FunctionComponent = () => {
  return (
    <Layout>
      <ResetPasswordForm />
      <Notification />
    </Layout>
  )
}

export default ResetPassword
