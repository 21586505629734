import React, { ReactNode } from 'react'
import { FieldRenderProps } from 'react-final-form'
import {
  DialogContent,
  Select,
  FormControl,
  InputLabel,
  RadioGroup,
  Slider,
  FormHelperText,
  TextField,
  Switch,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

const renderFormHelper = ({
  touched,
  error,
}: {
  touched: boolean | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any
}) => {
  if (!(touched && error)) {
    return <span />
  }
  return <FormHelperText>{touched && error}</FormHelperText>
}

export const renderTextField = ({
  type,
}: {
  type: 'number' | 'input' | 'password'
}) => ({
  label,
  input,
  meta: { touched, invalid, error, submitError },
  ...custom
}: { label: string } & FieldRenderProps<string, HTMLElement>): JSX.Element => {
  let helperText
  if ((error || submitError) && touched) {
    helperText = error || submitError
  }
  return (
    <TextField
      type={type}
      label={label}
      placeholder={label}
      error={touched && invalid}
      helperText={helperText}
      FormHelperTextProps={{ variant: 'standard' }}
      {...input}
      {...custom}
    />
  )
}

export const renderNumberField = renderTextField({ type: 'number' })
export const renderInputField = renderTextField({ type: 'input' })
export const renderSelect = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}: {
  label: string
  children: ReactNode
} & FieldRenderProps<string, HTMLElement>): JSX.Element => {
  // style to match textfield width
  return (
    <FormControl style={{ width: '100%' }} error={touched && error}>
      <InputLabel htmlFor={input.name}>{label}</InputLabel>
      <Select {...input} {...custom} id={input.name}>
        {children}
      </Select>
      {renderFormHelper({ touched, error })}
    </FormControl>
  )
}

export const renderAutocompleteField = ({
  input,
  label,
  meta,
  options,
  ...rest
}: FieldRenderProps<string, HTMLElement>): JSX.Element => {
  return (
    <Autocomplete
      options={options.filter((option: string) => option.includes(input.value))}
      value={input.value}
      onChange={(
        event: React.ChangeEvent<Record<string, unknown>>,
        value: string | null
      ): void => input.onChange(value)}
      renderInput={(params) =>
        renderTextField({
          type: 'input',
        })({
          ...params,
          input,
          label,
          meta,
        })
      } // eslint-disable-line react/jsx-curly-newline
      {...rest}
    />
  )
}

export const renderRadioGroup = ({
  input,
  ...custom
}: FieldRenderProps<string, HTMLElement>): JSX.Element => {
  return (
    <RadioGroup
      {...input}
      {...custom}
      value={input.value}
      onChange={(event: React.ChangeEvent, value: string) => {
        input.onChange(value)
      }}
    />
  )
}

export const renderSwitch = ({
  input,
  ...custom
}: FieldRenderProps<boolean, HTMLElement>): JSX.Element => {
  return (
    <Switch
      color="primary"
      {...input}
      {...custom}
      checked={input.value}
      onChange={(event: React.ChangeEvent, checked: boolean) => {
        input.onChange(checked)
      }}
    />
  )
}

export const renderSlider = ({
  marks,
  step,
  min,
  max,
  input,
  ...custom
}: FieldRenderProps<number | number[], HTMLElement>): JSX.Element => {
  return (
    <Slider
      {...input}
      {...custom}
      marks={marks}
      step={step}
      min={min}
      max={max}
      value={input.value}
      // eslint-disable-next-line @typescript-eslint/ban-types
      onChange={(event: object, value: number | number[]) => {
        input.onChange(value)
      }}
    />
  )
}

// TODO: how to not have this *and* renderDialogError?
export const renderError = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  input,
  meta: { error, submitError },
  ...custom
}: {
  label: string
} & FieldRenderProps<string, HTMLElement>): JSX.Element => {
  if (error || submitError) {
    return (
      <FormHelperText error {...custom}>
        {error || submitError}
      </FormHelperText>
    )
  }
  return <span />
}

export const renderDialogError = ({
  meta: { error },
}: {
  label: string
} & FieldRenderProps<string, HTMLElement>): JSX.Element => {
  if (error) {
    return (
      <DialogContent>
        <FormHelperText error>{error}</FormHelperText>
      </DialogContent>
    )
  }
  return <span />
}
