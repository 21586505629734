import React, { ReactNode } from 'react'
import { FETCH_ERROR } from 'ra-core'
import { Action } from 'redux'

// TODO: can we replace everything in this file with failureCallbacks instead?
interface ErrorPayload {
  [k: string]: string[]
}
export const extractErrorValue = (
  errors: ErrorPayload,
  key: string
): string => {
  const errorList = errors[key] || []
  return errorList.join('. ')
}

const errorListWithKey = (key: string) => (messages: string[]) =>
  messages.map(
    (msg: string) => `${key.charAt(0).toUpperCase()}${key.slice(1)} ${msg}`
  )
export const extractError = (
  errors: ErrorPayload,
  key: string | string[]
): string => {
  if (Array.isArray(key)) {
    let result = ''
    for (let i = 0; i < key.length; i += 1) {
      const _key = key[i]
      const val = errors[_key]
      const _keyErrorList: string[] =
        typeof val === 'string' ? [val] : val || []
      if (_keyErrorList.length > 0)
        result += ` ${errorListWithKey(_key)(_keyErrorList).join('. ')}`
    }
    return result
  }
  const errorList = errors[key] || []
  return errorListWithKey(key)(errorList).join('. ')
}

export const extractAllErrors = (errors: ErrorPayload): string => {
  const strings = Object.entries(errors).map(([k, v]) => {
    return errorListWithKey(k)(v)
  })
  return strings.join('. ')
}

export interface CrudFailureAction extends Action {
  error?: string
  payload?: { errors: ErrorPayload }
  meta?: {
    resource: string
    fetchResponse: string
    fetchStatus?: typeof FETCH_ERROR
    notification?: object
  }
}

interface UserLoginFailureAction extends Action {
  error?: {
    body: {
      errors: ErrorPayload
    }
  }
}

export const extractEmailError = (errors: ErrorPayload): ReactNode => {
  const key = 'email'
  const errorList = errors[key] || []
  const msg = extractError(errors, key)
  //
  // TODO: extremely brittle. if error msg ever changes, this won't work. what to do instead?
  // we can't return jsx elements through http so we need to use a code or something instead
  // but then that won't work well with the CLI which needs text..
  if (errorList.includes('not yet confirmed.')) {
    return (
      <span>
        {msg}. <a href="/#/confirmation/resend">Resend Confirmation</a>
      </span>
    )
  }
  if (errorList.includes('or password is incorrect.')) {
    return (
      <span>
        {msg}. <a href="/#/password/reset">Reset Password</a>
      </span>
    )
  }
  return msg
}
