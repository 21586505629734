import React, { FunctionComponent } from 'react'
import { useMutation, useRefresh } from 'ra-core'
import { FORM_ERROR } from 'final-form'
import { Field, Form } from 'react-final-form'
import {
  makeStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
} from '@material-ui/core'
import {
  extractError,
  extractErrorValue,
} from '../../../shared/errorExtractors'
import { renderTextField } from '../../../shared/components/formFields'
import ErrorComponent from '../../../shared/components/ErrorComponent'
import CancelButton from '../../../shared/components/CancelButton'
import SubmitButton from '../../../shared/components/SubmitButton'
import Bash from '../../../shared/components/Bash'
import useMaybeNotify from '../../../shared/hooks/useMaybeNotify'
import { composeValidators, required, url } from '../../../utils/validators'

const validateUrl = composeValidators([
  required(),
  url(['syslog', 'syslog+tls', 'udpsyslog', 'http', 'https']),
])

const renderUrlField = renderTextField({ type: 'input' })

interface Props {
  onSuccess: () => void
  onCancel: () => void
  appId: string
}
interface FormData {
  url: string
}

const useStyles = makeStyles((theme) => ({
  example: {
    padding: theme.spacing(2, 0, 0.5),
  },
}))

const LogDrainDialog: FunctionComponent<Props> = (props) => {
  const { onSuccess, onCancel, appId } = props

  const classes = useStyles()

  const [mutate] = useMutation()
  const notify = useMaybeNotify()
  const refresh = useRefresh()

  const submit = ({ url: _url }: FormData) => {
    const newLogDrain = {
      appId,
      url: _url,
    }
    return new Promise((resolve) => {
      mutate(
        {
          type: 'create',
          resource: 'log_drains',
          payload: { data: newLogDrain },
        },
        {
          onSuccess: () => {
            notify('Log Drain added')
            onSuccess()
            resolve()
            refresh()
          },
          onFailure: ({ body: { errors } }) => {
            resolve({
              [FORM_ERROR]: extractError(errors, ''),
              url: extractErrorValue(errors, 'url'),
            })
          },
        }
      )
    })
  }
  return (
    <Form
      onSubmit={submit}
      render={({
        handleSubmit,
        submitError,
        hasSubmitErrors,
        hasValidationErrors,
        modifiedSinceLastSubmit,
        pristine,
        submitting,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">Add Log Drain</DialogTitle>
            {submitError && (
              <DialogContent>
                <FormHelperText error>
                  <ErrorComponent>{submitError}</ErrorComponent>
                </FormHelperText>
              </DialogContent>
            )}
            <DialogContent>
              <div className={classes.example}>Example HTTP(S) drain:</div>
              <Bash>
                https://api.logflare.app/logs/logplex?api_key=YOUR_API_KEY&source=YOUR_SOURCE_ID
              </Bash>
              <div className={classes.example}>Example syslog drain:</div>
              <Bash>syslog+tls://logs123.papertrailapp.com:12345</Bash>
            </DialogContent>
            <DialogContent>
              <Field
                validate={validateUrl}
                component={renderUrlField}
                name="url"
                label="Drain URL"
                fullWidth
              />
            </DialogContent>

            <DialogActions>
              <CancelButton onClick={onCancel} />
              <SubmitButton
                {...{
                  hasValidationErrors,
                  hasSubmitErrors,
                  modifiedSinceLastSubmit,
                  pristine,
                  submitting,
                }}
                label="Add"
                variant="contained"
                size="small"
              />
            </DialogActions>
          </form>
        )
      }}
    />
  )
}

export default LogDrainDialog
