import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { ReduxState } from 'ra-core'

interface Props {
  className?: string
}

interface EnhancedProps {
  email: string
}

const UserEmail: FunctionComponent<Props & EnhancedProps> = (props) => {
  const { className, email } = props
  return <span className={className}>{email}</span>
}

const mapStateToProps = (state: ReduxState) => ({
  email: state.admin?.resources?.profile?.data?.ignored?.email,
})

const enhance = compose<Props & EnhancedProps, Props>(
  connect(mapStateToProps, {})
)

const EnhancedUserEmail = enhance(UserEmail)

export default EnhancedUserEmail
