import classnames from 'classnames'
import React, { ReactNode, FunctionComponent } from 'react'
import { Paper, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: theme.palette.background.default,
    marginTop: 20,
    padding: 20,
    '& h4': {
      marginTop: 30,
    },
    '& h4:first-child': {
      marginTop: 0,
    },
  },
}))

interface Props {
  classes?: Record<string, string>
  children: ReactNode
  className?: string
}

const Section: FunctionComponent<Props> = (props: Props) => {
  const { classes: styles, children, className } = props

  const classes = {
    ...useStyles(),
    ...styles,
  }

  return (
    <Paper className={classnames(classes.section, className)} elevation={0}>
      {children}
    </Paper>
  )
}

export default Section
