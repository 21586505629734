import * as apiKey from './actions'
import { ResetApiKeyParams } from './dataProviderParams'
import { UpdateParams } from '../../../shared/dataProvider/interfaces'
import { Method, Resource, Params } from '../../../shared/dataProvider/types'

const isResetApiKey = (
  method: Method,
  resource: Resource,
  params: Params
): params is UpdateParams<ResetApiKeyParams> =>
  method === 'UPDATE' && resource === 'api_keys'

const provider = (
  method: Method,
  resource: Resource,
  params: Params
): Promise<unknown> => {
  if (isResetApiKey(method, resource, params)) {
    const { currentPassword } = params.data
    return apiKey.reset(currentPassword)
  }
  throw new Error(`${method} ${resource} not implemented yet`)
}

export default provider
