import React, { FunctionComponent, ReactNode } from 'react'
import { makeStyles } from '@material-ui/core'

interface Props {
  classes?: Record<string, string>
  children: ReactNode
}

const useStyles = makeStyles({
  bash: {
    backgroundColor: '#374850',
    color: '#ddd',
    padding: 14,
    marginBottom: '10px',
    fontFamily: 'Roboto Mono',
    fontSize: 12,
    whiteSpace: 'pre',
    overflowX: 'scroll',
  },
})

const Bash: FunctionComponent<Props> = (props) => {
  const { classes: styles, children } = props
  const classes = {
    ...useStyles(),
    ...styles,
  }
  return <div className={classes.bash}>{children}</div>
}

export default Bash
