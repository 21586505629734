import React, { FunctionComponent } from 'react'
import { useQueryWithStore } from 'ra-core'
import { makeStyles } from '@material-ui/core'
import { ReadReplica } from '../../../actions'
import Loading from '../../../../../../shared/components/Loading'
import ErrorComponent from '../../../../../../shared/components/ErrorComponent'
import NoData from '../../../../../../shared/components/NoData'
import DialogButton from '../../../../../../shared/components/DialogButton'
import Field from '../Field'
import CreateDialog from './CreateDialog'
import DeleteDialog from './DeleteDialog'
import ScaleDialog from './ScaleDialog'

interface Props {
  appId: string
  databaseId: string
  databaseSize: number
}

const useStyles = makeStyles((theme) => ({
  loading: {
    marginTop: 80,
    marginBottom: 40,
  },
  noData: {
    padding: theme.spacing(2),
  },
  root: {
    padding: theme.spacing(2),
  },
  readReplicas: {},
  readReplica: {},
  readReplicaHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(),
  },
  readReplicaActions: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: `${theme.spacing(2)}px`,
  },
  readReplicaProperties: {},
}))

const ReadReplicas: FunctionComponent<Props> = (props) => {
  const { appId, databaseId, databaseSize } = props
  const {
    data: readReplicas,
    loading,
    error,
  }: {
    data?: ReadReplica[]
    loading: boolean
    error?: Error
  } = useQueryWithStore({
    type: 'GET_READ_REPLICAS',
    resource: 'databases',
    payload: {
      appId,
      databaseId,
    },
  })

  const classes = useStyles()

  if (loading) {
    return <Loading className={classes.loading} />
  }

  if (error) {
    return <ErrorComponent>{error.message}</ErrorComponent>
  }

  if (!(readReplicas && readReplicas.length > 0)) {
    return (
      <NoData className={classes.noData} message="No Read Replicas found">
        <DialogButton label="Create Read Replica">
          {(close) => (
            <CreateDialog
              onSuccess={close}
              onCancel={close}
              appId={appId}
              databaseId={databaseId}
              databaseSize={databaseSize}
            />
          )}
        </DialogButton>
      </NoData>
    )
  }

  return (
    <div className={classes.root}>
      <DialogButton label="Create Read Replica">
        {(close) => (
          <CreateDialog
            onSuccess={close}
            onCancel={close}
            appId={appId}
            databaseId={databaseId}
            databaseSize={databaseSize}
          />
        )}
      </DialogButton>
      <div className={classes.readReplicas}>
        {readReplicas.map((readReplica, index) => (
          <div key={readReplica.id} className={classes.readReplica}>
            <div className={classes.readReplicaHeader}>
              <span>{`Read Replica - ${index + 1}`}</span>
              <div className={classes.readReplicaActions}>
                <DialogButton label="Scale">
                  {(close) => (
                    <ScaleDialog
                      onSuccess={close}
                      onCancel={close}
                      appId={appId}
                      readReplica={readReplica}
                    />
                  )}
                </DialogButton>
                <DialogButton label="Delete">
                  {(close) => (
                    <DeleteDialog
                      onSuccess={close}
                      onCancel={close}
                      appId={appId}
                      readReplicaId={readReplica.id}
                    />
                  )}
                </DialogButton>
              </div>
            </div>
            <div className={classes.readReplicaProperties}>
              <Field leftSide="ID" rightSide={readReplica.id} />
              <Field leftSide="Host" rightSide={readReplica.host} />
              <Field leftSide="Port" rightSide={readReplica.port} />
              <Field leftSide="URL" rightSide={readReplica.url} />
              <Field leftSide="Username" rightSide={readReplica.username} />
              <Field leftSide="Password" rightSide={readReplica.password} />
              <Field leftSide="App Name" rightSide={readReplica.appName} />
              <Field leftSide="Database" rightSide={readReplica.database} />
              <Field leftSide="State" rightSide={readReplica.state} />
              <Field
                leftSide="High Availability"
                rightSide={
                  readReplica.highAvailability === 'enabled'
                    ? 'Enabled'
                    : 'Disabled'
                }
              />
              {readReplica.tier === 'STANDARD' ? (
                <>
                  <Field leftSide="Cloud" rightSide={readReplica.cloud} />
                  <Field leftSide="Region" rightSide={readReplica.region} />
                  <Field leftSide="Size" rightSide={readReplica.size} />
                </>
              ) : (
                <span />
              )}
              <Field leftSide="Primary" rightSide={readReplica.primary} />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ReadReplicas
