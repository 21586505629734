import * as api from '../../../shared/api'

export interface Usage {
  databases: { database: string; amountCents: number }[]
  apps: { app: string; amountCents: number }[]
}

interface ServerResponse {
  // eslint-disable-next-line camelcase
  databases: { database: string; amount_cents: number }[]
  // eslint-disable-next-line camelcase
  apps: { app: string; amount_cents: number }[]
}

export const get = (): Promise<{ data: Usage }> => {
  return api
    .get<{ data: ServerResponse }>('/frontend/api/usage')
    .then((response): { data: Usage } => {
      const usage = response.data.data
      return {
        data: {
          // eslint-disable-next-line camelcase
          databases: usage.databases.map(({ database, amount_cents }) => ({
            database,
            amountCents: amount_cents,
          })),
          // eslint-disable-next-line camelcase
          apps: usage.apps.map(({ app, amount_cents }) => ({
            app,
            amountCents: amount_cents,
          })),
        },
      }
    })
}
