import moment from 'moment'

const dateFormatter = (date?: Date): string => {
  if (!date) {
    return ''
  }
  return moment(date).format('YYYY-MM-DDThh:mm:ss')
}

export default dateFormatter
