import React, { ReactElement, FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  container: {
    height: '100%',
  },
})

interface Props {
  classes?: Record<string, string>
  title: string
  children: ReactElement
}

const Page: FunctionComponent<Props> = (props) => {
  const { classes: styles, children } = props

  const classes = {
    ...useStyles(),
    ...styles,
  }

  return <div className={classes.container}>{children}</div>
}

export default Page
