import React, { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  fields: {
    display: 'flex',
  },
})

interface Props {
  classes?: Record<string, string>
}

const Fields: FunctionComponent<Props> = (props) => {
  const { classes: styles, children } = props

  const classes = {
    ...useStyles(),
    ...styles,
  }

  return <div className={classes.fields}>{children}</div>
}

export default Fields
