import React, { FunctionComponent } from 'react'
import Layout from '../Layout'
import RegisterForm from './RegisterForm'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

const Register: FunctionComponent<Props> = () => {
  return (
    <Layout>
      <RegisterForm />
    </Layout>
  )
}

export default Register
