import * as auth from './actions'
import * as profile from '../Account/Profile/actions'
import {
  UpdateConfirmationParams,
  CreateUserParams,
  UpdateUserParams,
  UpdateEmailParams,
  CreatePasswordParams,
  UpdatePasswordParams,
  ChangePasswordParams,
} from './dataProviderParams'
import {
  CreateParams,
  UpdateParams,
} from '../../shared/dataProvider/interfaces'
import { Method, Resource, Params } from '../../shared/dataProvider/types'

const isUpdateConfirmation = (
  method: Method,
  resource: Resource,
  params: Params
): params is UpdateParams<UpdateConfirmationParams> =>
  method === 'UPDATE' && resource === 'confirmation'

const isCreateUser = (
  method: Method,
  resource: Resource,
  params: Params
): params is CreateParams<CreateUserParams> =>
  method === 'CREATE' && resource === 'users'

const isUpdateUser = (
  method: Method,
  resource: Resource,
  params: Params
): params is UpdateParams<UpdateUserParams> =>
  method === 'UPDATE' && resource === 'account'

const isUpdateEmail = (
  method: Method,
  resource: Resource,
  params: Params
): params is UpdateParams<UpdateEmailParams> =>
  method === 'UPDATE' && resource === 'email'

const isCreatePassword = (
  method: Method,
  resource: Resource,
  params: Params
): params is CreateParams<CreatePasswordParams> =>
  method === 'CREATE' && resource === 'password'

const isUpdatePassword = (
  method: Method,
  resource: Resource,
  params: Params
): params is UpdateParams<UpdatePasswordParams> =>
  method === 'UPDATE' && resource === 'password'

const isChangePassword = (
  method: Method,
  resource: Resource,
  params: Params
): params is UpdateParams<ChangePasswordParams> =>
  method === 'CHANGE' && resource === 'password'

const provider = (
  method: Method,
  resource: Resource,
  params: Params
): Promise<unknown> => {
  if (isUpdateConfirmation(method, resource, params)) {
    const { email } = params.data
    return auth.resendConfirmation(email)
  }
  if (isCreateUser(method, resource, params)) {
    const { email, password } = params.data
    return auth.create(email, password)
  }
  if (isUpdateUser(method, resource, params)) {
    const { token, promoCode } = params.data
    return profile.upgrade(token, promoCode)
  }
  if (isUpdateEmail(method, resource, params)) {
    const { email, password } = params.data
    return profile.updateEmail(email, password)
  }
  if (isCreatePassword(method, resource, params)) {
    const { email } = params.data
    return auth.resetPassword(email)
  }
  if (isUpdatePassword(method, resource, params)) {
    const { token, newPassword } = params.data
    return auth.setPassword(token, newPassword)
  }
  if (isChangePassword(method, resource, params)) {
    const { currentPassword, newPassword } = params.data
    return profile.changePassword(currentPassword, newPassword)
  }
  throw new Error(`${method} ${resource} not implemented yet`)
}

export default provider
