import qs from 'query-string'
import React, { FunctionComponent, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import Layout from './Layout'

interface Props {
  classes?: Record<string, string>
  location: {
    search: string
  }
}

const useStyles = makeStyles({
  message: {
    padding: 40,
    textAlign: 'center',
  },
})

// see https://stackoverflow.com/questions/56457935/typescript-error-property-x-does-not-exist-on-type-window/56458070
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gtag: any
  }
}


const SuccessPage: FunctionComponent<Props> = (props) => {
  useEffect(() => {
    window.gtag('event', 'conversion', {
      send_to: 'AW-10814265223/EGw9CNzNroYDEIev0qQo',
    })
  }, [])

  const { classes: styles, location } = props
  const params = qs.parse(location.search)

  const classes = {
    ...useStyles(),
    ...styles,
  }

  if (typeof params.msg === 'string') {
    return (
      <Layout>
        <div className={classes.message}>{params.msg}</div>
      </Layout>
    )
  }
  return <span />
}

export default SuccessPage
