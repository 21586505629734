import { FormHelperText, makeStyles } from '@material-ui/core'
import { useLogin, useSafeSetState, useTranslate } from 'ra-core'
import React, { FunctionComponent } from 'react'
import { Field, Form } from 'react-final-form'
import ErrorComponent from '../../../shared/components/ErrorComponent'
import { renderInputField as renderInput } from '../../../shared/components/formFields'
import SubmitButton from '../../../shared/components/SubmitButton'
import useMaybeNotify from '../../../shared/hooks/useMaybeNotify'
import styles from '../authStyles'

export interface Props {
  redirectTo?: string
  email: string
  password: string
}

interface FormData {
  mfaToken: string
}

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  signup: {},
  login: {},
  linkSecondary: {
    fontSize: '0.75rem',
  },
}))

const MfaForm: FunctionComponent<Props> = (props) => {
  const { redirectTo, email, password } = props
  const classes = useStyles(props)

  const [loading, setLoading] = useSafeSetState<boolean>(false)

  const login = useLogin()
  const notify = useMaybeNotify()
  const translate = useTranslate()

  const validate = (values: FormData) => {
    const errors: { mfaToken?: string } = {
      mfaToken: undefined,
    }

    if (!values.mfaToken) {
      errors.mfaToken = translate('ra.validation.required')
    }
    return errors
  }

  const submit = (values: FormData) => {
    setLoading(true)
    return login(
      {
        email,
        password,
        mfaToken: values.mfaToken,
      },
      redirectTo
    )
      .then(() => {
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)

        if (typeof error === 'string') {
          notify(error, 'warning')
        } else if (!error || !error.message) {
          notify('ra.auth.sign_in_error', 'warning')
        } else {
          notify(error.message, 'warning')
        }

        return {
          mfaToken: 'Incorrect code',
        }
      })
  }

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({
        handleSubmit,
        submitError,
        hasValidationErrors,
        hasSubmitErrors,
        modifiedSinceLastSubmit,
        pristine,
        submitting,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            {submitError && (
              <FormHelperText error>
                <ErrorComponent>{submitError}</ErrorComponent>
              </FormHelperText>
            )}

            <div className={classes.form}>
              <div className={classes.input}>
                <Field
                  id="mfaToken"
                  name="mfaToken"
                  component={renderInput}
                  fullWidth
                  placeholder="Multi-factor Authentication Code"
                  disabled={loading}
                  variant="outlined"
                />
              </div>
            </div>
            <SubmitButton
              {...{
                hasValidationErrors,
                hasSubmitErrors,
                modifiedSinceLastSubmit,
                pristine,
                submitting,
              }}
              color="primary"
              label="Sign In"
              variant="contained"
              className={classes.button}
            />
          </form>
        )
      }}
    />
  )
}

export default MfaForm
