import classnames from 'classnames'
import React, { ReactNode, FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/core'

interface Props {
  classes?: Record<string, string>
  className?: string
  children: ReactNode
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginLeft: theme.spacing(-0.75),
    marginRight: theme.spacing(-0.75),
  },
  childWrapper: {
    paddingLeft: theme.spacing(0.75),
    paddingRight: theme.spacing(0.75),
  },
}))

const ButtonPanel: FunctionComponent<Props> = (props) => {
  const { classes: styles, className, children } = props

  const classes = {
    ...useStyles(),
    ...styles,
  }

  return (
    <div className={classnames(classes.root, className)}>
      {React.Children.map(children, (child) => (
        <div className={classes.childWrapper}>{child}</div>
      ))}
    </div>
  )
}

export default ButtonPanel
