import React, { ReactNode, FunctionComponent } from 'react'
import { Layout } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import AppBar from './AppBar'
import Menu from './Menu'
import Footer from './Footer'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    // see https://stackoverflow.com/questions/38223879/white-space-nowrap-breaks-flexbox-layout
    minWidth: 0,
    backgroundColor: theme.palette.background.paper,
  },
  appFrame: {
    minHeight: `calc(100vh - ${theme.spacing(7.5)}px)`,
    marginTop: theme.spacing(7.5),
  },
  contentWithSidebar: {
    height: '100%',
    '& > div:first-child': {
      backgroundColor: theme.palette.background.default,
    },
  },
  content: {
    padding: theme.spacing(2, 3, 0),
    // see https://stackoverflow.com/questions/38223879/white-space-nowrap-breaks-flexbox-layout
    minWidth: 0,
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  childrenWrapper: {
    flex: '1 0 auto',
  },
  footerWrapper: {
    flexShrink: 0,
  },
}))

interface Props {
  children: ReactNode
}

const MyLayout: FunctionComponent<Props> = ({ children, ...rest }) => {
  const classes = useStyles()

  return (
    <Layout
      {...rest}
      appBar={AppBar}
      menu={Menu}
      classes={{
        root: classes.root,
        appFrame: classes.appFrame,
        contentWithSidebar: classes.contentWithSidebar,
        content: classes.content,
      }}
    >
      <div className={classes.container}>
        <div className={classes.childrenWrapper}>{children}</div>
        <div className={classes.footerWrapper}>
          <Footer />
        </div>
      </div>
    </Layout>
  )
}

export default MyLayout
