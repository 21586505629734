import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { useTranslate } from 'react-admin'
import { useMutation, useRedirect } from 'ra-core'
import { FORM_ERROR } from 'final-form'
import { Field, Form } from 'react-final-form'
import {
  makeStyles,
  FormHelperText,
  InputAdornment,
  SvgIcon,
} from '@material-ui/core'
import styles from '../authStyles'
import { extractError } from '../../../shared/errorExtractors'
import { renderInputField as renderInput } from '../../../shared/components/formFields'
import ErrorComponent from '../../../shared/components/ErrorComponent'
import LinkButton from '../../../shared/components/LinkButton'
import SubmitButton from '../../../shared/components/SubmitButton'
import { required } from '../../../utils/validators'
import { ReactComponent as EmailIcon } from '../../../icons/email.svg'
import { ReactComponent as LockIcon } from '../../../icons/lock.svg'
import { host } from '../../../shared/api'

const validateEmail = required()
const validatePassword = required()

interface Props {
  redirectTo?: string
}

interface FormData {
  email: string
  password: string
}

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  login: {},
  clickwrap: {
    marginBottom: 10,
  },
}))

const RegisterForm: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props)
  const translate = useTranslate()
  const [mutate] = useMutation()
  const redirect = useRedirect()
  const submit = (values: FormData) => {
    return new Promise((resolve) => {
      mutate(
        {
          type: 'create',
          resource: 'users',
          payload: { data: values },
        },
        {
          onSuccess: () => {
            const msg = 'Confirmation email sent. Please check your email.'
            redirect(`/success?msg=${encodeURIComponent(msg)}`)
            resolve()
          },
          onFailure: ({ body: { errors } }) => {
            resolve({
              [FORM_ERROR]: extractError(errors, ''),
              email: extractError(errors, 'email'),
              password: extractError(errors, 'password'),
            })
          },
        }
      )
    })
  }
  return (
    <Form
      onSubmit={submit}
      render={({
        handleSubmit,
        submitError,
        hasValidationErrors,
        hasSubmitErrors,
        modifiedSinceLastSubmit,
        pristine,
        submitting,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className={classes.form}>
              {submitError && (
                <FormHelperText error>
                  <ErrorComponent>{submitError}</ErrorComponent>
                </FormHelperText>
              )}
              <div className={classes.input}>
                <Field
                  id="email"
                  name="email"
                  validate={validateEmail}
                  component={renderInput}
                  fullWidth
                  placeholder="Email"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon className={classes.icon}>
                          <EmailIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className={classes.input}>
                <Field
                  id="password"
                  name="password"
                  validate={validatePassword}
                  component={renderInput}
                  fullWidth
                  placeholder={translate('ra.auth.password')}
                  type="password"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon className={classes.icon}>
                          <LockIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <SubmitButton
              {...{
                hasValidationErrors,
                hasSubmitErrors,
                modifiedSinceLastSubmit,
                pristine,
                submitting,
              }}
              color="primary"
              label="Register"
              variant="contained"
              className={classes.button}
            />
            <div className={`${classes.link} ${classes.clickwrap}`}>
              By signing up, you agree to our{' '}
              <a
                href="https://www.gigalixir.com/terms-of-service.html"
                className={classes.login}
              >
                terms of service
              </a>{' '}
              and{' '}
              <a
                href="https://www.gigalixir.com/privacy-policy.html"
                className={classes.login}
              >
                privacy policy
              </a>
              .
            </div>
            <LinkButton
              color="primary"
              label="Sign Up with Google"
              link={host + "/oauth/google/signup"}
              variant="contained"
              className={classes.button}
            />
            <div className={classes.link}>
              Already have an account?{' '}
              <Link to="/login" className={classes.login}>
                Sign in
              </Link>
            </div>
          </form>
        )
      }}
    />
  )
}

export default RegisterForm
