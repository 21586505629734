import classnames from 'classnames'
import React, { ReactNode, FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/core'

interface Props {
  classes?: Record<string, string>
  className?: string
  message: ReactNode
  note?: ReactNode
  children?: ReactNode
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundColor: theme.palette.background.default,
  },
  note: {
    marginTop: 0,
    marginBottom: theme.spacing(3),
    maxWidth: 600,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    color: '#555',
    textAlign: 'center',
    lineHeight: 1.4,
    '& a': {
      paddingTop: theme.spacing(),
      textDecoration: 'none',
      textTransform: 'uppercase',
      fontWeight: 'normal',
      color: '#3f51b5',
    },
  },
  message: {
    marginBottom: theme.spacing(),
    color: '#555',
  },
}))

const NoData: FunctionComponent<Props> = (props) => {
  const { classes: styles, className, message, note, children } = props

  const classes = {
    ...useStyles(),
    ...styles,
  }

  return (
    <div className={classnames(classes.container, className)}>
      {note && <div className={classes.note}>{note}</div>}
      <span className={classnames({ [classes.message]: !!children })}>
        {message}
      </span>
      {children}
    </div>
  )
}

export default NoData
