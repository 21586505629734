import { Socket } from 'phoenix'
import React, { FunctionComponent, useState, useEffect } from 'react'
import ScrollToBottom from 'react-scroll-to-bottom'
import { makeStyles, FormControlLabel, Switch } from '@material-ui/core'
import { authToken } from '../../../shared/api'
import NoData from '../../../shared/components/NoData'

const wsHost = process.env.REACT_APP_API_HOST?.replace(/^http/, 'ws')

const useStyles = makeStyles({
  wrapper: {
    // hack to get this to work with ScrollToBottom
    // ideally we would use flexbox grow to fill the height
    // but so far I can't figure out how to get this to play
    // nicely with ScrollToBottom which wants a set height in pixels.
    // 75px is the header
    // 75px is the footer
    // 20px is padding above tabs
    // 58.5px is the tabs
    // 30px is the padding under tabs
    height: `calc(100vh - 75px - 75px - 20px - 58.5px - 30px)`,
  },
  logs: {
    // 38 px is the raw toggle
    height: `calc(100% - 38px)`,
    whiteSpace: 'pre',
    backgroundColor: '#374850',
    color: '#ddd',
    fontFamily: 'Roboto Mono',
    fontSize: 12,
  },
  logLine: {},
})
interface Props {
  appId: string
}
const LogStream: FunctionComponent<Props> = ({ appId }) => {
  // the notify thing here causes re-renders?
  // const notify = useMaybeNotify()
  const [isRaw, setIsRaw] = useState(false)
  const [messages, setMessages] = useState<string[]>([])
  const classes = useStyles()
  useEffect(() => {
    const thePromise = authToken().then((token) => {
      const socket = new Socket(`${wsHost}/socket`, {
        params: { token },
      })
      socket.connect()
      const channel = socket.channel(`room:${appId}`, { client: 'browser' })

      channel.onMessage = (event: string, payload: { body: string }) => {
        // check event:
        // phx_reply
        // chan_reply_1
        // new_msg
        // phx_error
        if (event.startsWith('chan_reply_')) {
          return payload
        }
        if (event === 'phx_reply') {
          return payload
        }
        if (event === 'phx_close') {
          return payload
        }
        if (event === 'phx_error') {
          if (payload === undefined) {
            throw Error(`${event}: ${payload}`)
          } else {
            throw Error(`${event}: ${JSON.stringify(payload)}`)
          }
        }
        if (event !== 'new_msg') {
          throw Error(`Unknown event: ${event}`)
        }

        const { body: msg } = payload
        if (typeof msg === 'string') {
          // ascii characters only. there are some colors i think?
          const printableMsg = msg.replace(
            // eslint-disable-next-line no-control-regex
            /[\u001b\u009b][[()#;?]*(?:[0-9]{1,4}(?:;[0-9]{0,4})*)?[0-9A-ORZcf-nqry=><]/g,
            ''
          )
          setMessages((prev) => [...prev, printableMsg])
        }
        return payload
      }

      channel
        .join()
        // .receive('ok', (response) =>
        //   console.log('successfully joined channel', response)
        // )
        .receive('error', (response) => {
          // eslint-disable-next-line no-console
          console.error('failed to join channel', response)
          // notify('Oops, something went wrong. Please try again', 'error')
        })
      return () => {
        channel.leave()
        socket.disconnect()
      }
    })

    return () => {
      thePromise.then((cleanup) => {
        cleanup()
      })
    }
  }, [appId])

  // from https://stackoverflow.com/a/44051405/365377
  if (messages.length === 0) {
    return <NoData message="No logs yet" />
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsRaw(event.target.checked)
  }

  return (
    // this height needs to be here so that the height 100% in child elements expands to
    // the full height also
    <div className={classes.wrapper}>
      <FormControlLabel
        control={
          <Switch checked={isRaw} onChange={handleChange} name="isRaw" />
        }
        label="Raw"
      />

      <ScrollToBottom className={classes.logs}>
        {messages.map((msg) => {
          let m = msg
          if (!isRaw) {
            // regex safer?
            m = msg.split(':').slice(4).join(':')
          }
          return (
            <div key={msg} className={classes.logLine}>
              {m}
            </div>
          )
        })}
      </ScrollToBottom>
    </div>
  )
}

export default LogStream
