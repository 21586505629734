import React, { FunctionComponent } from 'react'
import { Field, Form } from 'react-final-form'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  makeStyles,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { renderTextField } from './formFields'
import ErrorComponent from './ErrorComponent'
import CancelButton from './CancelButton'
import SubmitButton from './SubmitButton'
import { composeValidators, required } from '../../utils/validators'

interface Props {
  classes?: Record<string, string>
  onCancel: () => void
  title: string
  warning?: string
  fieldLabel: string
  submitLabel: string
  onSubmit: (
    value: string,
    resolve: <T>(value?: T | PromiseLike<T>) => void
  ) => void
  initialValue: string
  validationMessage: string
}

const useStyles = makeStyles((theme) => ({
  error: {
    marginBottom: 20,
  },
  alert: {
    marginTop: theme.spacing(2),
  },
  helper: {
    marginTop: 20,
    marginBottom: 10,
  },
}))

const renderValue = renderTextField({ type: 'input' })

const InputConfirmationDialog: FunctionComponent<Props> = (props) => {
  const {
    classes: styles,
    onCancel,
    title,
    warning,
    fieldLabel,
    submitLabel,
    onSubmit,
    initialValue,
    validationMessage,
  } = props

  const classes = {
    ...useStyles(),
    ...styles,
  }

  const validateValue = composeValidators([
    required(),
    (value) => {
      if (value !== initialValue) {
        return validationMessage
      }
      return undefined
    },
  ])

  const submit = (values: { value: string }) => {
    return new Promise((resolve) => {
      onSubmit(values.value, resolve)
    })
  }

  return (
    <Form
      onSubmit={submit}
      render={({
        handleSubmit,
        submitError,
        submitting,
        hasValidationErrors,
        hasSubmitErrors,
        modifiedSinceLastSubmit,
        pristine,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
              {submitError && (
                <FormHelperText error className={classes.error}>
                  <ErrorComponent>{submitError}</ErrorComponent>
                </FormHelperText>
              )}
              {warning && (
                <Alert className={classes.alert} severity="error">
                  <b>Warning:</b> {warning}.
                </Alert>
              )}
              <div className={classes.helper}>
                {`Please enter "${initialValue}" below to confirm`}
              </div>
              <Field
                name="value"
                validate={validateValue}
                label={fieldLabel}
                component={renderValue}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <CancelButton onClick={onCancel} />
              <SubmitButton
                {...{
                  hasValidationErrors,
                  hasSubmitErrors,
                  modifiedSinceLastSubmit,
                  pristine,
                  submitting,
                }}
                color="primary"
                label={submitLabel}
                variant="contained"
                size="small"
              />
            </DialogActions>
          </form>
        )
      }}
    />
  )
}

export default InputConfirmationDialog
