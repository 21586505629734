import React, { FunctionComponent, ReactNode } from 'react'
import { Notification } from 'react-admin'
import { makeStyles, Card } from '@material-ui/core'
import Logo from '../../shared/components/Layout/AppLogo'
import Footer from '../../shared/components/Layout/Footer'

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.background.paper,
  },
  card: {
    width: 320,
    margin: '0 auto',
    marginTop: theme.spacing(10),
    padding: theme.spacing(5),
    overflow: 'visible',
    backgroundColor: theme.palette.background.default,
  },
  avatar: {
    marginBottom: theme.spacing(5),
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    flex: '1 0 auto',
  },
  footerWrapper: {
    flexShrink: 0,
  },
  footer: {
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(1.6),
  },
}))

interface Props {
  children: ReactNode
}

const LayoutContent: FunctionComponent<Props> = (props) => {
  const { children } = props
  const classes = useStyles()
  return (
    <div className={classes.main}>
      <div className={classes.content}>
        <Card className={classes.card}>
          <div className={classes.avatar}>
            <Logo />
          </div>
          {children}
        </Card>
      </div>
      <div className={classes.footerWrapper}>
        <Footer classes={{ footer: classes.footer }} />
      </div>
      <Notification />
    </div>
  )
}

export default LayoutContent
