import React, { FunctionComponent } from 'react'
import { makeStyles, IconButton, SvgIcon } from '@material-ui/core'
import ChangeEmailDialog from './ChangeEmailDialog'
import ChangePasswordDialog from './ChangePasswordDialog'
import Upgrade from './Upgrade'
import { User } from '../actions'
import Fields from '../../../shared/components/Fields'
import Field from '../../../shared/components/Field'
import DialogButton from '../../../shared/components/DialogButton'
import formatMoney from '../../../utils/formatters/moneyFormatters'
import { ReactComponent as EditIcon } from '../../../icons/edit.svg'

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  editIcon: {
    height: 16,
    width: 16,
  },
}))

interface Props {
  user: User
}

const Profile: FunctionComponent<Props> = (props) => {
  const { user } = props
  const classes = useStyles()
  return (
    <>
      <Fields>
        <Field
          label={
            <div className={classes.content}>
              <span>Email</span>
              <DialogButton
                label="Edit"
                component={
                  <IconButton size="small">
                    <SvgIcon className={classes.editIcon} color="primary">
                      <EditIcon />
                    </SvgIcon>
                  </IconButton>
                }
              >
                {(close) => (
                  <ChangeEmailDialog
                    onSuccess={() => close()}
                    onCancel={() => close()}
                  />
                )}
              </DialogButton>
            </div>
          }
          isCard
        >
          {user.email}
        </Field>
        <Field
          label={
            <div className={classes.content}>
              <span>Password</span>
              <DialogButton
                label="Edit"
                component={
                  <IconButton size="small">
                    <SvgIcon className={classes.editIcon} color="primary">
                      <EditIcon />
                    </SvgIcon>
                  </IconButton>
                }
              >
                {(close) => (
                  <ChangePasswordDialog
                    onSuccess={() => close()}
                    onCancel={() => close()}
                  />
                )}
              </DialogButton>
            </div>
          }
          isCard
        >
          **********
        </Field>
        <Field label="Tier" isCard>
          {user.tier}
        </Field>
        {user.creditCents !== 0 && (
          <Field label="Credits">{formatMoney(user.creditCents)}</Field>
        )}
      </Fields>
      <Upgrade record={user} />
    </>
  )
}

export default Profile
