import {
  FormHelperText,
  InputAdornment,
  makeStyles,
  SvgIcon,
} from '@material-ui/core'
import classnames from 'classnames'
import { FORM_ERROR } from 'final-form'
import { useLogin, useSafeSetState, useTranslate } from 'ra-core'
import React, { FunctionComponent } from 'react'
import { Field, Form } from 'react-final-form'
import { Link } from 'react-router-dom'
import { ReactComponent as EmailIcon } from '../../../icons/email.svg'
import { ReactComponent as LockIcon } from '../../../icons/lock.svg'
import ErrorComponent from '../../../shared/components/ErrorComponent'
import { renderInputField as renderInput } from '../../../shared/components/formFields'
import LinkButton from '../../../shared/components/LinkButton'
import SubmitButton from '../../../shared/components/SubmitButton'
import {
  extractEmailError,
  extractError,
} from '../../../shared/errorExtractors'
import useMaybeNotify from '../../../shared/hooks/useMaybeNotify'
import styles from '../authStyles'
import { host } from '../../../shared/api'

interface Props {
  redirectTo?: string
  setMfaProps: (email: string, password: string) => void
}

interface FormData {
  email: string
  password: string
}

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  // these are to silence the error
  // Warning: [JSS] Could not find the referenced rule "login" in "makeStyles".
  // but where is login used? can we delete it?
  signup: {},
  login: {},
  linkSecondary: {
    fontSize: '0.75rem',
  },
}))

const LoginForm: FunctionComponent<Props> = (props) => {
  const { redirectTo, setMfaProps } = props
  const classes = useStyles(props)

  const [loading, setLoading] = useSafeSetState<boolean>(false)

  const login = useLogin()
  const notify = useMaybeNotify()
  const translate = useTranslate()

  const validate = (values: FormData) => {
    const errors: { email?: string; password?: string } = {
      email: undefined,
      password: undefined,
    }

    if (!values.email) {
      errors.email = translate('ra.validation.required')
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required')
    }
    return errors
  }

  const submit = (values: FormData) => {
    setLoading(true)
    return login(values, redirectTo)
      .then(() => {
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        if (error.status === 303) {
          setMfaProps(values.email, values.password)

          // this can't be empty or it thinks it succeeded..
          return {
            [FORM_ERROR]: 'Multi-factor authentication code required',
          }
        }
        if (typeof error === 'string') {
          notify(error, 'warning')
        } else if (!error || !error.message) {
          notify('ra.auth.sign_in_error', 'warning')
        } else {
          notify(error.message, 'warning')
        }
        return {
          email: extractEmailError(error.body.errors),
          password: extractError(error.body.errors, 'password'),
        }
      })
  }

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({
        handleSubmit,
        submitError,
        hasValidationErrors,
        hasSubmitErrors,
        modifiedSinceLastSubmit,
        pristine,
        submitting,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            {submitError && (
              <FormHelperText error>
                <ErrorComponent>{submitError}</ErrorComponent>
              </FormHelperText>
            )}

            <div className={classes.form}>
              <div className={classes.input}>
                <Field
                  id="email"
                  name="email"
                  component={renderInput}
                  fullWidth
                  placeholder="Email"
                  disabled={loading}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon className={classes.icon}>
                          <EmailIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className={classes.input}>
                <Field
                  id="password"
                  name="password"
                  component={renderInput}
                  fullWidth
                  placeholder={translate('ra.auth.password')}
                  type="password"
                  disabled={loading}
                  autoComplete="current-password"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon className={classes.icon}>
                          <LockIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <SubmitButton
              {...{
                hasValidationErrors,
                hasSubmitErrors,
                modifiedSinceLastSubmit,
                pristine,
                submitting,
              }}
              color="primary"
              label="Sign In"
              variant="contained"
              className={classes.button}
            />
            <div className={classes.links}>
              <div className={classnames(classes.link, classes.linkSecondary)}>
                <Link to="/password/reset">Forgot Password</Link>
              </div>
              <div className={classnames(classes.link, classes.linkSecondary)}>
                <Link to="/confirmation/resend">Resend Confirmation Link</Link>
              </div>
            </div>
            <LinkButton
              color="primary"
              label="Sign In with Google"
              link={host + "/oauth/google"}
              variant="contained"
              className={classes.button}
            />
            <div className={classes.link}>
              Don&rsquo;t have an account?{' '}
              <Link to="/register" className={classes.signup}>
                Create Account
              </Link>
            </div>
          </form>
        )
      }}
    />
  )
}

export default LoginForm
