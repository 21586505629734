import React, { FunctionComponent } from 'react'
import { useMutation, useRefresh } from 'ra-core'
import { FORM_ERROR } from 'final-form'
import { Field, Form } from 'react-final-form'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
} from '@material-ui/core'
import { App } from './actions'
import { extractError } from '../../../shared/errorExtractors'
import ErrorComponent from '../../../shared/components/ErrorComponent'
import { renderTextField } from '../../../shared/components/formFields'
import CancelButton from '../../../shared/components/CancelButton'
import SubmitButton from '../../../shared/components/SubmitButton'
import useMaybeNotify from '../../../shared/hooks/useMaybeNotify'
import {
  composeValidators,
  isNumber,
  maxValue,
  minValue,
  required,
} from '../../../utils/validators'

const validateSize = composeValidators([
  required(),
  isNumber(),
  minValue(0.2, 'Must be at least 0.2'),
  maxValue(60, 'Please contact enterprise@gigalixir.com to scale above 60.'),
])
const validateReplicas = composeValidators([
  isNumber(),
  minValue(0, 'Must be non-negative'),
  maxValue(60, 'Please contact enterprise@gigalixir.com to scale above 60.'),
])

const renderSizeField = renderTextField({ type: 'input' })
const renderReplicasField = renderTextField({ type: 'number' })

interface ScaleProps {
  onSuccess: () => void
  onCancel: () => void
  app: App
}

interface FormData {
  size: string
  replicas: string
}

const ScaleDialog: FunctionComponent<ScaleProps> = (props) => {
  const { onSuccess, onCancel, app } = props
  const [mutate] = useMutation()
  const notify = useMaybeNotify()
  const refresh = useRefresh()

  const submit = ({ size, replicas }: FormData) => {
    // Do we need to do something like this instead?
    // handleBlur = event => {
    /**
     * Necessary because of a React bug on <input type="number">
     * @see https://github.com/facebook/react/issues/1425
     */
    // const numericValue = isNaN(parseFloat(event.target.value))
    //     ? null
    //     : parseFloat(event.target.value);
    // this.props.onBlur(numericValue);
    // this.props.input.onBlur(numericValue);
    // };

    const newApp = {
      ...app,
      size: size ? parseFloat(size) : app.size,
      replicas: replicas ? parseInt(replicas, 10) : app.replicas,
    }
    return new Promise((resolve) => {
      mutate(
        {
          type: 'update',
          resource: 'apps',
          payload: { id: app.id, data: newApp, previousData: app },
        },
        {
          onSuccess: () => {
            notify('App scaled')
            onSuccess()
            resolve()
            refresh()
          },
          onFailure: ({ body: { errors } }) => {
            resolve({
              [FORM_ERROR]: extractError(errors, ''),
              size: extractError(errors, 'size'),
              replicas: extractError(errors, 'replicas'),
            })
          },
        }
      )
    })
  }
  return (
    <Form
      onSubmit={submit}
      render={({
        handleSubmit,
        submitError,
        hasSubmitErrors,
        hasValidationErrors,
        modifiedSinceLastSubmit,
        pristine,
        submitting,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">Scale</DialogTitle>
            {submitError && (
              <DialogContent>
                <FormHelperText error>
                  <ErrorComponent>{submitError}</ErrorComponent>
                </FormHelperText>
              </DialogContent>
            )}
            <DialogContent>
              <Field
                validate={validateSize}
                component={renderSizeField}
                name="size"
                label="Size (GB of Memory)"
                initialValue={`${app.size}`}
                fullWidth
              />
            </DialogContent>
            <DialogContent>
              <Field
                validate={validateReplicas}
                component={renderReplicasField}
                name="replicas"
                label="Replicas"
                initialValue={`${app.replicas}`}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <CancelButton onClick={onCancel} />
              <SubmitButton
                {...{
                  hasValidationErrors,
                  hasSubmitErrors,
                  modifiedSinceLastSubmit,
                  pristine,
                  submitting,
                }}
                label="Scale"
                variant="contained"
                size="small"
              />
            </DialogActions>
          </form>
        )
      }}
    />
  )
}

export default ScaleDialog
