import React, { ReactNode, FunctionComponent, useState } from 'react'
import {
  Dialog,
  Button,
  Link,
  makeStyles,
  PropTypes as MaterialUiPropTypes,
} from '@material-ui/core'

const useStyles = makeStyles({
  componentRoot: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
})

export type CloseFunction = (
  event?: React.KeyboardEvent | React.MouseEvent
) => void
interface Props {
  classes?: Record<string, string>
  label: ReactNode
  className?: string
  containerClassName?: string
  size?: 'small' | 'medium' | 'large'
  color?: MaterialUiPropTypes.Color
  disabled?: boolean
  children: (close: CloseFunction) => ReactNode
  component?: 'link' | 'button' | ReactNode
}

const DialogButton: FunctionComponent<Props> = (props) => {
  const {
    classes: styles,
    label,
    disabled,
    children,
    className,
    color,
    size,
    containerClassName,
    component,
  } = props

  const classes = {
    ...useStyles(),
    ...styles,
  }

  const [isOpen, setIsOpen] = useState(false)

  const toggleDrawer = (open: boolean) => (
    event?: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    setIsOpen(open)
  }

  return (
    <div className={containerClassName || ''}>
      {!component || component === 'button' || component === 'link' ? (
        <Button
          component={component === 'link' ? Link : Button}
          variant={component === 'link' ? 'text' : 'contained'}
          classes={{ root: component === 'link' ? classes.componentRoot : '' }}
          disableRipple={component === 'link'}
          onClick={toggleDrawer(true)}
          size={size || 'small'}
          color={color || 'primary'}
          disabled={disabled}
          className={className || ''}
        >
          {label}
        </Button>
      ) : (
        <span role="presentation" onClick={toggleDrawer(true)}>
          {component}
        </span>
      )}
      <Dialog open={isOpen} aria-labelledby="form-dialog-title">
        {children(toggleDrawer(false))}
      </Dialog>
    </div>
  )
}

export default DialogButton
