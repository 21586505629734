import React, { FunctionComponent } from 'react'
import { useQueryWithStore } from 'ra-core'
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core'
import CreateDialog from './CreateDialog'
import DeleteButton from './DeleteButton'
import Loading from '../../../shared/components/Loading'
import ErrorComponent from '../../../shared/components/ErrorComponent'
import TableWrapper from '../../../shared/components/TableWrapper'
import DialogButton, {
  CloseFunction,
} from '../../../shared/components/DialogButton'

const useStyles = makeStyles((theme) => ({
  cell: {
    maxWidth: 0,
  },
}))

interface Props {
  id: string
}

const Permissions: FunctionComponent<Props> = ({ id }) => {
  const classes = useStyles()
  const { data, loading, error } = useQueryWithStore({
    type: 'getOne',
    resource: 'permissions',
    payload: {
      id,
    },
  })

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <ErrorComponent>{error.message}</ErrorComponent>
  }

  return (
    <>
      <DialogButton label="Add">
        {(close: CloseFunction) => {
          return <CreateDialog id={id} close={close} />
        }}
      </DialogButton>
      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.cell}>{data?.owner}</TableCell>
              <TableCell className={classes.cell}>Owner</TableCell>
              <TableCell className={classes.cell} />
            </TableRow>
            {data?.collaborators.map((email: string) => (
              <TableRow key={email}>
                <TableCell className={classes.cell}>{email}</TableCell>
                <TableCell className={classes.cell}>Collaborator</TableCell>
                <TableCell className={classes.cell}>
                  <DeleteButton email={email} id={id} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
    </>
  )
}

export default Permissions
