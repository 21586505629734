import * as canaries from './actions'
import { UpdateCanaryParams, DeleteCanaryParams } from './dataProviderParams'
import { UpdateParams } from '../../../shared/dataProvider/interfaces'
import { isGetOne } from '../../../shared/dataProvider/typeCheckers'
import { Method, Resource, Params } from '../../../shared/dataProvider/types'

const isUpdateCanary = (
  method: Method,
  resource: Resource,
  params: Params
): params is UpdateParams<UpdateCanaryParams> =>
  method === 'UPDATE' && resource === 'canaries'

const isDeleteCanary = (
  method: Method,
  resource: Resource,
  params: Params
): params is DeleteCanaryParams =>
  method === 'DELETE' && resource === 'canaries'

const provider = (
  method: Method,
  resource: Resource,
  params: Params
): Promise<unknown> => {
  if (isDeleteCanary(method, resource, params)) {
    return canaries.del(params.appId, params.canary)
  }
  if (isUpdateCanary(method, resource, params)) {
    const { canary, weight } = params.data
    return canaries.update(params.id, canary, weight)
  }
  if (isGetOne(method, resource, params)) {
    return canaries.get(params.id)
  }
  throw new Error(`${method} ${resource} not implemented yet`)
}

export default provider
