import React, { FunctionComponent } from 'react'
import { Button } from '@material-ui/core'

interface Props {
  label: string
  link: string
  className?: string
  variant?: 'text' | 'outlined' | 'contained' | undefined
  color?: 'inherit' | 'primary' | 'secondary' | 'default' | undefined
  size?: 'small' | 'medium' | 'large' | undefined
}

const LinkButton: FunctionComponent<Props> = (props) => {
  const {
    variant,
    label,
    link,
    color,
    size,
    className,
  } = props
  return (
    <Button
      onClick = {e => window.location.href = link}
      color={color || 'primary'}
      size={size}
      variant={variant}
      className={className}
    >
      {label}
    </Button>
  )
}

export default LinkButton
