import * as sshKeys from './actions'
import { CreateSshKeyParams, DeleteSshKeyParams } from './dataProviderParams'
import { CreateParams } from '../../../shared/dataProvider/interfaces'
import { isGetList } from '../../../shared/dataProvider/typeCheckers'
import { Method, Resource, Params } from '../../../shared/dataProvider/types'

const isCreateSshKey = (
  method: Method,
  resource: Resource,
  params: Params
): params is CreateParams<CreateSshKeyParams> =>
  method === 'CREATE' && resource === 'ssh_keys'

const isDeleteSshKey = (
  method: Method,
  resource: Resource,
  params: Params
): params is DeleteSshKeyParams =>
  method === 'DELETE' && resource === 'ssh_keys'

const provider = (
  method: Method,
  resource: Resource,
  params: Params
): Promise<unknown> => {
  if (isDeleteSshKey(method, resource, params)) {
    return sshKeys.del(params.id)
  }
  if (isCreateSshKey(method, resource, params)) {
    const { sshKey } = params.data
    return sshKeys.create(sshKey)
  }
  if (isGetList(method, resource, params)) {
    return sshKeys.list()
  }
  throw new Error(`${method} ${resource} not implemented yet`)
}

export default provider
