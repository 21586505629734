import React, { FunctionComponent } from 'react'
import { useMutation, useRefresh } from 'ra-core'
import { FORM_ERROR } from 'final-form'
import { Field, Form } from 'react-final-form'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
} from '@material-ui/core'
import { extractError } from '../../../shared/errorExtractors'
import { CloseFunction } from '../../../shared/components/DialogButton'
import { renderTextField } from '../../../shared/components/formFields'
import ErrorComponent from '../../../shared/components/ErrorComponent'
import CancelButton from '../../../shared/components/CancelButton'
import SubmitButton from '../../../shared/components/SubmitButton'
import useMaybeNotify from '../../../shared/hooks/useMaybeNotify'
import { required } from '../../../utils/validators'

const validateEmail = required()
const renderEmailField = renderTextField({ type: 'input' })

interface Props {
  close: CloseFunction
  id: string // app id
}

interface FormData {
  email: string
}

const CollaboratorAdd: FunctionComponent<Props> = (props) => {
  const { close, id } = props
  const [mutate] = useMutation()
  const notify = useMaybeNotify()
  const refresh = useRefresh()

  const onCancel = () => {
    close()
  }

  const submit = ({ email }: FormData) => {
    return new Promise((resolve) => {
      mutate(
        {
          type: 'create',
          resource: 'permissions',
          payload: {
            data: { id, email },
          },
        },
        {
          onSuccess: () => {
            notify('Collaborator added')
            close()
            resolve()
            refresh()
          },
          onFailure: ({ body: { errors } }) => {
            resolve({
              [FORM_ERROR]: extractError(errors, ''),
              email: extractError(errors, 'email'),
            })
          },
        }
      )
    })
  }

  return (
    <Form
      onSubmit={submit}
      render={({
        handleSubmit,
        submitError,
        submitting,
        pristine,
        hasValidationErrors,
        hasSubmitErrors,
        modifiedSinceLastSubmit,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">Add Collaborator</DialogTitle>
            {submitError && (
              <DialogContent>
                <FormHelperText error>
                  <ErrorComponent>{submitError}</ErrorComponent>
                </FormHelperText>
              </DialogContent>
            )}
            <DialogContent>
              <Field
                validate={validateEmail}
                component={renderEmailField}
                name="email"
                label="Email"
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <CancelButton onClick={onCancel} />
              <SubmitButton
                {...{
                  hasValidationErrors,
                  hasSubmitErrors,
                  modifiedSinceLastSubmit,
                  pristine,
                  submitting,
                }}
                label="Add"
                variant="contained"
                size="small"
              />
            </DialogActions>
          </form>
        )
      }}
    />
  )
}

export default CollaboratorAdd
