import React, { FunctionComponent } from 'react'
import { useMutation, useRefresh } from 'ra-core'
import { FORM_ERROR } from 'final-form'
import { extractError } from '../../../shared/errorExtractors'
import ConfirmationDialog from '../../../shared/components/ConfirmationDialog'
import useMaybeNotify from '../../../shared/hooks/useMaybeNotify'

interface Props {
  onSuccess: () => void
  onCancel: () => void
  appId: string
  id: string
  url: string
}

const LogDrainDeleteDialog: FunctionComponent<Props> = (props) => {
  const { onSuccess, onCancel, appId, id, url } = props

  const [mutate] = useMutation()
  const notify = useMaybeNotify()
  const refresh = useRefresh()

  const onDelete = (resolve: <T>(value?: T | PromiseLike<T>) => void): void => {
    mutate(
      {
        type: 'delete',
        resource: 'log_drains',
        payload: { appId, id },
      },
      {
        onSuccess: () => {
          notify('Log Drain deleted')
          onSuccess()
          resolve()
          refresh()
        },
        onFailure: ({ body: { errors } }) => {
          resolve<Record<string, string>>({
            [FORM_ERROR]: extractError(errors, ''),
          })
        },
      }
    )
  }

  return (
    <ConfirmationDialog
      onCancel={onCancel}
      title="Delete Log Drain"
      onConfirm={onDelete}
    >
      {`Are you sure want to remove ${url}?`}
    </ConfirmationDialog>
  )
}

export default LogDrainDeleteDialog
