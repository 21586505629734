import classnames from 'classnames'
import React, { FunctionComponent, ReactNode } from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  label: {
    color: 'rgba(0,0,0,0.5)',
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(0.5),
  },
  value: {},
  field: {
    flex: 1,
  },
  horizontal: {
    '&$tabular': {
      display: 'table-row',
      '& $label': {
        display: 'table-cell',
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 'bold',
        padding: theme.spacing(0.75, 0),
        color: '#535353',
      },
      '& $value': {
        display: 'table-cell',
        paddingLeft: theme.spacing(3),
        fontSize: theme.typography.pxToRem(12),
        padding: theme.spacing(0.75, 0),
        color: '#535353',
      },
    },
  },
  tabular: {},
  card: {
    flex: 1,
    marginRight: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1.5),
    maxWidth: theme.spacing(14),
    '&:last-child': {
      marginRight: 0,
    },
    '& $label': {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 'bold',
      textTransform: 'uppercase',
      padding: 0,
      paddingBottom: theme.spacing(),
      color: '#535353',
    },
    '& $value': {
      fontSize: theme.typography.pxToRem(12),
      color: '#535353',
      wordBreak: 'break-all',
    },
  },
}))

interface Props {
  classes?: Record<string, string>
  label: string | ReactNode
  children?: ReactNode
  isHorizontal?: boolean
  isTabular?: boolean
  isCard?: boolean
  className?: string
}

const Field: FunctionComponent<Props> = (props) => {
  const {
    classes: styles,
    label,
    children,
    isHorizontal,
    isTabular,
    isCard,
    className,
  } = props

  const classes = {
    ...useStyles(),
    ...styles,
  }

  if (children == null) {
    return <div />
  }

  return (
    <div
      className={classnames(classes.field, className, {
        [classes.horizontal]: isHorizontal,
        [classes.tabular]: isTabular,
        [classes.card]: isCard,
      })}
    >
      <div className={classes.label}>{label}</div>
      <div className={classes.value}>{children}</div>
    </div>
  )
}

export default Field
