import { Theme, createStyles } from '@material-ui/core'

const styles = (theme: Theme) =>
  createStyles({
    form: {},
    input: {
      marginTop: theme.spacing(2),
    },
    icon: {
      height: 16,
      width: 16,
      fill: 'rgba(0, 0, 0, 0.54)',
    },
    button: {
      width: '100%',
      margin: theme.spacing(2, 0),
      padding: theme.spacing(0.3, 0),
    },
    links: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2),
    },
    link: {
      fontSize: '0.875rem',
      textAlign: 'center',
      color: '#666',
      '& a': {
        textDecoration: 'none',
        color: '#666',
        '&$signup': {
          color: '#3f51b5',
        },
        '&$login': {
          color: '#3f51b5',
        },
      },
    },
  })

export default styles
