import React, { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/core'
import * as zoom from 'chartjs-plugin-zoom'
import { Line } from 'react-chartjs-2'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Chartjs = require('react-chartjs-2').Chart

Chartjs.plugins.register(zoom)

interface Props {
  classes?: Record<string, string>
  title: string
  fillColor?: string
  data: ChartPoint[]
}

export interface ChartPoint {
  x: number
  y: number | null
}

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    // this is paired with the parent's negative margin
    // to produce space between elements
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(15),
    color: '#535353',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(3),
  },
}))

const Chart: FunctionComponent<Props> = (props) => {
  const { classes: styles, data, title, fillColor } = props

  const classes = {
    ...useStyles(),
    ...styles,
  }

  const datasets = {
    datasets: [
      {
        pointRadius: 0,
        fill: true,
        borderColor: fillColor,
        backgroundColor: fillColor,
        data,
      },
    ],
  }
  const options = {
    pan: {
      enabled: true,
      mode: 'x',
    },
    zoom: {
      enabled: true,
      mode: 'x',
    },
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          type: 'time',
          gridLines: {
            display: false,
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 6,
            maxRotation: 0,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
  }

  return (
    <div className={classes.container}>
      <div className={classes.title}>{title}</div>
      <Line height={150} data={datasets} options={options} />
    </div>
  )
}

export default Chart
