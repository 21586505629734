import * as api from '../../shared/api'

type tier = 'STANDARD' | 'FREE'
interface ServerResponse {
  tier: tier
  // eslint-disable-next-line camelcase
  api_key: string
  email: string
  // eslint-disable-next-line camelcase
  credit_cents: number
}
export interface User {
  id: 'ignored'
  tier: tier
  apiKey: string
  email: string
  creditCents: number
}

export const get = (): Promise<{ data: User }> => {
  return api
    .get<{ data: ServerResponse }>(`/frontend/api/users`)
    .then((response): {
      data: User
    } => {
      const user = response.data.data
      // eslint-disable-next-line camelcase
      const { api_key, credit_cents, ...rest } = user
      return {
        data: {
          ...rest,
          id: 'ignored',
          apiKey: api_key,
          creditCents: credit_cents,
        },
      }
    })
}
