import Url from 'url-parse'

// react-admin's validators try to translate which is annoying
// so we create our own here

import { FieldValidator, FieldState } from 'final-form'

/* eslint-disable @typescript-eslint/no-explicit-any */
export const required = (message = 'Required') => (
  value: any
): string | undefined => (value ? undefined : message)
export const isNumber = (message = 'Must be a number') => (
  value: any
): string | undefined =>
  // eslint-disable-next-line no-restricted-globals
  value && isNaN(Number(value)) ? message : undefined
export const minValue = (min: number, message = 'Too small') => (
  value: any
): string | undefined => (value && value < min ? message : undefined)
export const maxValue = (max: number, message = 'Too big') => (
  value: any
): string | undefined => (value && value > max ? message : undefined)
export const choices = (list: any[], message = 'Invalid choice') => (
  value: any
): string | undefined => (list.indexOf(value) === -1 ? message : undefined)
export const url = (protocols: string[], message = 'Invalid URL') => (
  value: string
): string | undefined => {
  const _url = new Url(value, {})
  if (protocols.indexOf(_url.protocol.slice(0, -1)) === -1) return message
  return undefined
}
export const pattern = (regExp: RegExp, message = 'Invalid') => (
  value: string
): string | undefined => (!regExp.test(value) ? message : undefined)
export const composeValidators = (validators: Array<FieldValidator<any>>) => (
  value: any,
  // from final-form, so I don't want to change it
  // eslint-disable-next-line @typescript-eslint/ban-types
  allValues: object,
  meta?: FieldState<any>
): string | undefined =>
  validators.reduce(
    (error: string | undefined, validator: FieldValidator<any>) =>
      error || validator(value, allValues, meta),
    undefined
  )

export const regExpPatterns: Record<string, RegExp> = {
  domain: /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
}
