import * as api from '../../../shared/api'

export interface Configuration {
  name: string
  value: string
}

export const get = (appId: string): Promise<{ data: Configuration[] }> => {
  return api
    .get<{ data: Record<string, string> }>(
      `/frontend/api/apps/${appId}/configs`
    )
    .then((response): { data: Configuration[] } => {
      const configurations = response.data.data
      return {
        data: Object.keys(configurations).map((key) => ({
          name: key,
          value: configurations[key],
        })),
      }
    })
}

export const update = (
  appId: string,
  name: string,
  value: string
): Promise<{ data: string }> => {
  return api
    .post<{ data: string }>(`/frontend/api/apps/${appId}/configs`, {
      configs: {
        [name]: value,
      },
    })
    .then((response): { data: string } => {
      const message = response.data.data
      return { data: message }
    })
}

export const del = (appId: string, name: string): Promise<{ data: string }> => {
  return api
    .del<{ data: string }>(`/frontend/api/apps/${appId}/configs`, {
      key: name,
    })
    .then((response): { data: string } => {
      const message = response.data.data
      return { data: message }
    })
}
