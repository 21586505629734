import * as domains from './actions'
import { CreateDomainParams, DeleteDomainParams } from './dataProviderParams'
import { CreateParams } from '../../../shared/dataProvider/interfaces'
import { isGetListByID } from '../../../shared/dataProvider/typeCheckers'
import { Method, Resource, Params } from '../../../shared/dataProvider/types'

const isCreateDomain = (
  method: Method,
  resource: Resource,
  params: Params
): params is CreateParams<CreateDomainParams> =>
  method === 'CREATE' && resource === 'domains'

const isDeleteDomain = (
  method: Method,
  resource: Resource,
  params: Params
): params is DeleteDomainParams => method === 'DELETE' && resource === 'domains'

const provider = (
  method: Method,
  resource: Resource,
  params: Params
): Promise<unknown> => {
  if (isDeleteDomain(method, resource, params)) {
    return domains.del(params.appId, params.fqdn)
  }
  if (isGetListByID(method, resource, params)) {
    return domains.list(params.id)
  }
  if (isCreateDomain(method, resource, params)) {
    const { appId, fqdn } = params.data
    return domains.create(appId, fqdn)
  }
  throw new Error(`${method} ${resource} not implemented yet`)
}

export default provider
