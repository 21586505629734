import React, { FunctionComponent } from 'react'
import { useQueryWithStore } from 'ra-core'
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core'
import RestoreDialog from './RestoreDialog'
import { Backup } from '../../../actions'
import Loading from '../../../../../../shared/components/Loading'
import ErrorComponent from '../../../../../../shared/components/ErrorComponent'
import NoData from '../../../../../../shared/components/NoData'
import DialogButton from '../../../../../../shared/components/DialogButton'
import dateFormatter from '../../../../../../utils/formatters/dateFormatters'

interface Props {
  appId: string
  id: string
}

const useStyles = makeStyles({
  loading: {
    marginTop: 80,
    marginBottom: 40,
  },
  backups: {},
})

const Backups: FunctionComponent<Props> = (props) => {
  const { appId, id } = props
  const {
    data: backups,
    loading,
    error,
  }: {
    data?: Backup[]
    loading: boolean
    error?: Error
  } = useQueryWithStore({
    type: 'GET_BACKUPS',
    resource: 'databases',
    payload: {
      appId,
      id,
    },
  })

  const classes = useStyles()

  if (loading) {
    return <Loading className={classes.loading} />
  }

  if (error) {
    return <ErrorComponent>{error.message}</ErrorComponent>
  }

  if (!(backups && backups.length > 0)) {
    return <NoData message="No backups found" />
  }

  return (
    <div className={classes.backups}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Start Time</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {backups.map((backup) => (
            <TableRow key={backup.id}>
              <TableCell>{backup.id}</TableCell>
              <TableCell>{backup.type}</TableCell>
              <TableCell>{backup.status}</TableCell>
              <TableCell>{dateFormatter(backup.startTime)}</TableCell>
              <TableCell align="right">
                <DialogButton label="Restore">
                  {(close) => (
                    <RestoreDialog
                      onSuccess={() => close()}
                      onCancel={() => close()}
                      appId={appId}
                      id={id}
                      backupId={backup.id}
                    />
                  )}
                </DialogButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default Backups
