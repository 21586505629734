import React, { FunctionComponent, useState } from 'react'
import { Button, Link, makeStyles } from '@material-ui/core'

interface Props {
  children: string
}

const useStyles = makeStyles((theme) => ({
  value: {
    whiteSpace: 'pre-line',
  },
  visibilityHandler: {
    padding: 0,
    margin: theme.spacing(0, 1),
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))

const ConfigurationValue: FunctionComponent<Props> = (props) => {
  const { children } = props
  const classes = useStyles()

  const [isValueVisible, setIsValueVisible] = useState(false)

  return (
    <>
      <span className={classes.value}>
        {isValueVisible ? children : '**********'}
      </span>
      <Button
        component={Link}
        disableRipple
        onClick={() => setIsValueVisible((prevValue) => !prevValue)}
        color="primary"
        size="small"
        className={classes.visibilityHandler}
      >
        {isValueVisible ? 'Hide' : 'Show'}
      </Button>
    </>
  )
}

export default ConfigurationValue
