import React, { FunctionComponent } from 'react'
import { useQueryWithStore } from 'ra-core'
import { makeStyles, Button } from '@material-ui/core'
import UpsertDialog from './UpsertDialog'
import DeleteDialog from './DeleteDialog'
import { Canary } from './actions'
import Loading from '../../../shared/components/Loading'
import ErrorComponent from '../../../shared/components/ErrorComponent'
import NoData from '../../../shared/components/NoData'
import DialogButton from '../../../shared/components/DialogButton'
import Fields from '../../../shared/components/Fields'
import Field from '../../../shared/components/Field'
import ButtonPanel from '../../../shared/components/ButtonPanel'

interface Props {
  appId: string
}

const useStyles = makeStyles((theme) => ({
  canaryInfo: {
    marginTop: 20,
  },
}))

const Canaries: FunctionComponent<Props> = (props) => {
  const { appId } = props

  const classes = useStyles()

  const {
    data: canary,
    loading,
    error,
  }: {
    data?: Canary
    loading: boolean
    error?: Error
  } = useQueryWithStore({
    type: 'getOne',
    resource: 'canaries',
    payload: {
      id: appId,
    },
  })

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <ErrorComponent>{error.message}</ErrorComponent>
  }

  if (!(canary && Object.keys(canary).length > 0)) {
    return (
      <NoData
        note={
          <>
            <span>
              A canary lets you direct a percentage of your traffic to another
              app. This is useful for testing new deployments on a small amount
              of traffic or for blue-green deployment.
            </span>
            <a
              href="https://gigalixir.readthedocs.io/en/latest/deploy.html#how-to-do-blue-green-or-canary-deploys"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More
            </a>
          </>
        }
        message="No canary yet."
      >
        <DialogButton label="Add">
          {(close) => (
            <UpsertDialog
              onSuccess={() => close()}
              onCancel={() => close()}
              appId={appId}
            />
          )}
        </DialogButton>
      </NoData>
    )
  }

  return (
    <>
      <ButtonPanel>
        <Button
          href={`/#/apps/${canary.canary}/dashboard`}
          variant="contained"
          color="primary"
          size="small"
        >
          Dashboard
        </Button>
        <DialogButton label="Edit">
          {(close) => (
            <UpsertDialog
              onSuccess={() => close()}
              onCancel={() => close()}
              appId={appId}
              initialValues={canary}
            />
          )}
        </DialogButton>
        <DialogButton label="Remove">
          {(close) => (
            <DeleteDialog
              onSuccess={() => close()}
              onCancel={() => close()}
              appId={appId}
              canary={canary.canary}
            />
          )}
        </DialogButton>
      </ButtonPanel>
      <div className={classes.canaryInfo}>
        <Fields>
          <Field label="Name" isCard>
            {canary.canary}
          </Field>
          <Field label="Weight" isCard>
            {canary.weight}
          </Field>
        </Fields>
      </div>
    </>
  )
}

export default Canaries
