import qs from 'query-string'
import React, { FunctionComponent } from 'react'
import { useRedirect } from 'ra-core'
import useMaybeNotify from '../../shared/hooks/useMaybeNotify'

const NotifyPage: FunctionComponent<{ location: { search: string } }> = (
  props
) => {
  const notify = useMaybeNotify()
  const redirect = useRedirect()
  const { location } = props
  const params = qs.parse(location.search)
  if (typeof params.msg === 'string') {
    if (params.type === 'error' || params.type === 'warning') {
      notify(params.msg, params.type)
    } else {
      notify(params.msg, 'info')
    }
  }
  if (typeof params.to === 'string') {
    redirect(params.to)
  }
  return <div>{params.msg}</div>
}

export default NotifyPage
