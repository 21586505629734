import React, { FunctionComponent } from 'react'
import { useMutation, useRefresh } from 'ra-core'
import { FORM_ERROR } from 'final-form'
import { extractError } from '../../../shared/errorExtractors'
import ConfirmationDialog from '../../../shared/components/ConfirmationDialog'
import useMaybeNotify from '../../../shared/hooks/useMaybeNotify'

interface Props {
  onSuccess: () => void
  onCancel: () => void
  appId: string
}

const RestartDialog: FunctionComponent<Props> = (props) => {
  const { onSuccess, onCancel, appId } = props

  const [mutate] = useMutation()
  const notify = useMaybeNotify()
  const refresh = useRefresh()

  const onRestart = (
    resolve: <T>(value?: T | PromiseLike<T>) => void
  ): void => {
    mutate(
      {
        type: 'RESTART',
        resource: 'apps',
        payload: { id: appId },
      },
      {
        onSuccess: (response) => {
          notify(response.data)
          onSuccess()
          resolve()
          refresh()
        },
        onFailure: ({ body: { errors } }) => {
          resolve<Record<string, string>>({
            [FORM_ERROR]: extractError(errors, ''),
          })
        },
      }
    )
  }

  return (
    <ConfirmationDialog
      onCancel={onCancel}
      title="Restart App"
      onConfirm={onRestart}
    >
      {`Are you sure want to restart ${appId}?`}
    </ConfirmationDialog>
  )
}

export default RestartDialog
