import React, { FunctionComponent } from 'react'
import { Button, makeStyles } from '@material-ui/core'
import ScaleDialog from './ScaleDialog'
import RestartDialog from './RestartDialog'
import DeleteDialog from './DeleteDialog'
import EditDialog from './EditDialog'
import Replicas from './Replicas'
import Charts from './Charts'
import { App } from './actions'
import Section from '../../../shared/components/Section'
import ButtonPanel from '../../../shared/components/ButtonPanel'
import DialogButton from '../../../shared/components/DialogButton'
import Fields from '../../../shared/components/Fields'
import Field from '../../../shared/components/Field'

interface Props {
  app: App
}

const useStyles = makeStyles((theme) => ({
  appInfo: {
    marginTop: theme.spacing(2),
  },
  name: {
    textTransform: 'uppercase',
  },
  replicaWrapper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
    // borderBottom: `${theme.spacing(0.35)}px solid #cccccc`,
  },
  chartsWrapper: {
    // marginTop: 0,
    padding: theme.spacing(3),
  },
}))

const Dashboard: FunctionComponent<Props> = (props) => {
  const { app } = props

  const classes = useStyles()

  return (
    <>
      <ButtonPanel>
        <DialogButton label="Scale">
          {(close) => (
            <ScaleDialog
              app={app}
              onSuccess={() => close()}
              onCancel={() => close()}
            />
          )}
        </DialogButton>
        <DialogButton label="Restart" disabled={app.replicas === 0}>
          {(close) => (
            <RestartDialog
              onSuccess={() => close()}
              onCancel={() => close()}
              appId={app.id}
            />
          )}
        </DialogButton>
        <DialogButton label="Destroy">
          {(close) => (
            <DeleteDialog
              appId={app.id}
              onCancel={() => close()}
              onSuccess={() => close()}
            />
          )}
        </DialogButton>
        <Button
          href={`https://${app.id}.gigalixirapp.com/`}
          target="_blank"
          rel="noopener noreferrer"
          variant="contained"
          color="primary"
          size="small"
          disabled={app.replicas === 0}
        >
          Open
        </Button>
        <DialogButton label="Edit">
          {(close) => (
            <EditDialog
              onSuccess={() => close()}
              onCancel={() => close()}
              app={app}
            />
          )}
        </DialogButton>
      </ButtonPanel>
      <div className={classes.appInfo}>
        <Fields>
          <Field label="Name" isCard>
            {app.id}
          </Field>
          <Field label="Size" isCard>
            {app.size}
          </Field>
          <Field label="Replicas" isCard>
            {app.replicas}
          </Field>
          <Field label="Cloud" isCard>
            {app.cloud}
          </Field>
          <Field label="Region" isCard>
            {app.region}
          </Field>
          <Field label="Stack" isCard>
            {app.stack}
          </Field>
        </Fields>
      </div>
      <Section className={classes.replicaWrapper}>
        <Replicas app={app} />
      </Section>
      <Section className={classes.chartsWrapper}>
        <Charts id={app.id} />
      </Section>
    </>
  )
}

export default Dashboard
