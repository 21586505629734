import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { Slider, FormHelperText } from '@material-ui/core'

export const sliderMarks = [
  {
    value: 0,
    label: 0.6,
  },
  {
    value: 1,
    label: 1.7,
  },
  {
    value: 2,
    label: 4,
  },
  {
    value: 3,
    label: 8,
  },
  {
    value: 4,
    label: 16,
  },
  {
    value: 5,
    label: 32,
  },
  {
    value: 6,
    label: 48,
  },
  {
    value: 7,
    label: 64,
  },
  {
    value: 8,
    label: 96,
  },
]

const SizeSlider = ({
  style,
  marks,
  step,
  min,
  max,
  input,
  meta: { touched, error, submitError },
  ...custom
}: FieldRenderProps<number | number[], HTMLElement>): JSX.Element => {
  let helperText = <span />
  if ((error || submitError) && touched) {
    helperText = (
      <FormHelperText error {...custom}>
        {error || submitError}
      </FormHelperText>
    )
  }

  return (
    <div>
      {helperText}
      <Slider
        {...input}
        {...custom}
        value={input.value}
        // eslint-disable-next-line @typescript-eslint/ban-types
        onChange={(event: object, value: number | number[]) => {
          input.onChange(value)
        }}
        style={style || { marginLeft: 10 }}
        marks={marks || sliderMarks}
        step={step || null}
        min={min || 0}
        max={max || 8}
      />
    </div>
  )
}

export default SizeSlider
