import classnames from 'classnames'
import React, { FunctionComponent } from 'react'
import { useMutation, useRefresh } from 'ra-core'
import { FORM_ERROR } from 'final-form'
import { Field, Form } from 'react-final-form'
import {
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import SizeSlider, { sliderMarks as marks } from './SizeSlider'
import { extractError } from '../../../../shared/errorExtractors'
import ErrorComponent from '../../../../shared/components/ErrorComponent'
import { renderRadioGroup } from '../../../../shared/components/formFields'
import CancelButton from '../../../../shared/components/CancelButton'
import SubmitButton from '../../../../shared/components/SubmitButton'
import useMaybeNotify from '../../../../shared/hooks/useMaybeNotify'

const renderTierField = renderRadioGroup

interface Props {
  appId: string
  onSuccess: () => void
  onCancel: () => void
}
interface FormData {
  tier: 'STANDARD' | 'FREE'
  size: number
}
const useStyles = makeStyles((theme) => ({
  form: {
    minWidth: 600,
  },
  alert: {
    marginTop: theme.spacing(2),
  },
  hidden: {
    display: 'none',
  },
  sizeContainer: {
    display: 'block',
  },
  size: {
    marginRight: theme.spacing(3),
  },
  // duplicated in Field.tsx
  label: {
    color: 'rgba(0,0,0,0.5)',
    padding: theme.spacing(2, 0, 1),
  },
  sizeLabel: {
    padding: theme.spacing(0, 0, 1),
  },
}))

const CreateDialog: FunctionComponent<Props> = (props) => {
  const { appId, onCancel, onSuccess } = props
  const classes = useStyles(props)
  const refresh = useRefresh()
  const notify = useMaybeNotify()
  const [mutate] = useMutation()

  const submit = (values: FormData) => {
    return new Promise((resolve) => {
      mutate(
        {
          type: 'create',
          resource: 'databases',
          payload: {
            data: { size: marks[values.size].label, tier: values.tier, appId },
          },
        },
        {
          onSuccess: () => {
            notify('Database created')
            onSuccess()
            resolve()
            refresh()
          },
          onFailure: ({ body: { errors } }) => {
            resolve({
              [FORM_ERROR]: extractError(errors, ['', 'app_name', 'app_id']),
            })
          },
        }
      )
    })
  }

  return (
    <Form
      onSubmit={submit}
      render={(formRenderProps) => {
        const {
          handleSubmit,
          submitError,
          submitting,
          hasValidationErrors,
          hasSubmitErrors,
          modifiedSinceLastSubmit,
          pristine,
          values,
        } = formRenderProps
        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            <DialogTitle id="form-dialog-title">Create Database</DialogTitle>
            {submitError && (
              <DialogContent>
                <FormHelperText error>
                  <ErrorComponent>{submitError}</ErrorComponent>
                </FormHelperText>
              </DialogContent>
            )}

            <DialogContent>
              {values.tier === 'FREE' && (
                <Alert className={classes.alert} severity="error">
                  <b>Warning:</b> Free tier databases are not suitable for
                  production and migrating from a free db to a standard db is
                  not trivial.
                </Alert>
              )}

              <FormControl>
                <div className={classes.label}>Tier</div>
                <Field name="tier" label="Tier" component={renderTierField} row>
                  <FormControlLabel
                    value="FREE"
                    control={<Radio color="primary" />}
                    label="Free"
                  />
                  <FormControlLabel
                    value="STANDARD"
                    control={<Radio color="primary" />}
                    label="Standard"
                  />
                </Field>
              </FormControl>
            </DialogContent>
            <DialogContent
              className={
                values.tier === 'STANDARD'
                  ? classes.sizeContainer
                  : classes.hidden
              }
            >
              <div className={classes.size}>
                <div className={classnames(classes.label, classes.sizeLabel)}>
                  Size (GB of Memory)
                </div>
                <Field name="size" initialValue={1} component={SizeSlider} />
              </div>
            </DialogContent>
            <DialogActions>
              <CancelButton onClick={onCancel} />
              <SubmitButton
                {...{
                  hasValidationErrors,
                  hasSubmitErrors,
                  modifiedSinceLastSubmit,
                  pristine,
                  submitting,
                }}
                label="Create"
                variant="contained"
                size="small"
              />
            </DialogActions>
          </form>
        )
      }}
    />
  )
}

export default CreateDialog
