import * as paymentMethods from './actions'
import { UpdatePaymentMethodParams } from './dataProviderParams'
import { UpdateParams } from '../../../shared/dataProvider/interfaces'
import { isGetOne } from '../../../shared/dataProvider/typeCheckers'
import { Method, Resource, Params } from '../../../shared/dataProvider/types'

const isUpdatePaymentMethod = (
  method: Method,
  resource: Resource,
  params: Params
): params is UpdateParams<UpdatePaymentMethodParams> =>
  method === 'UPDATE' && resource === 'payment_methods'

const provider = (
  method: Method,
  resource: Resource,
  params: Params
): Promise<unknown> => {
  if (isUpdatePaymentMethod(method, resource, params)) {
    const { token } = params.data
    return paymentMethods.update(token)
  }
  if (isGetOne(method, resource, params)) {
    return paymentMethods.get()
  }
  throw new Error(`${method} ${resource} not implemented yet`)
}

export default provider
