import { useNotify, NotificationType } from 'ra-core'

type NotifyFunction = (
  message: string,
  type?: NotificationType,
  // from react-admin
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  messageArgs?: any,
  undoable?: boolean,
  autoHideDuration?: number
) => void

export const canonicalize = (message: string): string => {
  const msg = message.trim()
  if (msg.indexOf('.') === -1) {
    return msg
  }
  if (msg.indexOf('.') < msg.length - 1) {
    return msg
  }
  if (msg.endsWith('.')) {
    return msg.slice(0, -1)
  }

  // this should actually never happen..
  return msg
}

const useMaybeNotify = (): NotifyFunction => {
  const notify = useNotify()
  return (message, type) => {
    const msg = canonicalize(message)
    if (msg.length > 0) {
      notify(msg, type)
    }
  }
}

export default useMaybeNotify
