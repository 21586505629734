import * as apps from './actions'
import {
  UpdateStackParams,
  CreateAppParams,
  UpdateAppParams,
  DeleteAppParams,
  RestartAppParams,
} from './dataProviderParams'
import {
  CreateParams,
  UpdateParams,
} from '../../../shared/dataProvider/interfaces'
import { isGetList, isGetOne } from '../../../shared/dataProvider/typeCheckers'
import { Method, Resource, Params } from '../../../shared/dataProvider/types'

const isUpdateStack = (
  method: Method,
  resource: Resource,
  params: Params
): params is UpdateParams<UpdateStackParams> =>
  method === 'UPDATE' && resource === 'stack'

const isCreateApp = (
  method: Method,
  resource: Resource,
  params: Params
): params is CreateParams<CreateAppParams> =>
  method === 'CREATE' && resource === 'apps'

const isUpdateApp = (
  method: Method,
  resource: Resource,
  params: Params
): params is UpdateParams<UpdateAppParams> =>
  method === 'UPDATE' && resource === 'apps'

const isDeleteApp = (
  method: Method,
  resource: Resource,
  params: Params
): params is DeleteAppParams => method === 'DELETE' && resource === 'apps'

const isRestartApp = (
  method: Method,
  resource: Resource,
  params: Params
): params is RestartAppParams => method === 'RESTART' && resource === 'apps'

const provider = (
  method: Method,
  resource: Resource,
  params: Params
): Promise<unknown> => {
  if (isUpdateStack(method, resource, params)) {
    const { stack } = params.data
    return apps.updateStack(params.id, stack)
  }
  if (isDeleteApp(method, resource, params)) {
    return apps.del(params.id)
  }
  if (isCreateApp(method, resource, params)) {
    const { name, cloud, region } = params.data
    return apps.create(name, cloud, region)
  }
  if (isUpdateApp(method, resource, params)) {
    return apps
      .scale(params.id, params.data.size, params.data.replicas)
      .then((response) => {
        const { data } = response
        return {
          data: {
            id: params.id,
            size: data.size,
            replicas: data.replicas,
          },
        }
      })
  }
  if (isRestartApp(method, resource, params)) {
    return apps.restart(params.id)
  }
  if (isGetOne(method, resource, params)) {
    return apps.get(params.id)
  }
  if (isGetList(method, resource, params)) {
    return apps.list()
  }
  throw new Error(`${method} ${resource} not implemented yet`)
}

export default provider
