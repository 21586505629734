import React, { ReactNode, FunctionComponent } from 'react'
import { ThemeProvider } from '@material-ui/core'
import theme from '../../theme'
import LayoutContent from './LayoutContent'

interface Props {
  children: ReactNode
}

const Layout: FunctionComponent<Props> = ({ children, ...rest }) => (
  <ThemeProvider theme={theme}>
    <LayoutContent {...rest}>{children}</LayoutContent>
  </ThemeProvider>
)

export default Layout
