import React, { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/core'

interface Props {
  classes?: Record<string, string>
}

const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '0.85em',
    height: theme.spacing(7.5),
  },
  list: {
    display: 'flex',
    alignItems: 'baseline',
    listStyleType: 'none',
    padding: 0,
  },
  listItem: {
    margin: theme.spacing(0.5),
    borderRight: '1px solid rgba(0,0,0,0.2)',
    paddingRight: theme.spacing(1),
    '&:last-child': {
      borderRight: 'none',
    },
  },
  link: {
    color: '#888',
    textDecoration: 'none',
    fontSize: '0.75em',
  },
  copyright: {
    color: '#888',
    fontSize: '0.75em',
    textAlign: 'center',
  },
}))

const Footer: FunctionComponent<Props> = ({ classes: styles }) => {
  const classes = {
    ...useStyles(),
    ...styles,
  }

  return (
    <div className={classes.footer}>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          <a
            className={classes.link}
            href="https://www.gigalixir.com/terms-of-service.html"
          >
            Terms of Service
          </a>
        </li>
        <li className={classes.listItem}>
          <a
            className={classes.link}
            href="https://www.gigalixir.com/privacy-policy.html"
          >
            Privacy Policy
          </a>
        </li>
        <li className={classes.listItem}>
          <a className={classes.link} href="mailto:help@gigalixir.com">
            Contact
          </a>
        </li>
        <li className={classes.listItem}>
          <a className={classes.link} href="https://blog.gigalixir.com/">
            Blog
          </a>
        </li>
      </ul>
      <div className={classes.copyright}>
        Copyright &copy; 2020 Invisible Software
      </div>
    </div>
  )
}

export default Footer
