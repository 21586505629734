import React, { FunctionComponent } from 'react'
import { useMutation, useRefresh } from 'ra-core'
import { FORM_ERROR } from 'final-form'
import {
  extractError,
  extractErrorValue,
} from '../../../shared/errorExtractors'
import ConfirmationDialog from '../../../shared/components/ConfirmationDialog'
import useMaybeNotify from '../../../shared/hooks/useMaybeNotify'

interface Props {
  onSuccess: () => void
  onCancel: () => void
  appId: string
  fqdn: string
}

const DomainDeleteDialog: FunctionComponent<Props> = (props) => {
  const { onSuccess, onCancel, appId, fqdn } = props

  const [mutate] = useMutation()
  const notify = useMaybeNotify()
  const refresh = useRefresh()

  const onDelete = (resolve: <T>(value?: T | PromiseLike<T>) => void): void => {
    mutate(
      {
        type: 'delete',
        resource: 'domains',
        payload: { appId, fqdn },
      },
      {
        onSuccess: () => {
          notify('Domain deleted')
          onSuccess()
          resolve()
          refresh()
        },
        onFailure: ({ body: { errors } }) => {
          resolve<Record<string, string>>({
            [FORM_ERROR]: extractError(errors, ''),
            name: extractErrorValue(errors, 'fully_qualified_domain_name'),
          })
        },
      }
    )
  }

  return (
    <ConfirmationDialog
      onCancel={onCancel}
      title="Delete Domain"
      onConfirm={onDelete}
    >
      {`Are you sure want to delete ${fqdn}?`}
    </ConfirmationDialog>
  )
}

export default DomainDeleteDialog
