import React, { FunctionComponent } from 'react'
import { useMutation, useRefresh } from 'ra-core'
import { FORM_ERROR } from 'final-form'
import { Field, Form } from 'react-final-form'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  ListSubheader,
  MenuItem,
} from '@material-ui/core'
import { Cloud, Region } from './actions'
import { extractError } from '../../../shared/errorExtractors'
import {
  renderSelect,
  renderTextField,
} from '../../../shared/components/formFields'
import ErrorComponent from '../../../shared/components/ErrorComponent'
import CancelButton from '../../../shared/components/CancelButton'
import SubmitButton from '../../../shared/components/SubmitButton'
import useMaybeNotify from '../../../shared/hooks/useMaybeNotify'
import { choices, composeValidators, required } from '../../../utils/validators'

const renderNameField = renderTextField({ type: 'input' })
const renderCloudRegionField = renderSelect

const validateName = required()
const validateCloudRegion = composeValidators([
  required(),
  choices(
    ['v2018-us-central1', 'europe-west1', 'us-east-1', 'us-west-2'],
    'Must be v2018-us-central1 or europe-west1 or us-east-1 or us-west-2'
  ),
])

interface CreateProps {
  onSuccess: () => void
  onCancel: () => void
}
interface FormData {
  name: string
  cloudRegion: string
}

const CreateDialog: FunctionComponent<CreateProps> = (props) => {
  const refresh = useRefresh()
  const [mutate] = useMutation()
  const notify = useMaybeNotify()
  const { onSuccess, onCancel } = props
  const submit = ({ name, cloudRegion }: FormData) => {
    let cloud: Cloud = 'gcp'
    let region: Region = 'v2018-us-central1'
    if (cloudRegion === 'us-east-1' || cloudRegion === 'us-west-2') {
      cloud = 'aws'
      region = cloudRegion
    } else if (
      cloudRegion === 'europe-west1' ||
      cloudRegion === 'v2018-us-central1'
    ) {
      cloud = 'gcp'
      region = cloudRegion
    } else {
      // this should never happen
    }
    const newApp = {
      name,
      cloud,
      region,
    }
    return new Promise((resolve) => {
      mutate(
        {
          type: 'create',
          resource: 'apps',
          payload: { data: newApp },
        },
        {
          onSuccess: () => {
            notify('App created')
            onSuccess()
            resolve()
            refresh()
          },
          onFailure: ({ body: { errors } }) => {
            const formErrors = {
              ...errors,
              name: errors.unique_name,
            }
            resolve({
              [FORM_ERROR]: extractError(formErrors, ''),
              name: extractError(formErrors, 'name'),
              cloudRegion:
                extractError(formErrors, 'cloud') ||
                extractError(formErrors, 'region'),
            })
          },
        }
      )
    })
  }

  return (
    <Form
      onSubmit={submit}
      render={({
        handleSubmit,
        submitError,
        hasSubmitErrors,
        hasValidationErrors,
        modifiedSinceLastSubmit,
        pristine,
        submitting,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">Create App</DialogTitle>
            {submitError && (
              <DialogContent>
                <FormHelperText error>
                  <ErrorComponent>{submitError}</ErrorComponent>
                </FormHelperText>
              </DialogContent>
            )}
            <DialogContent>
              <Field
                validate={validateName}
                component={renderNameField}
                name="name"
                label="App Name"
                fullWidth
              />
            </DialogContent>
            <DialogContent>
              <Field
                validate={validateCloudRegion}
                component={renderCloudRegionField}
                name="cloudRegion"
                label="Region"
                initialValue="v2018-us-central1"
              >
                <ListSubheader>Google Cloud</ListSubheader>
                <MenuItem value="v2018-us-central1">us-central1</MenuItem>
                <MenuItem value="europe-west1">europe-west1</MenuItem>
                <ListSubheader>Amazon Web Services</ListSubheader>
                <MenuItem value="us-east-1">us-east-1</MenuItem>
                <MenuItem value="us-west-2">us-west-2</MenuItem>
              </Field>
            </DialogContent>
            <DialogActions>
              <CancelButton onClick={onCancel} />
              <SubmitButton
                {...{
                  hasValidationErrors,
                  hasSubmitErrors,
                  modifiedSinceLastSubmit,
                  pristine,
                  submitting,
                }}
                label="Create"
                variant="contained"
                size="small"
              />
            </DialogActions>
          </form>
        )
      }}
    />
  )
}

export default CreateDialog
