import React, { FunctionComponent } from 'react'
import { useQueryWithStore, useRedirect } from 'ra-core'
import Profile from './Profile'
import PaymentMethod from './PaymentMethod'
import Invoices from './Invoices'
import ApiKey from './ApiKey'
import SshKeys from './SshKeys'
import Usage from './Usage'
import { User } from './actions'
import PageRoot from '../../shared/components/PageRoot'
import Loading from '../../shared/components/Loading'
import ErrorComponent from '../../shared/components/ErrorComponent'
import NoData from '../../shared/components/NoData'
import Page from '../../shared/components/Page'
import { StyledTabs, StyledTab } from '../../shared/components/Tabs'

interface Props {
  match: {
    params: {
      tab: string
    }
  }
}

const tabs = [
  {
    path: 'profile',
    label: 'Profile',
    element: (user: User) => {
      return <Profile user={user} />
    },
  },
  {
    path: 'payment-method',
    label: 'Payment Method',
    element: () => {
      return <PaymentMethod />
    },
  },
  {
    path: 'invoices',
    label: 'Invoices',
    element: () => {
      return <Invoices />
    },
  },
  {
    path: 'api-key',
    label: 'API Key',
    element: (user: User) => {
      return <ApiKey apiKey={user.apiKey} />
    },
  },
  {
    path: 'ssh-keys',
    label: 'SSH Keys',
    element: () => {
      return <SshKeys />
    },
  },
  {
    path: 'usage',
    label: 'Usage',
    element: () => {
      return <Usage />
    },
  },
]

const Account: FunctionComponent<Props> = (props) => {
  const { match } = props
  const redirect = useRedirect()

  const { data: profile, loading, error } = useQueryWithStore({
    type: 'getOne',
    resource: 'profile',
    payload: {
      id: 'ignored',
    },
  })

  // mui types
  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleTabChange = (event: object, newValue: number) => {
    const tabName = tabs[newValue].path
    redirect(`/account/${tabName}`)
  }

  const selectedTabIndex = () => {
    const index = tabs.findIndex((tab) => {
      return tab.path === match.params.tab
    })

    if (index === -1) {
      return 0
    }

    return index
  }

  const selectedTab = () => {
    const index = selectedTabIndex()
    return tabs[index]
  }

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <ErrorComponent>{error.message}</ErrorComponent>
  }

  if (!profile) {
    return <NoData message="Profile not found" />
  }

  return (
    <Page title="Account">
      <>
        <StyledTabs value={selectedTabIndex()} onChange={handleTabChange}>
          {tabs.map((x) => {
            return <StyledTab key={x.label} label={x.label} />
          })}
        </StyledTabs>
        <PageRoot>{selectedTab().element(profile)}</PageRoot>
      </>
    </Page>
  )
}

export default Account
