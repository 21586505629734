import React, { FunctionComponent, ReactNode } from 'react'
import { Theme, makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

interface StyledTabsProps {
  classes?: Record<string, string>
  value: number
  onChange: (
    event: React.ChangeEvent<Record<string, unknown>>,
    newValue: number
  ) => void
}

const useTabsStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  flexContainer: {
    borderBottom: `${theme.spacing(0.35)}px solid #cccccc`,
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    height: theme.spacing(0.35),
  },
}))

const StyledTabs: FunctionComponent<StyledTabsProps> = (props) => {
  const { classes: styles, ...rest } = props

  const classes = {
    ...useTabsStyles(),
    ...styles,
  }

  return (
    <Tabs
      {...rest}
      classes={classes}
      variant="scrollable"
      scrollButtons="auto"
      TabIndicatorProps={{ children: <div /> }}
    />
  )
}

interface StyledTabProps {
  classes?: Record<string, string>
  label: ReactNode
}

const useTabStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 80,
    textTransform: 'none',
    color: '#535353',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(15),
    minHeight: theme.typography.pxToRem(15),
    padding: theme.spacing(2, 0),
    opacity: 1,
    '&:last-child': {
      '& $wrapper': {
        borderRight: 'none',
      },
    },
  },
  wrapper: {
    /* borderRight: `${theme.spacing(0.1)}px solid #8c8c8c`, */
    display: 'inline-block',
    padding: theme.spacing(0, 1.5),
  },
  selected: {
    fontWeight: 'bold',
  },
}))

const StyledTab: FunctionComponent<StyledTabProps> = (props) => {
  const { classes: styles, ...rest } = props

  const classes = {
    ...useTabStyles(),
    ...styles,
  }

  return <Tab disableRipple {...rest} classes={classes} />
}

export { StyledTabs, StyledTab }
