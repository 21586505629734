import React from 'react'
import { useQueryWithStore } from 'ra-core'
import { makeStyles } from '@material-ui/core'
import { point } from './actions'
import Loading from '../../../../shared/components/Loading'
import ErrorComponent from '../../../../shared/components/ErrorComponent'
import Chart, { ChartPoint } from '../../../../shared/components/Chart'

const useStyles = makeStyles((theme) => ({
  loading: {
    margin: theme.spacing(4, 0),
  },
  chartSection: {
    display: 'flex',
    // this is paired with the child's padding
    // to produce space between elements
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
  },
}))

interface Props {
  id: string
}

const formatStatsPointForChart = (p: point): ChartPoint => {
  const [x, y] = p
  if (x === null) {
    throw new Error('data point x can not be null')
  }
  return { x, y }
}
const formatStatsForChart = (data: point[]): ChartPoint[] =>
  data.map(formatStatsPointForChart)

const toMegabytes = (data: ChartPoint[]): ChartPoint[] =>
  data.map(
    (p: ChartPoint): ChartPoint => {
      const { x: time } = p
      let { y: value } = p
      if (value !== null) {
        value /= 1000000 // convert to megabytes
      }
      return { x: time, y: value }
    }
  )

const Charts: React.FunctionComponent<Props> = (props): React.ReactElement => {
  const { id } = props
  const classes = useStyles()
  const stats = useQueryWithStore({
    type: 'getOne',
    resource: 'stats',
    payload: { id },
  })

  if (stats.loading) {
    return <Loading className={classes.loading} />
  }

  if (stats.error) {
    return <ErrorComponent>{stats.error.message}</ErrorComponent>
  }

  return (
    <div className={classes.chartSection}>
      <Chart
        data={formatStatsForChart(stats.data.data.cpu)}
        title="CPU (Cores)"
        fillColor="#ff9494"
      />
      <Chart
        data={toMegabytes(formatStatsForChart(stats.data.data.mem))}
        title="Memory (MB)"
        fillColor="#bcec8e"
      />
    </div>
  )
}

export default Charts
