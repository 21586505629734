import * as activities from './actions'
import { RollbackAppParams } from './dataProviderParams'
import { isGetListByID } from '../../../shared/dataProvider/typeCheckers'
import { Method, Resource, Params } from '../../../shared/dataProvider/types'

const isRollbackApp = (
  method: Method,
  resource: Resource,
  params: Params
): params is RollbackAppParams =>
  method === 'ROLLBACK' && resource === 'activities'

const provider = (
  method: Method,
  resource: Resource,
  params: Params
): Promise<unknown> => {
  if (isRollbackApp(method, resource, params)) {
    return activities.rollback(params.appId, params.version)
  }
  if (isGetListByID(method, resource, params)) {
    return activities.list(params.id)
  }
  throw new Error(`${method} ${resource} not implemented yet`)
}

export default provider
