import React, { FunctionComponent } from 'react'
import { withRouter } from 'react-router'
import compose from 'recompose/compose'
import { useRedirect } from 'ra-core'
import { makeStyles } from '@material-ui/core'
import { StyledTab, StyledTabs } from './Tabs'
import PageRoot from './PageRoot'
import NoData from './NoData'
import { App } from '../../modules/Apps/Dashboard/actions'
import { User } from '../../modules/Account/actions'

const useStyles = makeStyles({
  contentWrapper: {
    height: '100%',
  },
})

interface Props {
  profile: User
  app: App
  tabs: {
    path: string
    label: string
    element: (
      profile: User,
      app: App,
      classes?: Record<string, string>
    ) => JSX.Element
  }[]
  classes?: Record<string, string>
}

interface EnhancedProps {
  match: {
    params: {
      tab: string
    }
  }
}

const ContentLayout: FunctionComponent<Props & EnhancedProps> = (props) => {
  const { profile, app, tabs, match, classes: styles } = props

  const classes = {
    ...useStyles(),
    ...styles,
  }

  const redirect = useRedirect()

  // refactor tab stuff out since it is shared with ProfileShow
  // this signature comes from mui

  const handleTabChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    value: number
  ) => {
    const tabName = tabs[value].path
    redirect(`/apps/${app.id}/${tabName}`)
  }

  const selectedTabIndex = () => {
    const index = tabs.findIndex((tab) => {
      return tab.path === match.params.tab
    })

    if (index === -1) {
      // if tab is not found, or is empty
      if (app.replicas > 0) {
        // if there are replicas, go to the Dashboard tab
        return 1
      }

      // otheriwse, go to the Setup tab
      return 0
    }

    return index
  }

  const selectedTab = () => {
    const index = selectedTabIndex()
    return tabs[index]
  }

  if (!app) {
    // it's possible that even after loading, app is not found
    return (
      <NoData message="Oops, no record found. Please contact help@gigalixir.com" />
    )
  }

  return (
    <div className={classes.contentWrapper}>
      <StyledTabs value={selectedTabIndex()} onChange={handleTabChange}>
        {tabs.map((x) => (
          <StyledTab key={x.label} label={x.label} />
        ))}
      </StyledTabs>
      <PageRoot>{selectedTab().element(profile, app, {})}</PageRoot>
    </div>
  )
}

const EnhancedContentLayout = compose<Props & EnhancedProps, Props>(withRouter)(
  ContentLayout
)

export default EnhancedContentLayout
