import React, { FunctionComponent } from 'react'
import { Grid, makeStyles } from '@material-ui/core'

interface Props {
  leftSide: string | number
  rightSide: string | number
}

const useStyles = makeStyles({
  container: {
    margin: '0.66em 0',
  },
  leftSide: {
    color: 'rgba(0,0,0,0.5)',
  },
})

const Field: FunctionComponent<Props> = ({ leftSide, rightSide }) => {
  const classes = useStyles()
  if (
    typeof rightSide === 'undefined' ||
    rightSide === null ||
    (typeof rightSide === 'string' && rightSide.trim() === '')
  )
    return null
  return (
    <Grid container className={classes.container}>
      <Grid item xs={2} className={classes.leftSide}>
        {leftSide}
      </Grid>
      <Grid item xs={9}>
        {rightSide}
      </Grid>
    </Grid>
  )
}

export default Field
