import { Method, Resource, Params } from './types'
import { GetListParams, GetListParamsByID, GetOneParams } from './interfaces'

export const isGetList = (
  method: Method,
  resource: Resource,
  params: Params
): params is GetListParams | GetListParamsByID => method === 'GET_LIST'

export const isGetListByID = (
  method: Method,
  resource: Resource,
  params: Params
): params is GetListParamsByID => method === 'GET_LIST_BY_ID'

export const isGetOne = (
  method: Method,
  resource: Resource,
  params: Params
): params is GetOneParams => method === 'GET_ONE'
