import { Method, Resource, Params } from './types'
import authProvider from '../../modules/Auth/dataProvider'
import accountProvider from '../../modules/Account/dataProvider'
import invoicesProvider from '../../modules/Account/Invoices/dataProvider'
import paymentMethodsProvider from '../../modules/Account/PaymentMethod/dataProvider'
import apiKeysProvider from '../../modules/Account/ApiKey/dataProvider'
import sshKeysProvider from '../../modules/Account/SshKeys/dataProvider'
import usageProvider from '../../modules/Account/Usage/dataProvider'
import appsProvider from '../../modules/Apps/Dashboard/dataProvider'
import replicasProvider from '../../modules/Apps/Dashboard/Replicas/dataProvider'
import chartsProvider from '../../modules/Apps/Dashboard/Charts/dataProvider'
import activitiesProvider from '../../modules/Apps/Activity/dataProvider'
import databasesProvider from '../../modules/Apps/Databases/dataProvider'
import configurationsProvider from '../../modules/Apps/Configuration/dataProvider'
import permissionsProvider from '../../modules/Apps/Permissions/dataProvider'
import canariesProvider from '../../modules/Apps/Canary/dataProvider'
import domainsProvider from '../../modules/Apps/Domains/dataProvider'
import logDrainsProvider from '../../modules/Apps/LogDrains/dataProvider'

const dataProvider = <T extends Method>(
  method: T,
  resource: Resource,
  params: Params
): Promise<unknown> => {
  switch (resource) {
    case 'confirmation':
    case 'users':
    case 'password':
    case 'account':
    case 'email':
      return authProvider(method, resource, params)
    case 'profile':
      return accountProvider(method, resource, params)
    case 'payment_methods':
      return paymentMethodsProvider(method, resource, params)
    case 'invoices':
      return invoicesProvider(method, resource, params)
    case 'api_keys':
      return apiKeysProvider(method, resource, params)
    case 'ssh_keys':
      return sshKeysProvider(method, resource, params)
    case 'usage':
      return usageProvider(method, resource, params)
    case 'apps':
    case 'stack':
      return appsProvider(method, resource, params)
    case 'app_status':
      return replicasProvider(method, resource, params)
    case 'stats':
      return chartsProvider(method, resource, params)
    case 'activities':
      return activitiesProvider(method, resource, params)
    case 'databases':
      return databasesProvider(method, resource, params)
    case 'configurations':
      return configurationsProvider(method, resource, params)
    case 'permissions':
      return permissionsProvider(method, resource, params)
    case 'canaries':
      return canariesProvider(method, resource, params)
    case 'domains':
      return domainsProvider(method, resource, params)
    case 'log_drains':
      return logDrainsProvider(method, resource, params)
    default:
      throw new Error(`${method} ${resource} not implemented yet`)
  }
}

export default dataProvider
