import React, { FunctionComponent } from 'react'
import { useMutation, useRedirect } from 'ra-core'
import { FORM_ERROR } from 'final-form'
import { extractError } from '../../../shared/errorExtractors'
import InputConfirmationDialog from '../../../shared/components/InputConfirmationDialog'
import useMaybeNotify from '../../../shared/hooks/useMaybeNotify'

interface Props {
  onSuccess: () => void
  onCancel: () => void
  appId: string
}

const DeleteDialog: FunctionComponent<Props> = (props) => {
  const { onSuccess, onCancel, appId } = props

  const [mutate] = useMutation()
  const notify = useMaybeNotify()
  const redirect = useRedirect()

  const onDestroy = (
    value: string,
    resolve: <T>(value?: T | PromiseLike<T>) => void
  ): void => {
    mutate(
      {
        type: 'delete',
        resource: 'apps',
        payload: { id: appId },
      },
      {
        onSuccess: () => {
          notify('App destroyed')
          onSuccess()
          redirect('/apps')
          resolve()
        },
        onFailure: ({ body: { errors } }) => {
          resolve<Record<string, string>>({
            [FORM_ERROR]: extractError(errors, ['', 'app_id', 'deleted_at']),
          })
        },
      }
    )
  }

  return (
    <InputConfirmationDialog
      onCancel={onCancel}
      title="Destroy App"
      warning="Destroying an app can not be undone"
      fieldLabel="Confirm App ID"
      submitLabel="Destroy"
      onSubmit={onDestroy}
      initialValue={appId}
      validationMessage="Incorrect app id"
    />
  )
}

export default DeleteDialog
