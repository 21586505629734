import * as api from '../../../shared/api'

export interface Domain {
  domain: string
  cname: string
}

export const list = (appId: string): Promise<{ data: Domain[] }> => {
  return api
    .get<{ data: Domain[] }>(`/frontend/api/apps/${appId}/domains`)
    .then((response): { data: Domain[] } => {
      const domains = response.data.data
      return { data: domains }
    })
}

export const create = (
  appId: string,
  fqdn: string
): Promise<{ data: { cname: string } }> => {
  return api
    .post<{ data: { cname: string } }>(`/frontend/api/apps/${appId}/domains`, {
      fqdn,
    })
    .then((response): { data: { cname: string } } => {
      const { data } = response.data
      return { data }
    })
}

export const del = (
  appId: string,
  fqdn: string
): Promise<{ data: Record<string, unknown> }> => {
  return api
    .del<{ data: Record<string, unknown> }>(
      `/frontend/api/apps/${appId}/domains`,
      { fqdn }
    )
    .then(() => ({
      data: {},
    }))
}
