import React, { FunctionComponent } from 'react'
import { withRouter } from 'react-router'
import compose from 'recompose/compose'
import { useRedirect } from 'ra-core'
import { Badge, makeStyles } from '@material-ui/core'
import Info from './Info'
import Backups from './Backups'
import { FreeDatabase, StandardDatabase } from '../../actions'
import { StyledTabs, StyledTab } from '../../../../../shared/components/Tabs'
import ReadReplicas from './ReadReplicas'

interface Props {
  appId: string
  database: StandardDatabase | FreeDatabase
}

interface EnhancedProps {
  match: {
    params: {
      subtab: string
    }
  }
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 10,
  },
  tabContent: {
    display: 'flex',
    alignItems: 'center',
    gap: `${theme.spacing(2)}px`,
  },
}))

const Tabs: FunctionComponent<Props & EnhancedProps> = (props) => {
  const { appId, database, match } = props
  const classes = useStyles()

  const redirect = useRedirect()

  const tabs = [
    {
      path: 'info',
      label: 'Info',
      element: () => {
        return <Info database={database} />
      },
    },
    {
      path: 'read-replicas',
      label: ({ readReplicas }: StandardDatabase) => (
        <div className={classes.tabContent}>
          <span>Read Replicas</span>
          {readReplicas > 0 && (
            <Badge badgeContent={readReplicas} color="primary" />
          )}
        </div>
      ),
      element: () => {
        if (database.tier === 'FREE') return null
        return (
          <ReadReplicas
            appId={appId}
            databaseId={database.id}
            databaseSize={database.size}
          />
        )
      },
    },
    {
      path: 'backups',
      label: 'Backups',
      element: () => {
        return <Backups appId={appId} id={database.id} />
      },
    },
  ]

  const selectedTabIndex = () => {
    const index = tabs.findIndex((tab) => {
      return tab.path === match.params.subtab
    })

    if (index === -1) {
      return 0
    }

    return index
  }

  const handleTabChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    value: number
  ) => {
    const tabName = tabs[value].path
    redirect(`/apps/${appId}/databases/${tabName}`)
  }

  const selectedTab = () => {
    const index = selectedTabIndex()
    return tabs[index]
  }

  return (
    <div className={classes.container}>
      {database.tier === 'STANDARD' ? (
        <>
          <StyledTabs value={selectedTabIndex()} onChange={handleTabChange}>
            {tabs.map((tab) => (
              <StyledTab
                key={tab.path}
                label={
                  typeof tab.label === 'function'
                    ? tab.label(database)
                    : tab.label
                }
              />
            ))}
          </StyledTabs>
          {selectedTab().element()}
        </>
      ) : (
        tabs[0].element()
      )}
    </div>
  )
}

const EnhancedTabs = compose<Props & EnhancedProps, Props>(withRouter)(Tabs)

export default EnhancedTabs
