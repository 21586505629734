import React, { FunctionComponent } from 'react'
import { useQueryWithStore } from 'ra-core'
import { Grid, makeStyles } from '@material-ui/core'
import Database from './Database'
import { DatabasesArray } from './actions'
import CreateDialog from './Database/CreateDialog'
import Loading from '../../../shared/components/Loading'
import ErrorComponent from '../../../shared/components/ErrorComponent'
import NoData from '../../../shared/components/NoData'
import DialogButton, {
  CloseFunction,
} from '../../../shared/components/DialogButton'

interface Props {
  id: string
}

const useStyles = makeStyles((theme) => ({
  databases: {
    height: `calc(100% - ${theme.spacing(5)}px)`,
  },
  database: {
    marginTop: theme.spacing(2),
  },
}))

const Databases: FunctionComponent<Props> = ({ id }) => {
  const {
    data: databases,
    loading,
    error,
  }: {
    data?: DatabasesArray
    loading: boolean
    error?: Error
  } = useQueryWithStore({
    type: 'GET_LIST_BY_ID',
    resource: 'databases',
    payload: { id },
  })

  const classes = useStyles()

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <ErrorComponent>{error.message}</ErrorComponent>
  }

  const createButton = (
    <DialogButton label="Create">
      {(close: CloseFunction) => {
        return (
          <CreateDialog
            onSuccess={() => close()}
            onCancel={() => close()}
            appId={id}
          />
        )
      }}
    </DialogButton>
  )

  if (!databases || databases.length === 0) {
    return (
      <NoData
        note={
          <>
            <span>If your app needs a database, create one here.</span>
            <a
              href="https://gigalixir.readthedocs.io/en/latest/database.html#database-management"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More
            </a>
          </>
        }
        message="No databases yet"
      >
        {createButton}
      </NoData>
    )
  }

  return (
    <>
      {createButton}
      <div className={classes.databases}>
        {databases.map((database) => {
          return (
            <div key={database.id} className={classes.database}>
              <Grid container>
                <Grid item xs={12}>
                  <Database appId={id} database={database} />
                </Grid>
              </Grid>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default Databases
