import qs from 'query-string'
import React, { FunctionComponent } from 'react'
import { Notification } from 'react-admin'
import Layout from '../Layout'
import ResendConfirmationForm from './ResendConfirmationForm'

const ResendConfirmation: FunctionComponent<{
  location: { search: string }
}> = (props) => {
  const { location } = props
  const params = qs.parse(location.search)
  let email
  if (typeof params.email === 'string') {
    email = params.email
  }
  return (
    <Layout>
      <ResendConfirmationForm email={email} />
      <Notification />
    </Layout>
  )
}

export default ResendConfirmation
