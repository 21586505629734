import React, { FunctionComponent } from 'react'
import { useQueryWithStore } from 'ra-core'
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core'
import CreateDialog from './CreateDialog'
import DeleteDialog from './DeleteDialog'
import { SshKey } from './actions'
import Loading from '../../../shared/components/Loading'
import ErrorComponent from '../../../shared/components/ErrorComponent'
import NoData from '../../../shared/components/NoData'
import TableWrapper from '../../../shared/components/TableWrapper'
import DialogButton from '../../../shared/components/DialogButton'

const useStyles = makeStyles((theme) => ({
  sshKey: {
    wordBreak: 'break-word',
  },
}))

const SshKeys: FunctionComponent = () => {
  const {
    data: sshKeys,
    loading,
    error,
  }: {
    data?: SshKey[]
    loading: boolean
    error?: Error
  } = useQueryWithStore({
    type: 'getList',
    resource: 'ssh_keys',
    payload: {},
  })

  const classes = useStyles()

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <ErrorComponent>{error.message}</ErrorComponent>
  }

  const sshKeyDialog = (
    <DialogButton label="Add">
      {(close) => (
        <CreateDialog onSuccess={() => close()} onCancel={() => close()} />
      )}
    </DialogButton>
  )

  if (!(sshKeys && sshKeys.length > 0)) {
    return (
      <NoData
        note={
          <>
            <span>
              SSH keys are needed to SSH into, remote console into, or connect a
              remote observer to your app.
            </span>
            <a
              href="https://gigalixir.readthedocs.io/en/latest/runtime.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn More
            </a>
          </>
        }
        message="No SSH keys yet"
      >
        {sshKeyDialog}
      </NoData>
    )
  }

  return (
    <>
      {sshKeyDialog}
      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SSH Key</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {sshKeys.map((sshKey) => (
              <TableRow key={sshKey.id}>
                <TableCell className={classes.sshKey}>{sshKey.key}</TableCell>
                <TableCell align="right">
                  <DialogButton label="Delete">
                    {(close) => (
                      <DeleteDialog
                        onSuccess={() => close()}
                        onCancel={() => close()}
                        sshKey={sshKey}
                      />
                    )}
                  </DialogButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
    </>
  )
}

export default SshKeys
