import { createMuiTheme } from '@material-ui/core'

const spacing = 10

const theme = createMuiTheme({
  spacing,
  palette: {
    background: {
      default: '#fff',
      paper: '#f2f2f2',
    },
  },
  overrides: {
    MuiDrawer: {
      paper: {
        marginTop: '0 !important',
        maxWidth: spacing * 25.5,
        minWidth: spacing * 8.5,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 4,
        fontWeight: 'bold',
      },
    },
    MuiRadio: {
      root: {
        padding: `0 ${spacing}px`,
      },
    },
    MuiTable: {
      root: {
        padding: 0,
        backgroundColor: '#fff',
      },
    },
    MuiTableRow: {
      root: {
        height: spacing * 5,
      },
    },
    MuiTableCell: {
      root: {
        padding: spacing,
      },
      head: {
        color: '#666',
        fontWeight: 600,
      },
      body: {
        color: '#888',
      },
    },
    MuiDialog: {
      paper: {
        background: '#fff',
        minWidth: spacing * 40,
        padding: spacing * 3,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: 0,
        '& h2': {
          fontSize: '1.15rem',
          lineHeight: 'unset',
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: `${spacing}px 0`,
        overflowX: 'hidden',
      },
    },
    MuiDialogActions: {
      root: {
        padding: 0,
        paddingTop: spacing,
      },
      spacing: {
        '&>:not(:first-child)': {
          marginLeft: spacing * 2,
        },
      },
    },
    MuiInputAdornment: {
      positionStart: {
        marginRight: spacing / 2,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
        backgroundColor: '#f2f2f2',
        '&:hover $notchedOutline': {
          borderColor: '#bbb',
        },
      },
      adornedStart: {
        paddingLeft: spacing,
      },
      input: {
        padding: `${spacing * 0.55}px ${spacing}px`,
        '&+fieldset': {
          borderColor: 'transparent',
        },
      },
      inputAdornedStart: {
        paddingLeft: spacing / 2,
      },
    },
  },
})

export default theme
