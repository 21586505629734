import React, { FunctionComponent } from 'react'
import { makeStyles, IconButton, SvgIcon } from '@material-ui/core'
import ResetIcon from '@material-ui/icons/Refresh'
import ResetDialog from './ResetDialog'
import Field from '../../../shared/components/Field'
import Fields from '../../../shared/components/Fields'
import NoData from '../../../shared/components/NoData'
import DialogButton from '../../../shared/components/DialogButton'

interface Props {
  apiKey: string
}

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  card: {
    maxWidth: 240,
  },
  resetIcon: {
    height: 16,
    width: 16,
  },
}))

const ApiKey: FunctionComponent<Props> = (props) => {
  const { apiKey } = props
  const classes = useStyles()

  if (!apiKey) {
    return <NoData message="No API Key Found." />
  }

  return (
    <Fields>
      <Field
        className={classes.card}
        label={
          <div className={classes.content}>
            <span>API Key</span>
            <DialogButton
              label="Reset"
              component={
                <IconButton size="small">
                  <SvgIcon className={classes.resetIcon} color="primary">
                    <ResetIcon />
                  </SvgIcon>
                </IconButton>
              }
            >
              {(close) => (
                <ResetDialog
                  onSuccess={() => close()}
                  onCancel={() => close()}
                />
              )}
            </DialogButton>
          </div>
        }
        isCard
      >
        {apiKey}
      </Field>
    </Fields>
  )
}

export default ApiKey
