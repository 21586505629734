import moment from 'moment'
import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

interface Props {
  classes?: Record<string, string>
  limitedAt: Date
}

const useStyles = makeStyles({
  alert: {
    marginTop: 20,
  },
  link: {
    color: '#3f51b5',
    textDecoration: 'none',
  },
})

const LimitedAt: FunctionComponent<Props> = ({
  classes: styles,
  limitedAt,
}) => {
  const classes = {
    ...useStyles(),
    ...styles,
  }

  if (typeof limitedAt === 'undefined' || limitedAt === null) return null
  const limitedAtMoment = moment(limitedAt)

  return (
    <Alert className={classes.alert} severity="error">
      <span>
        {`Warning: This database was limited at ${limitedAtMoment.format(
          'h:mma'
        )} on ${limitedAtMoment.format(
          'MM/DD/YYYY'
        )} because it exceeded 10,000 rows. It is now read-only. Please`}{' '}
        <Link to="/account" className={classes.link}>
          upgrade
        </Link>{' '}
        to a STANDARD database.
      </span>
    </Alert>
  )
}

export default LimitedAt
