import React, { FunctionComponent } from 'react'
import { Elements, StripeProvider } from 'react-stripe-elements'
import UpdateForm from './UpdateForm'

const stripeKey = process.env.REACT_APP_STRIPE_API_KEY || 'missing'

interface Props {
  onSuccess: () => void
  onCancel: () => void
}

const UpdateDialog: FunctionComponent<Props> = (props) => {
  const { onSuccess, onCancel } = props

  return (
    <StripeProvider apiKey={stripeKey}>
      <Elements>
        <UpdateForm onSuccess={onSuccess} onCancel={onCancel} />
      </Elements>
    </StripeProvider>
  )
}

export default UpdateDialog
