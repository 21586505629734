import React, { FunctionComponent } from 'react'
import { useMutation, useRefresh } from 'ra-core'
import { Button, CircularProgress, makeStyles, Theme } from '@material-ui/core'
import { extractError } from '../../../shared/errorExtractors'
import useMaybeNotify from '../../../shared/hooks/useMaybeNotify'

const useStyles = makeStyles(
  (theme: Theme) => ({
    // duplicated in SubmitButton and LoginForm? how to not duplicate?
    icon: {
      color: 'white',
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'CollaboratorDeleteButton' }
)

interface Props {
  id: string
  email: string
}

const DeleteButton: FunctionComponent<Props> = (props) => {
  const refresh = useRefresh()
  const { id, email } = props
  const classes = useStyles(props)
  const notify = useMaybeNotify()
  const [mutate, { loading }] = useMutation(
    {
      type: 'delete',
      resource: 'permissions',
      payload: { id, email },
    },
    {
      onSuccess: () => {
        notify('Collaborator removed.')
        refresh()
      },
      onFailure: ({ body: { errors } }) => {
        notify(extractError(errors, ''), 'error')
      },
    }
  )
  return (
    <Button color="primary" variant="contained" size="small" onClick={mutate}>
      {loading && (
        <CircularProgress className={classes.icon} size={18} thickness={2} />
      )}
      Remove
    </Button>
  )
}

export default DeleteButton
