import classnames from 'classnames'
import React, { FunctionComponent } from 'react'
import { useMutation, useRefresh } from 'ra-core'
import { FORM_ERROR } from 'final-form'
import { Field, Form } from 'react-final-form'
import {
  makeStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
} from '@material-ui/core'
import SizeSlider, { sliderMarks as marks, sliderMarks } from '../../SizeSlider'
import { extractError } from '../../../../../../shared/errorExtractors'
import ErrorComponent from '../../../../../../shared/components/ErrorComponent'
import CancelButton from '../../../../../../shared/components/CancelButton'
import SubmitButton from '../../../../../../shared/components/SubmitButton'
import useMaybeNotify from '../../../../../../shared/hooks/useMaybeNotify'

interface Props {
  appId: string
  databaseId: string
  databaseSize: number
  onSuccess: () => void
  onCancel: () => void
}
interface FormData {
  size: number
}
const useStyles = makeStyles((theme) => ({
  form: {
    minWidth: 600,
  },
  alert: {
    marginTop: theme.spacing(2),
  },
  sizeContainer: {
    display: 'block',
  },
  size: {
    marginRight: theme.spacing(3),
  },
  // duplicated in Field.tsx
  label: {
    color: 'rgba(0,0,0,0.5)',
    padding: theme.spacing(2, 0, 1),
  },
  sizeLabel: {
    padding: theme.spacing(0, 0, 1),
  },
}))

const CreateDialog: FunctionComponent<Props> = (props) => {
  const { appId, databaseId, databaseSize, onCancel, onSuccess } = props
  const classes = useStyles(props)
  const refresh = useRefresh()
  const notify = useMaybeNotify()
  const [mutate] = useMutation()

  const submit = ({ size }: FormData) => {
    return new Promise((resolve) => {
      mutate(
        {
          type: 'CREATE_READ_REPLICA',
          resource: 'databases',
          payload: {
            data: { size: marks[size].label, appId, databaseId },
          },
        },
        {
          onSuccess: () => {
            notify('Read replica created')
            onSuccess()
            resolve()
            refresh()
          },
          onFailure: ({ body: { errors } }) => {
            resolve({
              [FORM_ERROR]: extractError(errors, [
                '',
                'app_name',
                'app_id',
                'database_id',
              ]),
            })
          },
        }
      )
    })
  }

  return (
    <Form
      onSubmit={submit}
      render={(formRenderProps) => {
        const {
          handleSubmit,
          submitError,
          submitting,
          hasValidationErrors,
          hasSubmitErrors,
        } = formRenderProps
        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            <DialogTitle id="form-dialog-title">
              Create Read Replica
            </DialogTitle>
            {submitError && (
              <DialogContent>
                <FormHelperText error>
                  <ErrorComponent>{submitError}</ErrorComponent>
                </FormHelperText>
              </DialogContent>
            )}

            <DialogContent>
              <div className={classes.size}>
                <div className={classnames(classes.label, classes.sizeLabel)}>
                  Size (GB of Memory)
                </div>
                <Field
                  name="size"
                  initialValue={
                    sliderMarks.find((mark) => mark.label === databaseSize)
                      ?.value
                  }
                  component={SizeSlider}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <CancelButton onClick={onCancel} />
              <SubmitButton
                {...{
                  hasValidationErrors,
                  hasSubmitErrors,
                  modifiedSinceLastSubmit: true,
                  pristine: false,
                  submitting,
                }}
                label="Create"
                variant="contained"
                size="small"
              />
            </DialogActions>
          </form>
        )
      }}
    />
  )
}

export default CreateDialog
