import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { useQueryWithStore } from 'ra-core'
import { makeStyles, IconButton, SvgIcon } from '@material-ui/core'
import UpdateDialog from './UpdateDialog'
import Loading from '../../../shared/components/Loading'
import ErrorComponent from '../../../shared/components/ErrorComponent'
import NoData from '../../../shared/components/NoData'
import Fields from '../../../shared/components/Fields'
import Field from '../../../shared/components/Field'
import DialogButton from '../../../shared/components/DialogButton'
import { ReactComponent as EditIcon } from '../../../icons/edit.svg'

const useStyles = makeStyles({
  brand: {
    maxWidth: 'unset',
    flex: 'unset',
    margin: 0,
  },
  brandLabel: {},
  brandIconWrapper: {
    visibility: 'hidden',
  },
  expires: {},
  expiresLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  editIcon: {
    height: 16,
    width: 16,
  },
  link: {
    color: '#3f51b5',
    textDecoration: 'none',
  },
})

const PaymentMethod: FunctionComponent = () => {
  const classes = useStyles()

  const { data, loading, error } = useQueryWithStore({
    type: 'getOne',
    resource: 'payment_methods',
    payload: {
      id: 'ignored',
    },
  })

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <ErrorComponent>{error.message}</ErrorComponent>
  }

  if (!data?.brand) {
    return (
      <NoData
        message={
          <span>
            No payment method is required until you decide to{' '}
            <Link to="/account" className={classes.link}>
              upgrade
            </Link>{' '}
            to the STANDARD tier.
          </span>
        }
      />
    )
  }

  return (
    <Fields>
      <Field
        className={classes.brand}
        label={
          <div className={classes.brandLabel}>
            <span>{data.brand.toUpperCase()}</span>
            <IconButton className={classes.brandIconWrapper} size="small">
              <SvgIcon className={classes.editIcon} color="primary">
                <EditIcon />
              </SvgIcon>
            </IconButton>
          </div>
        }
        isCard
      >
        {`****${data.last4}`}
      </Field>
      <Field
        className={classes.expires}
        label={
          <div className={classes.expiresLabel}>
            <span>Expires</span>
            <DialogButton
              label="Edit"
              component={
                <IconButton size="small">
                  <SvgIcon className={classes.editIcon} color="primary">
                    <EditIcon />
                  </SvgIcon>
                </IconButton>
              }
            >
              {(close) => (
                <UpdateDialog
                  onSuccess={() => close()}
                  onCancel={() => close()}
                />
              )}
            </DialogButton>
          </div>
        }
        isCard
      >
        {`${data.expMonth}/${data.expYear}`}
      </Field>
    </Fields>
  )
}

export default PaymentMethod
