import * as invoices from './actions'
import { isGetList } from '../../../shared/dataProvider/typeCheckers'
import { Method, Resource, Params } from '../../../shared/dataProvider/types'

const provider = (
  method: Method,
  resource: Resource,
  params: Params
): Promise<unknown> => {
  if (isGetList(method, resource, params)) {
    return invoices.pdfs()
  }
  throw new Error(`${method} ${resource} not implemented yet`)
}

export default provider
