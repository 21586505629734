import classnames from 'classnames'
import React, { FunctionComponent } from 'react'
import { makeStyles, CircularProgress } from '@material-ui/core'

interface Props {
  classes?: Record<string, string>
  className?: string
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      height: '100vh',
      marginTop: '-3em',
    },
  },
  message: {
    textAlign: 'center',
    fontFamily: 'Roboto, sans-serif',
    opacity: 0.5,
    margin: '0 1em',
  },
  icon: {
    width: '9em',
    height: '9em',
  },
}))

const Loading: FunctionComponent<Props> = (props) => {
  const { classes: styles, className } = props

  const classes = {
    ...useStyles(),
    ...styles,
  }

  return (
    <div className={classnames(classes.container, className)}>
      <div className={classes.message}>
        <CircularProgress className={classes.icon} color="primary" />
      </div>
    </div>
  )
}

export default Loading
