import moment from 'moment'
import React, { FunctionComponent } from 'react'
import { useQueryWithStore } from 'ra-core'
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core'

import { Invoice } from './actions'
import Loading from '../../../shared/components/Loading'
import ErrorComponent from '../../../shared/components/ErrorComponent'
import NoData from '../../../shared/components/NoData'
import TableWrapper from '../../../shared/components/TableWrapper'
import formatMoney from '../../../utils/formatters/moneyFormatters'

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    marginTop: 0,
    height: `calc(100% - ${theme.spacing(5)}px)`,
  },
}))

const Invoices: FunctionComponent = () => {
  const classes = useStyles()
  const {
    data: invoices,
    loading,
    error,
  }: { data?: Invoice[]; loading: boolean; error?: Error } = useQueryWithStore({
    type: 'getList',
    resource: 'invoices',
    payload: {},
  })

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <ErrorComponent>{error.message}</ErrorComponent>
  }

  if (invoices?.length === 0) {
    return (
      <NoData
        note="View and download all your past invoices here"
        message="No invoices yet"
      />
    )
  }
  return (
    <TableWrapper className={classes.tableWrapper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>PDF</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices?.map(
            (invoice) =>
              invoice.pdf && (
                <TableRow key={invoice.id}>
                  <TableCell>
                    {`${moment(invoice.periodStart).format(
                      'MM/DD/YYYY'
                    )} - ${moment(invoice.periodEnd).format('MM/DD/YYYY')}`}
                  </TableCell>
                  <TableCell>{formatMoney(invoice.amountCents)}</TableCell>
                  <TableCell>
                    <a href={invoice.pdf}>PDF</a>
                  </TableCell>
                </TableRow>
              )
          )}
        </TableBody>
      </Table>
    </TableWrapper>
  )
}
export default Invoices
