import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

interface Props {
  children: string
}

const ErrorComponent: FunctionComponent<Props> = ({ children }) => {
  return (
    <>
      {children
        // got this regex from this answer: https://stackoverflow.com/a/64561095/2284240
        // link to regex tester: https://regex101.com/r/j8USe2/2
        .split(/\bupgrade\b(?=(?:[^`]*`[^`]*`)*[^`]*$)/gi)
        .map((msg: string, index: number) => {
          if (index === 0) {
            // eslint-disable-next-line react/no-array-index-key
            return <span key={`${msg}-${index}`}>{msg}</span>
          }
          return (
            // eslint-disable-next-line react/no-array-index-key
            <span key={`${msg}-${index}`}>
              <Link to="/account">upgrade</Link>
              {msg}
            </span>
          )
        })}
    </>
  )
}

export default ErrorComponent
