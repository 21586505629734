import qs from 'query-string'
import React, { FunctionComponent } from 'react'
import { Notification } from 'react-admin'
import Layout from '../Layout'
import SetPasswordForm from './SetPasswordForm'

interface Props {
  location: { search: string }
}

const SetPassword: FunctionComponent<Props> = (props) => {
  const {
    location: { search },
  } = props
  const params = qs.parse(search)

  let token
  if (typeof params.token === 'string') {
    token = params.token
  }

  return (
    <Layout>
      <SetPasswordForm token={token} />
      <Notification />
    </Layout>
  )
}

export default SetPassword
