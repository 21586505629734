import React, { FunctionComponent } from 'react'
import { useQueryWithStore } from 'ra-core'
import Setup from './Setup'
import Dashboard from './Dashboard'
import { App } from './Dashboard/actions'
import Databases from './Databases'
import Activities from './Activity'
import Permissions from './Permissions'
import Canaries from './Canary'
import Configurations from './Configuration'
import Domains from './Domains'
import LogDrains from './LogDrains'
import LogStream from './LogStream'
import { User } from '../Account/actions'
import Loading from '../../shared/components/Loading'
import ErrorComponent from '../../shared/components/ErrorComponent'
import Page from '../../shared/components/Page'
import ContentLayout from '../../shared/components/ContentLayout'

interface Props {
  match: {
    params: {
      id: string
    }
  }
}

const tabs = [
  {
    path: 'setup',
    label: 'Setup',
    element: (profile: User, app: App) => {
      return (
        <Setup appId={app.id} email={profile.email} apiKey={profile.apiKey} />
      )
    },
  },
  {
    path: 'dashboard',
    label: 'Dashboard',
    element: (profile: User, app: App) => {
      return <Dashboard app={app} />
    },
  },
  {
    path: 'databases',
    label: 'Databases',
    element: (profile: User, app: App) => {
      return <Databases id={app.id} />
    },
  },
  {
    path: 'activity',
    label: 'Activity',
    element: (profile: User, app: App) => {
      return <Activities appId={app.id} />
    },
  },
  {
    path: 'access',
    label: 'Permissions',
    element: (profile: User, app: App) => {
      return <Permissions id={app.id} />
    },
  },
  {
    path: 'canary',
    label: 'Canary',
    element: (profile: User, app: App) => {
      return <Canaries appId={app.id} />
    },
  },
  {
    path: 'configuration',
    label: 'Configuration',
    element: (profile: User, app: App) => {
      return <Configurations id={app.id} />
    },
  },
  {
    path: 'domains',
    label: 'Domains',
    element: (profile: User, app: App) => {
      return <Domains appId={app.id} />
    },
  },
  {
    path: 'logs',
    label: 'Logs',
    element: (profile: User, app: App) => {
      return <LogStream appId={app.id} />
    },
  },
  {
    path: 'drains',
    label: 'Log Drains',
    element: (profile: User, app: App) => {
      return <LogDrains appId={app.id} />
    },
  },
]

const AppShow: FunctionComponent<Props> = ({
  match: {
    params: { id },
  },
}) => {
  const profile = useQueryWithStore({
    type: 'getOne',
    resource: 'profile',
    payload: {
      id: 'ignored',
    },
  })

  const app = useQueryWithStore({
    type: 'getOne',
    resource: 'apps',
    payload: {
      id,
    },
  })

  if (profile.loading || app.loading) {
    return <Loading />
  }

  if (profile.error) {
    return <ErrorComponent>{profile.error}</ErrorComponent>
  }

  if (app.error) {
    return <ErrorComponent>{app.error.message}</ErrorComponent>
  }

  return (
    <Page title={id}>
      <ContentLayout app={app.data} profile={profile.data} tabs={tabs} />
    </Page>
  )
}

export default AppShow
