import React, { FunctionComponent } from 'react'
import { useMutation, useRefresh } from 'ra-core'
import { FORM_ERROR } from 'final-form'
import { Field, Form } from 'react-final-form'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
} from '@material-ui/core'
import {
  extractError,
  extractErrorValue,
} from '../../../shared/errorExtractors'
import { renderTextField } from '../../../shared/components/formFields'
import ErrorComponent from '../../../shared/components/ErrorComponent'
import CancelButton from '../../../shared/components/CancelButton'
import SubmitButton from '../../../shared/components/SubmitButton'
import useMaybeNotify from '../../../shared/hooks/useMaybeNotify'
import { composeValidators, required } from '../../../utils/validators'

const validatePassword = composeValidators([required()])

const renderPasswordField = renderTextField({ type: 'password' })

interface Props {
  onSuccess: () => void
  onCancel: () => void
}

interface FormData {
  currentPassword: string
}

const ResetDialog: FunctionComponent<Props> = (props) => {
  const { onSuccess, onCancel } = props
  const [mutate] = useMutation()
  const notify = useMaybeNotify()
  const refresh = useRefresh()

  const submit = (values: FormData) => {
    return new Promise((resolve) => {
      mutate(
        {
          type: 'update',
          resource: 'api_keys',
          payload: { id: null, data: values, previousData: {} },
        },
        {
          onSuccess: () => {
            notify('API Key reset')
            onSuccess()
            resolve()
            refresh()
          },
          onFailure: ({ body: { errors } }) => {
            resolve({
              [FORM_ERROR]: extractError(errors, ''),
              currentPassword: extractErrorValue(errors, 'current_password'),
            })
          },
        }
      )
    })
  }
  return (
    <Form
      onSubmit={submit}
      render={({
        handleSubmit,
        submitError,
        hasSubmitErrors,
        hasValidationErrors,
        modifiedSinceLastSubmit,
        pristine,
        submitting,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">Reset API Key</DialogTitle>
            {submitError && (
              <DialogContent>
                <FormHelperText error>
                  <ErrorComponent>{submitError}</ErrorComponent>
                </FormHelperText>
              </DialogContent>
            )}
            <DialogContent>
              <Field
                validate={validatePassword}
                component={renderPasswordField}
                name="currentPassword"
                label="Current Password"
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <CancelButton onClick={onCancel} />
              <SubmitButton
                {...{
                  hasValidationErrors,
                  hasSubmitErrors,
                  modifiedSinceLastSubmit,
                  pristine,
                  submitting,
                }}
                label="Reset"
                variant="contained"
                size="small"
              />
            </DialogActions>
          </form>
        )
      }}
    />
  )
}

export default ResetDialog
