import * as api from '../../../shared/api'

// eslint-disable-next-line import/prefer-default-export
export const reset = (
  currentPassword: string
): Promise<{ data: { key: string } }> => {
  return api
    .post<{ data: { key: string } }>('/frontend/api/api_keys', {
      current_password: currentPassword,
    })
    .then((response): { data: { key: string } } => {
      const key = response.data.data
      return { data: key }
    })
}
