import React, { FunctionComponent } from 'react'
import { Form } from 'react-final-form'
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormHelperText,
  makeStyles,
} from '@material-ui/core'
import ErrorComponent from './ErrorComponent'
import { CloseFunction } from './DialogButton'
import CancelButton from './CancelButton'
import SubmitButton from './SubmitButton'

interface Props {
  title: string
  children: string
  onCancel: CloseFunction
  onConfirm: (resolve: <T>(value?: T | PromiseLike<T>) => void) => void
}

const useStyles = makeStyles((theme) => ({
  text: {
    paddingTop: theme.spacing(2),
  },
}))

const ConfirmationDialog: FunctionComponent<Props> = (props) => {
  const { title, children, onCancel, onConfirm } = props

  const submit = () => {
    return new Promise((resolve) => {
      onConfirm(resolve)
    })
  }

  const classes = useStyles()

  return (
    <Form
      onSubmit={submit}
      render={({ handleSubmit, submitError, submitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            {submitError && (
              <DialogContent>
                <FormHelperText error>
                  <ErrorComponent>{submitError}</ErrorComponent>
                </FormHelperText>
              </DialogContent>
            )}
            <DialogContent>
              <DialogContentText className={classes.text}>
                {children}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <CancelButton onClick={onCancel} />
              <SubmitButton
                hasSubmitErrors={false}
                hasValidationErrors={false}
                modifiedSinceLastSubmit
                pristine={false}
                submitting={submitting}
                label="Confirm"
                variant="contained"
                size="small"
              />
            </DialogActions>
          </form>
        )
      }}
    />
  )
}

export default ConfirmationDialog
