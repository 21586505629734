import React, { FunctionComponent } from 'react'
import { useMutation, useRefresh } from 'ra-core'
import { FORM_ERROR } from 'final-form'
import { extractError } from '../../../../shared/errorExtractors'
import InputConfirmationDialog from '../../../../shared/components/InputConfirmationDialog'
import useMaybeNotify from '../../../../shared/hooks/useMaybeNotify'

interface Props {
  onSuccess: () => void
  onCancel: () => void
  appId: string
  databaseId: string
}

const DeleteDialog: FunctionComponent<Props> = (props) => {
  const { onSuccess, onCancel, appId, databaseId } = props

  const [mutate] = useMutation()
  const notify = useMaybeNotify()
  const refresh = useRefresh()

  const onDelete = (
    value: string,
    resolve: <T>(value?: T | PromiseLike<T>) => void
  ): void => {
    mutate(
      {
        type: 'delete',
        resource: 'databases',
        payload: { appId, id: databaseId },
      },
      {
        onSuccess: () => {
          notify('Database deleted')
          refresh()
          onSuccess()
          resolve()
        },
        onFailure: ({ body: { errors } }) => {
          resolve<Record<string, string>>({
            [FORM_ERROR]: extractError(errors, ['', 'database_id']),
          })
        },
      }
    )
  }

  return (
    <InputConfirmationDialog
      onCancel={onCancel}
      title="Delete Database"
      warning="Deleting a database cannot be undone and will destroy all backups"
      fieldLabel="Confirm Database ID"
      submitLabel="Delete"
      onSubmit={onDelete}
      initialValue={databaseId}
      validationMessage="Incorrect database id"
    />
  )
}

export default DeleteDialog
