import React, { FunctionComponent } from 'react'
import { useMutation, useRefresh } from 'ra-core'
import { FORM_ERROR } from 'final-form'
import { extractError } from '../../../shared/errorExtractors'
import ConfirmationDialog from '../../../shared/components/ConfirmationDialog'
import useMaybeNotify from '../../../shared/hooks/useMaybeNotify'

interface Props {
  onSuccess: () => void
  onCancel: () => void
  appId: string
  version: number
}

const RollbackDialog: FunctionComponent<Props> = (props) => {
  const { onSuccess, onCancel, appId, version } = props

  const [mutate] = useMutation()
  const notify = useMaybeNotify()
  const refresh = useRefresh()

  const onRollback = (
    resolve: <T>(value?: T | PromiseLike<T>) => void
  ): void => {
    mutate(
      {
        type: 'ROLLBACK',
        resource: 'activities',
        payload: { appId, version },
      },
      {
        onSuccess: (response) => {
          notify(response.data)
          onSuccess()
          resolve()
          refresh()
        },
        onFailure: ({ body: { errors } }) => {
          resolve<Record<string, string>>({
            [FORM_ERROR]: extractError(errors, ''),
          })
        },
      }
    )
  }

  return (
    <ConfirmationDialog
      onCancel={onCancel}
      title="Rollback"
      onConfirm={onRollback}
    >
      {`Are you sure want to rollback to version ${version}?`}
    </ConfirmationDialog>
  )
}

export default RollbackDialog
