import { useAuthState } from 'ra-core'
import React, { FunctionComponent, useState } from 'react'
import { Redirect } from 'react-router-dom'
import Layout from '../Layout'
import LoginForm from './LoginForm'
import MfaForm, { Props as MfaFormProps } from './MfaForm'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

const Login: FunctionComponent<Props> = () => {
  const { loaded, authenticated } = useAuthState()
  const [mfaProps, setMfaProps] = useState<MfaFormProps | null>(null)

  if (loaded && authenticated) {
    return <Redirect to="/apps" />
  }

  if (mfaProps == null) {
    return (
      <Layout>
        <LoginForm
          setMfaProps={(email, password) => {
            setMfaProps({ email, password })
          }}
        />
      </Layout>
    )
  }
  return (
    <Layout>
      <MfaForm {...mfaProps} />
    </Layout>
  )
}

export default Login
