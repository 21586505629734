import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'

interface Props {
  classes?: Record<string, string>
}

const useStyles = makeStyles({
  root: {},
  image: {
    width: 130,
    filter:
      'invert(17%) sepia(10%) saturate(987%) hue-rotate(169deg) brightness(92%) contrast(87%)',
  },
})

const Logo: FunctionComponent<Props> = ({ classes: styles }) => {
  const classes = {
    ...useStyles(),
    ...styles,
  }

  return (
    <div className={classes.root}>
      <Link to="/">
        <img
          className={classes.image}
          alt="Gigalixir"
          src="/images/Gigalixir-Logo-Bounded.svg"
        />
      </Link>
    </div>
  )
}

export default Logo
