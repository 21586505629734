import React, { FunctionComponent } from 'react'
import { useQueryWithStore, useRedirect } from 'ra-core'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core'
import { App } from './Dashboard/actions'
import AppCreateDialog from './Dashboard/CreateDialog'
import Loading from '../../shared/components/Loading'
import ErrorComponent from '../../shared/components/ErrorComponent'
import NoData from '../../shared/components/NoData'
import DialogButton from '../../shared/components/DialogButton'
import Page from '../../shared/components/Page'
import TableWrapper from '../../shared/components/TableWrapper'
import Section from '../../shared/components/Section'

const useStyles = makeStyles((theme) => ({
  noDataWrapper: {
    height: `calc(100% - ${theme.spacing(6)}px)`,
  },
  errorWrapper: {
    marginTop: 20,
    height: `calc(100% - ${theme.spacing(2)}px)`,
  },
  container: {
    height: '100%',
  },
  row: {
    cursor: 'pointer',
  },
}))

const createDialog = (
  <DialogButton label="Create">
    {(close) => (
      <AppCreateDialog
        onSuccess={() => {
          close()
        }}
        onCancel={() => {
          close()
        }}
      />
    )}
  </DialogButton>
)

const AppList: FunctionComponent = () => {
  const redirect = useRedirect()

  const classes = useStyles()

  const {
    data: apps,
    loading,
    error,
  }: { data?: App[]; loading: boolean; error?: Error } = useQueryWithStore({
    type: 'getList',
    resource: 'apps',
    payload: {},
  })

  if (error) {
    return (
      <div className={classes.errorWrapper}>
        <ErrorComponent>{error.message}</ErrorComponent>
      </div>
    )
  }

  if (loading) {
    return <Loading />
  }

  if (!apps || apps.length === 0) {
    return (
      <Section className={classes.noDataWrapper}>
        <NoData message="No apps yet">{createDialog}</NoData>
      </Section>
    )
  }

  return (
    <Page title="Apps">
      <div className={classes.container}>
        {createDialog}
        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Replicas</TableCell>
                <TableCell align="right">Size</TableCell>
                <TableCell align="right">Version</TableCell>
                <TableCell align="right">Cloud</TableCell>
                <TableCell align="right">Region</TableCell>
                <TableCell align="right">Stack</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {apps.map((app) => (
                <TableRow
                  hover
                  className={classes.row}
                  key={app.id}
                  onClick={() => {
                    redirect(`/apps/${app.id}`)
                  }}
                >
                  <TableCell component="th" scope="row">
                    {app.id}
                  </TableCell>
                  <TableCell align="right">{app.replicas}</TableCell>
                  <TableCell align="right">{app.size}</TableCell>
                  <TableCell align="right">{app.version}</TableCell>
                  <TableCell align="right">{app.cloud}</TableCell>
                  <TableCell align="right">{app.region}</TableCell>
                  <TableCell align="right">{app.stack}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableWrapper>
      </div>
    </Page>
  )
}

export default AppList
