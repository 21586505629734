import * as users from './actions'
import { isGetOne } from '../../shared/dataProvider/typeCheckers'
import { Method, Resource, Params } from '../../shared/dataProvider/types'

const provider = (
  method: Method,
  resource: Resource,
  params: Params
): Promise<unknown> => {
  if (isGetOne(method, resource, params)) {
    return users.get()
  }
  throw new Error(`${method} ${resource} not implemented yet`)
}

export default provider
