import React, { useState, FunctionComponent } from 'react'
import compose from 'recompose/compose'
import { useMutation, useRefresh } from 'ra-core'
import { FORM_ERROR } from 'final-form'
import { Form } from 'react-final-form'
import {
  makeStyles,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormHelperText,
} from '@material-ui/core'
import {
  CardElement,
  injectStripe,
  ReactStripeElements,
} from 'react-stripe-elements'
import ErrorComponent from '../../../shared/components/ErrorComponent'
import CancelButton from '../../../shared/components/CancelButton'
import SubmitButton from '../../../shared/components/SubmitButton'
import useMaybeNotify from '../../../shared/hooks/useMaybeNotify'

const useStyles = makeStyles((theme) => ({
  checkout: {
    width: 400,
  },
  label: {
    padding: theme.spacing(2, 0, 1),
  },
}))

interface Props {
  onSuccess: () => void
  onCancel: () => void
}

interface EnhancedProps {
  stripe: {
    createToken: ({
      name,
    }: {
      name: string
    }) => Promise<{ token: { id: string } }>
  }
}

const UpdateForm: FunctionComponent<Props & EnhancedProps> = (props) => {
  const [complete, setComplete] = useState(false)
  const [stateError, setError] = useState<string | undefined>(undefined)
  const { stripe, onSuccess, onCancel } = props
  const classes = useStyles(props)
  const [mutate, { loading }] = useMutation()
  const notify = useMaybeNotify()
  const refresh = useRefresh()

  const onChange = (params: ReactStripeElements.ElementChangeResponse) => {
    setComplete(params.complete)
    setError(params.error && params.error.message)
  }

  const submit = () => {
    // TODO: name is not used
    // TODO: how does createToken get the card info?
    return stripe.createToken({ name: 'Name' }).then(({ token }) => {
      if (!token) {
        return {
          [FORM_ERROR]: 'Oops, something went wrong. Please try again.',
        }
      }
      return new Promise((resolve) => {
        mutate(
          {
            type: 'update',
            resource: 'payment_methods',
            payload: { id: token.id, data: { token: token.id } },
          },
          {
            onSuccess: () => {
              notify('Payment method updated')
              onSuccess()
              resolve()
              refresh()
            },
            onFailure: ({
              body: {
                // eslint-disable-next-line camelcase
                errors: { stripe_token },
              },
            }) => {
              resolve({
                [FORM_ERROR]: stripe_token.join('. '),
              })
            },
          }
        )
      })
    })
  }

  return (
    <div className={classes.checkout}>
      <Form
        onSubmit={submit}
        render={({ handleSubmit, submitting, submitError }) => {
          const error = stateError || submitError

          return (
            <form onSubmit={handleSubmit}>
              <DialogTitle id="form-dialog-title">
                Update Payment Method
              </DialogTitle>
              <DialogContent>
                <div className={classes.label}>
                  Enter your card details below
                </div>
                <CardElement
                  disabled={loading || submitting}
                  onChange={onChange}
                />
                {error && (
                  <FormHelperText error>
                    <ErrorComponent>{error}</ErrorComponent>
                  </FormHelperText>
                )}
              </DialogContent>
              <DialogActions>
                <CancelButton onClick={onCancel} />
                <SubmitButton
                  // let CardElement tell us when to disable the button
                  hasValidationErrors={!complete}
                  hasSubmitErrors={false}
                  modifiedSinceLastSubmit={false}
                  pristine={false}
                  submitting={submitting}
                  label="Update"
                  variant="contained"
                  size="small"
                />
              </DialogActions>
            </form>
          )
        }}
      />
    </div>
  )
}

export default compose<Props & EnhancedProps, Props>(injectStripe)(UpdateForm)
