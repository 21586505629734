import React, { FunctionComponent } from 'react'
import { useMutation, useRedirect, useRefresh } from 'ra-core'
import { FORM_ERROR } from 'final-form'
import { extractError } from '../../../../../../shared/errorExtractors'
import InputConfirmationDialog from '../../../../../../shared/components/InputConfirmationDialog'
import useMaybeNotify from '../../../../../../shared/hooks/useMaybeNotify'

interface Props {
  onSuccess: () => void
  onCancel: () => void
  appId: string
  id: string
  backupId: string
}

const RestoreDialog: FunctionComponent<Props> = (props) => {
  const { onSuccess, onCancel, appId, id, backupId } = props

  const [mutate] = useMutation()
  const notify = useMaybeNotify()
  const redirect = useRedirect()
  const refresh = useRefresh()

  const onRestore = (
    value: string,
    resolve: <T>(value?: T | PromiseLike<T>) => void
  ): void => {
    mutate(
      {
        type: 'RESTORE',
        resource: 'databases',
        payload: { appId, id, backupId },
      },
      {
        onSuccess: () => {
          notify(
            'Database restore is now pending. Please refresh to check the status of the operation.'
          )
          onSuccess()
          redirect(`/apps/${appId}/databases/info`)
          refresh()
          resolve()
        },
        onFailure: ({ body: { errors } }) => {
          resolve<Record<string, string>>({
            [FORM_ERROR]: extractError(errors, ''),
          })
        },
      }
    )
  }

  return (
    <InputConfirmationDialog
      onCancel={onCancel}
      title="Restore database from backup"
      warning="Restoring from backup cannot be undone"
      fieldLabel="Database Name"
      submitLabel="Restore"
      onSubmit={onRestore}
      initialValue={id}
      validationMessage="Incorrect database id"
    />
  )
}

export default RestoreDialog
