import React, { ReactNode, FunctionComponent } from 'react'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core'

interface Props {
  className?: string
  children: ReactNode | ReactNode[]
}

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    marginTop: theme.spacing(2),
    height: `calc(100% - ${theme.spacing(10)}px)`,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2, 3, 3),
  },
}))

const TableWrapper: FunctionComponent<Props> = ({ className, children }) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.tableWrapper, className)}>
      {children}
    </div>
  )
}

export default TableWrapper
