import React, { FunctionComponent } from 'react'
import { Button, CircularProgress, makeStyles, Theme } from '@material-ui/core'

interface Props {
  hasValidationErrors: boolean
  hasSubmitErrors: boolean
  modifiedSinceLastSubmit: boolean
  pristine: boolean
  submitting: boolean
  label: string
  className?: string
  variant?: 'text' | 'outlined' | 'contained' | undefined
  color?: 'inherit' | 'primary' | 'secondary' | 'default' | undefined
  size?: 'small' | 'medium' | 'large' | undefined
}

const useStyles = makeStyles(({ spacing }: Theme) => ({
  icon: {
    marginRight: spacing(),
  },
}))

const SubmitButton: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props)
  const {
    variant,
    hasValidationErrors,
    hasSubmitErrors,
    modifiedSinceLastSubmit,
    pristine,
    submitting,
    label,
    color,
    size,
    className,
  } = props
  const invalid =
    hasValidationErrors || (hasSubmitErrors && !modifiedSinceLastSubmit)
  return (
    <Button
      type="submit"
      disabled={invalid || pristine || submitting}
      color={color || 'primary'}
      size={size}
      variant={variant}
      className={className}
    >
      {submitting && (
        <CircularProgress className={classes.icon} size={18} thickness={2} />
      )}
      {label}
    </Button>
  )
}

export default SubmitButton
