import * as api from '../../../shared/api'

interface ActivityBase {
  action: string
}

export interface Activity extends ActivityBase {
  createdAt: Date
  properties: Record<string, string | number>
}

interface ActivityResponse extends ActivityBase {
  created_at: string // eslint-disable-line camelcase
  [prop: string]: string | number
}

export const list = (appId: string): Promise<{ data: Activity[] }> => {
  return api
    .get<{ data: ActivityResponse[] }>(`/frontend/api/apps/${appId}/activity`)
    .then((response): { data: Activity[] } => {
      const _activities = response.data.data
      const activities: Activity[] = _activities.reverse().map((_activity) => {
        const { action, created_at, ...rest } = _activity // eslint-disable-line camelcase
        return {
          action,
          createdAt: new Date(created_at),
          properties:
            rest &&
            Object.keys(rest).reduce(
              (acc: Record<string, string | number>, item) => {
                if (rest[item] !== undefined) {
                  acc[item] = rest[item]
                }
                return acc
              },
              {}
            ),
        }
      })
      return { data: activities }
    })
}

export const rollback = (
  appId: string,
  version: number
): Promise<{ data: string }> => {
  return api
    .post<{ data: string }>(
      `/frontend/api/apps/${appId}/releases/${version}/rollback`,
      {}
    )
    .then((response): { data: string } => {
      const message = response.data.data
      return { data: message }
    })
}
