import React, { FunctionComponent } from 'react'
import { useMutation, useRefresh } from 'ra-core'
import { FORM_ERROR } from 'final-form'
import { Field, Form } from 'react-final-form'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  makeStyles,
} from '@material-ui/core'
import {
  extractError,
  extractErrorValue,
} from '../../../shared/errorExtractors'
import { renderTextField } from '../../../shared/components/formFields'
import ErrorComponent from '../../../shared/components/ErrorComponent'
import CancelButton from '../../../shared/components/CancelButton'
import SubmitButton from '../../../shared/components/SubmitButton'
import useMaybeNotify from '../../../shared/hooks/useMaybeNotify'
import {
  composeValidators,
  required,
  minValue,
} from '../../../utils/validators'

const validateSshKey = composeValidators([
  required(),
  minValue(200, 'should be at least 200 characters'),
])

const renderSshKeyField = renderTextField({ type: 'input' })

interface Props {
  onSuccess: () => void
  onCancel: () => void
}

interface FormData {
  sshKey: string
}

const useStyles = makeStyles({
  form: {
    width: 560,
  },
})

const CreateDialog: FunctionComponent<Props> = (props) => {
  const { onSuccess, onCancel } = props
  const classes = useStyles()

  const [mutate] = useMutation()
  const notify = useMaybeNotify()
  const refresh = useRefresh()

  const submit = (values: FormData) => {
    return new Promise((resolve) => {
      mutate(
        {
          type: 'create',
          resource: 'ssh_keys',
          payload: { data: values },
        },
        {
          onSuccess: () => {
            notify('SSH Key added')
            onSuccess()
            resolve()
            refresh()
          },
          onFailure: ({ body: { errors } }) => {
            resolve({
              [FORM_ERROR]: extractError(errors, ''),
              sshKey: extractErrorValue(errors, 'key'),
            })
          },
        }
      )
    })
  }
  return (
    <Form
      onSubmit={submit}
      render={({
        handleSubmit,
        submitError,
        hasSubmitErrors,
        hasValidationErrors,
        modifiedSinceLastSubmit,
        pristine,
        submitting,
      }) => {
        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            <DialogTitle id="form-dialog-title">Add SSH Key</DialogTitle>
            {submitError && (
              <DialogContent>
                <FormHelperText error>
                  <ErrorComponent>{submitError}</ErrorComponent>
                </FormHelperText>
              </DialogContent>
            )}
            <DialogContent>
              <Field
                validate={validateSshKey}
                component={renderSshKeyField}
                name="sshKey"
                label="SSH Key"
                multiline
                rows={10}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <CancelButton onClick={onCancel} />
              <SubmitButton
                {...{
                  hasValidationErrors,
                  hasSubmitErrors,
                  modifiedSinceLastSubmit,
                  pristine,
                  submitting,
                }}
                label="Add"
                variant="contained"
                size="small"
              />
            </DialogActions>
          </form>
        )
      }}
    />
  )
}

export default CreateDialog
