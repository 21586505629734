import React, { ReactNode, FunctionComponent } from 'react'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core'

interface Props {
  className?: string
  children: ReactNode | ReactNode[]
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    height: `calc(100% - calc(58.5px + ${theme.spacing(3)}px))`, // 58.5 = height of tab bar
  },
}))

const PageRoot: FunctionComponent<Props> = ({ className, children }) => {
  const classes = useStyles()
  return <div className={classnames(classes.root, className)}>{children}</div>
}

export default PageRoot
