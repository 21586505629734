import * as api from '../../../../shared/api'

interface Status {
  exitCode: number
  reason: string
  startedAt: Date
  finishedAt: Date
}
interface PodStatus extends Status {
  containerId: string
}

interface PodStatusResponse extends Status {
  containerID: string
}

interface Pod {
  name: string
  status: string
  sha: string
}

interface AppStatusPod extends Pod {
  version: number
  lastState?: Record<string, PodStatus>
}

interface AppStatusPodResponse extends Pod {
  version: string
  lastState?: Record<string, PodStatusResponse>
}

export interface AppStatus {
  id: string
  stack?: string
  size: number
  replicasRunning: number
  replicasDesired: number
  region: string
  pods: AppStatusPod[]
  cloud: string
}

interface AppStatusResponse {
  unique_name: string // eslint-disable-line camelcase
  stack?: string
  size: number
  replicas_running: number // eslint-disable-line camelcase
  replicas_desired: number // eslint-disable-line camelcase
  region: string
  pods: AppStatusPodResponse[]
  cloud: string
}

export const get = (id: string): Promise<{ data: AppStatus }> => {
  return api
    .get<{ data: AppStatusResponse }>(`/frontend/api/apps/${id}/status`)
    .then((response): { data: AppStatus } => {
      const {
        unique_name, // eslint-disable-line camelcase
        replicas_running, // eslint-disable-line camelcase
        replicas_desired, // eslint-disable-line camelcase
        pods,
        ...others
      } = response.data.data
      return {
        data: {
          id: unique_name,
          replicasRunning: replicas_running,
          replicasDesired: replicas_desired,
          pods: pods.map((pod) => {
            const { version, lastState, ...restPod } = pod
            let _lastState: Record<string, PodStatus> | undefined
            if (lastState) {
              _lastState = Object.keys(lastState).reduce(
                (acc: Record<string, PodStatus>, key: string) => {
                  const values: PodStatusResponse = lastState[key]
                  const {
                    containerID,
                    startedAt,
                    finishedAt,
                    ...restStatus
                  } = values
                  acc[key] = {
                    ...restStatus,
                    containerId: containerID,
                    startedAt: new Date(startedAt),
                    finishedAt: new Date(finishedAt),
                  }
                  return acc
                },
                {}
              )
            }

            return {
              ...restPod,
              version: parseInt(version, 10),
              lastState: _lastState,
            }
          }),
          ...others,
        },
      }
    })
}
