import * as permissions from './actions'
import {
  CreatePermissionParams,
  DeletePermissionParams,
} from './dataProviderParams'
import { CreateParams } from '../../../shared/dataProvider/interfaces'
import { isGetOne } from '../../../shared/dataProvider/typeCheckers'
import { Method, Resource, Params } from '../../../shared/dataProvider/types'

const isCreatePermission = (
  method: Method,
  resource: Resource,
  params: Params
): params is CreateParams<CreatePermissionParams> =>
  method === 'CREATE' && resource === 'permissions'

const isDeletePermissions = (
  method: Method,
  resource: Resource,
  params: Params
): params is DeletePermissionParams =>
  method === 'DELETE' && resource === 'permissions'

const provider = (
  method: Method,
  resource: Resource,
  params: Params
): Promise<unknown> => {
  if (isDeletePermissions(method, resource, params)) {
    return permissions.del(params.id, params.email)
  }
  if (isCreatePermission(method, resource, params)) {
    const { email, id } = params.data
    return permissions.create(id, email)
  }
  if (isGetOne(method, resource, params)) {
    return permissions.get(params.id)
  }
  throw new Error(`${method} ${resource} not implemented yet`)
}

export default provider
