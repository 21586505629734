import React, { FunctionComponent, ReactNode } from 'react'
import { makeStyles, Button } from '@material-ui/core'

interface Props {
  onClick: () => void
  children?: ReactNode
}

const useStyles = makeStyles({
  cancel: {
    backgroundColor: '#777',
    '&:hover': {
      backgroundColor: '#535353',
    },
  },
})

const CancelButton: FunctionComponent<Props> = ({ onClick, children }) => {
  const classes = useStyles()
  return (
    <Button
      onClick={onClick}
      variant="contained"
      color="primary"
      size="small"
      className={classes.cancel}
    >
      {children || 'Cancel'}
    </Button>
  )
}

export default CancelButton
