import React, { FunctionComponent } from 'react'
import { useMutation, useRefresh } from 'ra-core'
import { FORM_ERROR } from 'final-form'
import { Field, Form } from 'react-final-form'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  FormControlLabel,
  Radio,
  makeStyles,
  FormControl,
} from '@material-ui/core'
import { App, Stack } from './actions'
import { extractError } from '../../../shared/errorExtractors'
import ErrorComponent from '../../../shared/components/ErrorComponent'
import { renderRadioGroup } from '../../../shared/components/formFields'
import CancelButton from '../../../shared/components/CancelButton'
import SubmitButton from '../../../shared/components/SubmitButton'
import useMaybeNotify from '../../../shared/hooks/useMaybeNotify'

const renderStackField = renderRadioGroup

interface Props {
  onSuccess: () => void
  onCancel: () => void
  app: App
}

interface FormData {
  stack: Stack
}

const useStyles = makeStyles((theme) => ({
  // duplicated in Field.tsx
  label: {
    color: 'rgba(0,0,0,0.5)',
    padding: theme.spacing(2, 0, 1),
  },
}))

const AppEditDialog: FunctionComponent<Props> = (props) => {
  const { onSuccess, onCancel, app } = props
  const classes = useStyles()

  const [mutate] = useMutation()
  const notify = useMaybeNotify()
  const refresh = useRefresh()

  const submit = ({ stack }: FormData) => {
    const newApp = {
      ...app,
      stack,
    }
    return new Promise((resolve) => {
      mutate(
        {
          type: 'update',
          resource: 'stack',
          payload: { id: app.id, data: newApp, previousData: app },
        },
        {
          onSuccess: () => {
            notify('App updated')
            onSuccess()
            resolve()
            refresh()
          },
          onFailure: ({ body: { errors } }) => {
            resolve({
              [FORM_ERROR]: extractError(errors, ''),
              stack: extractError(errors, 'stack'),
            })
          },
        }
      )
    })
  }
  return (
    <Form
      onSubmit={submit}
      render={({
        handleSubmit,
        submitError,
        hasSubmitErrors,
        hasValidationErrors,
        modifiedSinceLastSubmit,
        pristine,
        submitting,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">Edit App</DialogTitle>
            {submitError && (
              <DialogContent>
                <FormHelperText error>
                  <ErrorComponent>{submitError}</ErrorComponent>
                </FormHelperText>
              </DialogContent>
            )}
            <DialogContent>
              <FormControl>
                <div className={classes.label}>Stack</div>
                <Field
                  name="stack"
                  label="Stack"
                  component={renderStackField}
                  initialValue={app.stack}
                >
                  <FormControlLabel
                    value="gigalixir-14"
                    control={<Radio color="primary" />}
                    label="gigalixir-14 (end-of-life)"
                  />
                  <FormControlLabel
                    value="gigalixir-16"
                    control={<Radio color="primary" />}
                    label="gigalixir-16 (end-of-life)"
                  />
                  <FormControlLabel
                    value="gigalixir-18"
                    control={<Radio color="primary" />}
                    label="gigalixir-18 (end-of-life: 6/30/2024)"
                  />
                  <FormControlLabel
                    value="gigalixir-20"
                    control={<Radio color="primary" />}
                    label="gigalixir-20"
                  />
                  <FormControlLabel
                    value="gigalixir-22"
                    control={<Radio color="primary" />}
                    label="gigalixir-22"
                  />
                  <FormControlLabel
                    value="gigalixir-24"
                    control={<Radio color="primary" />}
                    label="gigalixir-24"
                  />
                </Field>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <CancelButton onClick={onCancel} />
              <SubmitButton
                {...{
                  hasValidationErrors,
                  hasSubmitErrors,
                  modifiedSinceLastSubmit,
                  pristine,
                  submitting,
                }}
                label="Update"
                variant="contained"
                size="small"
              />
            </DialogActions>
          </form>
        )
      }}
    />
  )
}

export default AppEditDialog
