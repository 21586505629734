import React, { FunctionComponent } from 'react'
import { useMutation, useRedirect } from 'ra-core'
import { FORM_ERROR } from 'final-form'
import { Field, Form } from 'react-final-form'
import {
  makeStyles,
  FormHelperText,
  InputAdornment,
  SvgIcon,
} from '@material-ui/core'
import styles from '../authStyles'
import { renderInputField as renderInput } from '../../../shared/components/formFields'
import ErrorComponent from '../../../shared/components/ErrorComponent'
import SubmitButton from '../../../shared/components/SubmitButton'
import { extractError } from '../../../shared/errorExtractors'
import { required } from '../../../utils/validators'
import { ReactComponent as EmailIcon } from '../../../icons/email.svg'

const validateEmail = required()

interface Props {
  email: string | undefined
}

interface FormData {
  email: string
}

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
}))

const ResendConfirmationForm: FunctionComponent<Props> = (props) => {
  const { email } = props
  const classes = useStyles(props)
  const [mutate] = useMutation()
  const redirect = useRedirect()

  const submit = (values: FormData) => {
    return new Promise((resolve) => {
      mutate(
        {
          type: 'update',
          resource: 'confirmation',
          payload: { data: values },
        },
        {
          onSuccess: () => {
            const msg = 'Confirmation email resent. Please check your email.'
            redirect(`/success?msg=${encodeURIComponent(msg)}`)
            resolve()
          },
          onFailure: ({ body: { errors } }) => {
            resolve({
              [FORM_ERROR]: extractError(errors, ''),
              email: extractError(errors, 'email'),
            })
          },
        }
      )
    })
  }

  return (
    <Form
      onSubmit={submit}
      render={({
        handleSubmit,
        submitError,
        hasValidationErrors,
        hasSubmitErrors,
        modifiedSinceLastSubmit,
        pristine,
        submitting,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className={classes.form}>
              {submitError && (
                <FormHelperText error>
                  <ErrorComponent>{submitError}</ErrorComponent>
                </FormHelperText>
              )}
              <div className={classes.input}>
                <Field
                  id="email"
                  name="email"
                  validate={validateEmail}
                  component={renderInput}
                  placeholder="Email"
                  defaultValue={email}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon className={classes.icon}>
                          <EmailIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <SubmitButton
                {...{
                  hasValidationErrors,
                  hasSubmitErrors,
                  modifiedSinceLastSubmit,
                  pristine,
                  submitting,
                }}
                color="primary"
                label="Resend Confirmation"
                variant="contained"
                className={classes.button}
              />
            </div>
          </form>
        )
      }}
    />
  )
}

export default ResendConfirmationForm
