import React, { FunctionComponent } from 'react'
import { useMutation, useRefresh } from 'ra-core'
import { FORM_ERROR } from 'final-form'
import { Field, Form } from 'react-final-form'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  makeStyles,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import {
  extractError,
  extractErrorValue,
} from '../../../shared/errorExtractors'
import { renderTextField } from '../../../shared/components/formFields'
import ErrorComponent from '../../../shared/components/ErrorComponent'
import CancelButton from '../../../shared/components/CancelButton'
import SubmitButton from '../../../shared/components/SubmitButton'
import useMaybeNotify from '../../../shared/hooks/useMaybeNotify'
import {
  composeValidators,
  required,
  pattern,
  regExpPatterns,
} from '../../../utils/validators'

const validateName = composeValidators([
  required(),
  pattern(regExpPatterns.domain, 'Invalid Domain'),
])

const renderNameField = renderTextField({ type: 'input' })

interface Props {
  onSuccess: () => void
  onCancel: () => void
  appId: string
}

interface FormData {
  fqdn: string
}

const useStyles = makeStyles((theme) => ({
  alert: {
    marginTop: theme.spacing(2),
  },
}))

const DomainDialog: FunctionComponent<Props> = (props) => {
  const { onSuccess, onCancel, appId } = props

  const [mutate] = useMutation()
  const notify = useMaybeNotify()
  const refresh = useRefresh()

  const classes = useStyles()

  const submit = ({ fqdn }: FormData) => {
    const newDomain = {
      appId,
      fqdn,
    }
    return new Promise((resolve) => {
      mutate(
        {
          type: 'create',
          resource: 'domains',
          payload: { data: newDomain },
        },
        {
          onSuccess: () => {
            notify('Domain added')
            onSuccess()
            resolve()
            refresh()
          },
          onFailure: ({ body: { errors } }) => {
            resolve({
              [FORM_ERROR]: extractError(errors, ''),
              fqdn: extractErrorValue(errors, 'fully_qualified_domain_name'),
            })
          },
        }
      )
    })
  }
  return (
    <Form
      onSubmit={submit}
      render={({
        handleSubmit,
        submitError,
        hasSubmitErrors,
        hasValidationErrors,
        modifiedSinceLastSubmit,
        pristine,
        submitting,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">Add Domain</DialogTitle>
            {submitError && (
              <DialogContent>
                <FormHelperText error>
                  <ErrorComponent>{submitError}</ErrorComponent>
                </FormHelperText>
              </DialogContent>
            )}
            <DialogContent>
              <Alert className={classes.alert} severity="info">
                <strong>Tip:</strong> If you want both your apex domain and a
                subdomain like www, you must add them both separately.
              </Alert>
            </DialogContent>
            <DialogContent>
              <Field
                validate={validateName}
                component={renderNameField}
                name="fqdn"
                label="Fully Qualified Domain Name"
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <CancelButton onClick={onCancel} />
              <SubmitButton
                {...{
                  hasValidationErrors,
                  hasSubmitErrors,
                  modifiedSinceLastSubmit,
                  pristine,
                  submitting,
                }}
                label="Add"
                variant="contained"
                size="small"
              />
            </DialogActions>
          </form>
        )
      }}
    />
  )
}

export default DomainDialog
