import * as api from '../../../shared/api'

export interface Canary {
  canary: string
  weight: number
}

export const get = (appId: string): Promise<{ data: Canary }> => {
  return api
    .get<{ data: Canary }>(`/frontend/api/apps/${appId}/canaries`)
    .then((response): { data: Canary } => {
      const canary = response.data.data
      return { data: canary }
    })
}

export const update = (
  appId: string,
  canary: string,
  weight: number
): Promise<{ data: Record<string, unknown> }> => {
  return api
    .put<{ data: Record<string, unknown> }>(
      `/frontend/api/apps/${appId}/canaries`,
      {
        canary,
        weight,
      }
    )
    .then(() => ({
      data: {},
    }))
}

export const del = (
  appId: string,
  canary: string
): Promise<{ data: Record<string, unknown> }> => {
  return api
    .del<{ data: Record<string, unknown> }>(
      `/frontend/api/apps/${appId}/canaries/${canary}`
    )
    .then(() => ({
      data: {},
    }))
}
