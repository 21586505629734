import React, { FunctionComponent } from 'react'

const App: FunctionComponent<Record<string, unknown>> = () => {
  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <div
        style={{
          whiteSpace: 'nowrap',
          overflow: 'scroll',
        }}
      >
        aoeu ao eusantoehu sntahoeu santeou asntoeu asntoeaoh unsahutsanoe
        tunsoeh nuastho eusntheo usntaho snt sntoeu nsatoeh usntaoeunso eusneaoh
        anseoh asnouh aesountha sonethu asnethu asnteasnetohu astneohu stanoeu
        sanetoh en asoentuh asnoetuh asntoehu nsatoehu snatoheu stnaoehu
        snaoetuhs
      </div>
    </div>
  )
}

export default App
