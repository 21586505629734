import * as api from '../../../shared/api'

interface LogDrainInfo {
  token: string
  url: string
}

export interface LogDrain extends LogDrainInfo {
  id: string
}

interface ServerResponse extends LogDrainInfo {
  id: number
}

export const list = (appId: string): Promise<{ data: LogDrain[] }> => {
  return api
    .get<{ data: ServerResponse[] }>(`/frontend/api/apps/${appId}/drains`)
    .then((response): { data: LogDrain[] } => {
      const logDrains = response.data.data
      return {
        data: logDrains.map((logDrain) => {
          const { id, ...rest } = logDrain
          return {
            ...rest,
            id: `${id}`,
          }
        }),
      }
    })
}

export const create = (
  appId: string,
  url: string
): Promise<{ data: Record<string, unknown> }> => {
  return api
    .post<{ data: string }>(`/frontend/api/apps/${appId}/drains`, { url })
    .then(() => ({
      data: {},
    }))
}

export const del = (
  appId: string,
  id: string
): Promise<{ data: Record<string, unknown> }> => {
  return api
    .del<{ data: Record<string, unknown> }>(
      `/frontend/api/apps/${appId}/drains`,
      { drain_id: id }
    )
    .then(() => ({
      data: {},
    }))
}
