import classnames from 'classnames'
import React, { FunctionComponent } from 'react'
import { useQueryWithStore } from 'ra-core'
import { makeStyles } from '@material-ui/core'
import ScaleDialog from '../ScaleDialog'
import { AppStatus } from './actions'
import { App } from '../actions'
import Loading from '../../../../shared/components/Loading'
import ErrorComponent from '../../../../shared/components/ErrorComponent'
import NoData from '../../../../shared/components/NoData'
import DialogButton from '../../../../shared/components/DialogButton'
import Field from '../../../../shared/components/Field'
import dateFormatter from '../../../../utils/formatters/dateFormatters'

interface Props {
  app: App
}

const useStyles = makeStyles((theme) => ({
  loading: {
    margin: theme.spacing(4, 0),
  },
  replicasSection: {},
  title: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(15),
    color: '#535353',
    textTransform: 'uppercase',
  },
  replicas: {},
  pod: {
    display: 'flex',
    borderBottom: `${theme.spacing(0.1)}px solid #eee`,
    padding: theme.spacing(3, 0),
    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: 'unset',
    },
  },
  podLeft: {
    paddingRight: theme.spacing(3),
    flex: 1,
  },
  podRight: {
    paddingLeft: theme.spacing(3),
    flex: 2.05,
  },
  subHeading: {
    display: 'block',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    color: '#535353',
    textTransform: 'uppercase',
    paddingBottom: theme.spacing(1.25),
  },
  chip: {
    display: 'inline-block',
    padding: theme.spacing(0.2, 1),
    borderRadius: 10,
    backgroundColor: '#ff9494',
  },
  healthy: {
    backgroundColor: '#bcec8e',
  },
}))

const Replicas: FunctionComponent<Props> = (props) => {
  const { app } = props
  const {
    data: appStatus,
    loading,
    error,
  }: {
    data?: AppStatus
    loading: boolean
    error?: Error
  } = useQueryWithStore({
    type: 'getOne',
    resource: 'app_status',
    payload: {
      id: app.id,
    },
  })

  const classes = useStyles()

  if (loading) {
    return <Loading className={classes.loading} />
  }

  if (error) {
    return <ErrorComponent>{error.message}</ErrorComponent>
  }

  if (!appStatus) {
    throw new Error('App Status cannot be null!')
  }

  if (!(appStatus.pods?.length > 0)) {
    return (
      <NoData message="No replicas running. Try scaling to at least 1 replica to see data here. Replicas are the containers or instances that run your app.">
        <DialogButton label="Scale">
          {(close) => (
            <ScaleDialog
              app={app}
              onSuccess={() => close()}
              onCancel={() => close()}
            />
          )}
        </DialogButton>
      </NoData>
    )
  }

  return (
    <div className={classes.replicasSection}>
      <span className={classes.title}>Replicas</span>
      <div className={classes.replicas}>
        {appStatus.pods?.map((pod) => (
          <div key={pod.name} className={classes.pod}>
            <div className={classes.podLeft}>
              <span className={classes.subHeading}>Status</span>
              <Field label="Name" isHorizontal isTabular>
                {pod.name}
              </Field>
              <Field label="SHA" isHorizontal isTabular>
                {pod.sha?.slice(0, 6)}
              </Field>
              <Field label="Status" isHorizontal isTabular>
                <span
                  className={classnames(classes.chip, {
                    [classes.healthy]: pod.status === 'Healthy',
                  })}
                >
                  {pod.status}
                </span>
              </Field>
              <Field label="Version" isHorizontal isTabular>
                {pod.version}
              </Field>
            </div>
            {pod.lastState &&
              Object.keys(pod.lastState).map((status) => {
                const { exitCode, reason, startedAt, finishedAt } =
                  (pod.lastState && pod.lastState[status]) || {}
                return (
                  <div key={status} className={classes.podRight}>
                    <span className={classes.subHeading}>Last State</span>
                    <Field label="Status" isHorizontal isTabular>
                      {status}
                    </Field>
                    <Field label="Exit Code" isHorizontal isTabular>
                      {exitCode}
                    </Field>
                    <Field label="Reason" isHorizontal isTabular>
                      <span
                        className={classnames(classes.chip, {
                          [classes.healthy]: reason === 'Completed',
                        })}
                      >
                        {reason}
                      </span>
                    </Field>
                    <Field label="Started At" isHorizontal isTabular>
                      {dateFormatter(startedAt)}
                    </Field>
                    <Field label="Finished At" isHorizontal isTabular>
                      {dateFormatter(finishedAt)}
                    </Field>
                  </div>
                )
              })}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Replicas
