import qs from 'query-string'
import React, { FunctionComponent } from 'react'
import Layout from '../Layout'

const OauthCli: FunctionComponent<{ location: { search: string } }> = (
  props
) => {
  const { location } = props
  const params = qs.parse(location.search)

  let status = "Completed Successfully"
  if (params.failed) {
    status = "Failed to Complete"
  }

  return (
    <Layout>
      <div>
        <h1>CLI Login</h1>
        <h3>{status}</h3>
        <p>Return to the terminal for details</p>
      </div>
    </Layout>
  )
}

export default OauthCli
