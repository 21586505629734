import React, { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/core'
import LimitedAt from './LimitedAt'
import Field from '../Field'
import { FreeDatabase, StandardDatabase } from '../../../actions'

const useStyles = makeStyles({
  container: {
    marginTop: 20,
  },
})

interface Props {
  database: StandardDatabase | FreeDatabase
}

const Info: FunctionComponent<Props> = (props) => {
  const { database } = props

  const classes = useStyles()

  return (
    <div className={classes.container}>
      {database.tier === 'FREE' && database.limitedAt !== null && (
        <LimitedAt limitedAt={database.limitedAt} />
      )}
      <Field leftSide="ID" rightSide={database.id} />
      <Field leftSide="Host" rightSide={database.host} />
      <Field leftSide="Port" rightSide={database.port} />
      <Field leftSide="Username" rightSide={database.username} />
      <Field leftSide="Password" rightSide={database.password} />
      <Field leftSide="Database" rightSide={database.database} />
      <Field leftSide="State" rightSide={database.state} />
      {database.tier === 'STANDARD' ? (
        <>
          <Field
            leftSide="High availability"
            rightSide={`${
              database.highAvailability === 'enabled' ? 'Enabled' : 'Disabled'
            }`}
          />
          <Field leftSide="Cloud" rightSide={database.cloud} />
          <Field leftSide="Region" rightSide={database.region} />
          <Field leftSide="Size" rightSide={database.size} />
          <Field leftSide="Version" rightSide={database.version} />
        </>
      ) : (
        <span />
      )}

      <Field leftSide="URL" rightSide={database.url} />
    </div>
  )
}

export default Info
