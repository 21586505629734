import React, { FunctionComponent, useState } from 'react'
import compose from 'recompose/compose'
import { useMutation, useRefresh } from 'ra-core'
import { FORM_ERROR } from 'final-form'
import { Field, Form } from 'react-final-form'
import { makeStyles, FormHelperText } from '@material-ui/core'
import {
  CardElement,
  injectStripe,
  ReactStripeElements,
} from 'react-stripe-elements'
import { extractError } from '../../../shared/errorExtractors'
import ErrorComponent from '../../../shared/components/ErrorComponent'
import SubmitButton from '../../../shared/components/SubmitButton'
import useMaybeNotify from '../../../shared/hooks/useMaybeNotify'
import { renderInputField as renderInput } from '../../../shared/components/formFields'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FormData {
  promoCode: string
}

interface EnhancedProps {
  stripe: {
    createToken: ({
      name,
    }: {
      name: string
    }) => Promise<{ token: { id: string } }>
  }
}

const useStyles = makeStyles((theme) => ({
  checkout: {
    marginTop: theme.spacing(2),
    maxWidth: theme.spacing(35),
  },
  form: {
    marginTop: theme.spacing(),
  },
  spacer: {
    marginTop: theme.spacing(2),
  },
  input: {
    padding: `0 0 15px`,
    '&+fieldset': {
      borderColor: 'transparent',
    },
  },
  card: {
    backgroundColor: '#f2f2f2',
    padding: theme.spacing(0.7, 0, 0.7, 1),
  },
}))

const UpgradeForm: FunctionComponent<Props & EnhancedProps> = (props) => {
  const [complete, setComplete] = useState(false)
  const [stateError, setError] = useState<string | undefined>(undefined)
  const { stripe } = props
  const classes = useStyles(props)
  const [mutate, { loading }] = useMutation()
  const notify = useMaybeNotify()
  const refresh = useRefresh()

  const onChange = (params: ReactStripeElements.ElementChangeResponse) => {
    setComplete(params.complete)
    setError(params.error && params.error.message)
  }

  const submit = (values: FormData) => {
    return stripe.createToken({ name: 'Name' }).then(({ token }) => {
      if (!token) {
        return {
          [FORM_ERROR]: 'Oops, something went wrong. Please try again.',
        }
      }
      return new Promise((resolve) => {
        mutate(
          {
            type: 'update',
            resource: 'account',
            payload: { id: token.id, data: { token: token.id, promoCode: values.promoCode } },
          },
          {
            onSuccess: () => {
              notify('Account upgraded')
              resolve()
              refresh()
            },
            onFailure: ({ body: { errors } }) => {
              // TODO: gross, this and UpdatePaymentMethodForm are ever so slightly different
              // this returns errors under '', the other uses 'stripe_token'
              resolve({
                [FORM_ERROR]: extractError(errors, ''),
                promoCode: extractError(errors, 'promo_code'),
              })
            },
          }
        )
      })
    })
  }

  return (
    <div className={classes.checkout}>
      <Form
        onSubmit={submit}
        render={({ handleSubmit, submitting, submitError }) => {
          // eslint-disable-next-line react/destructuring-assignment
          const error = stateError || submitError

          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <CardElement
                disabled={loading || submitting}
                onChange={onChange}
                classes={{
                  base: classes.card,
                }}
              />
              {error && (
                <FormHelperText error>
                  <ErrorComponent>{error}</ErrorComponent>
                </FormHelperText>
              )}
              <div className={classes.spacer} />
              <div className={classes.input}>
                <Field
                  id="promoCode"
                  name="promoCode"
                  component={renderInput}
                  fullWidth
                  placeholder="Promo Code (optional)"
                  variant="outlined"
                />
              </div>
              <SubmitButton
                // we let CardElement tell us when the button is disabled
                hasValidationErrors={!complete}
                hasSubmitErrors={false}
                modifiedSinceLastSubmit={false}
                pristine={false}
                submitting={submitting}
                variant="contained"
                label="Upgrade"
              />
            </form>
          )
        }}
      />
    </div>
  )
}

export default compose<Props & EnhancedProps, Props>(injectStripe)(UpgradeForm)
