import React, { FunctionComponent } from 'react'
import { useQueryWithStore } from 'ra-core'
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core'
import { Usage } from './actions'
import Loading from '../../../shared/components/Loading'
import ErrorComponent from '../../../shared/components/ErrorComponent'
import NoData from '../../../shared/components/NoData'
import TableWrapper from '../../../shared/components/TableWrapper'
import moneyFormatter from '../../../utils/formatters/moneyFormatters'

interface UsageData {
  type: string
  name: string
  amountCents: number
}

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    marginTop: 0,
    height: `calc(100% - ${theme.spacing(5)}px)`,
  },
}))

const UsageView: FunctionComponent = () => {
  const {
    data: usage,
    loading,
    error,
  }: {
    data?: Usage
    loading: boolean
    error?: Error
  } = useQueryWithStore({
    type: 'getList',
    resource: 'usage',
    payload: {},
  })

  const classes = useStyles()

  const usageData = React.useMemo<UsageData[]>(
    () =>
      [
        ...(usage?.databases.map(({ database, amountCents }) => ({
          type: 'Database',
          name: database,
          amountCents,
        })) ?? []),
        ...(usage?.apps.map(({ app, amountCents }) => ({
          type: 'App',
          name: app,
          amountCents,
        })) ?? []),
      ].sort((a, b) => a.name.localeCompare(b.name)),
    [usage]
  )

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <ErrorComponent>{error.message}</ErrorComponent>
  }

  if (usageData.length === 0) {
    return (
      <NoData
        note={
          <>
            <span>
              On the STANDARD tier, this shows you the current period
              accumulated usage so far. On the FREE tier, nothing will show
              here.
            </span>
            <a href="https://gigalixir.readthedocs.io/en/latest/billing.html#how-to-see-the-current-period-s-usage">
              Read More
            </a>
          </>
        }
        message="No usage yet"
      />
    )
  }

  return (
    <TableWrapper className={classes.tableWrapper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Name</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {usageData.map(({ type, name, amountCents }) => (
            <TableRow key={name}>
              <TableCell>{type}</TableCell>
              <TableCell>{name}</TableCell>
              <TableCell align="right">{moneyFormatter(amountCents)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  )
}

export default UsageView
