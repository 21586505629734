import React, { FunctionComponent } from 'react'
import { useMutation, useRedirect } from 'ra-core'
import { FORM_ERROR } from 'final-form'
import { Field, Form } from 'react-final-form'
import { makeStyles, FormHelperText, InputAdornment } from '@material-ui/core'
import LockIcon from '@material-ui/icons/Lock'
import styles from '../authStyles'
import { extractError } from '../../../shared/errorExtractors'
import {
  renderError,
  renderInputField,
} from '../../../shared/components/formFields'
import ErrorComponent from '../../../shared/components/ErrorComponent'
import SubmitButton from '../../../shared/components/SubmitButton'
import useMaybeNotify from '../../../shared/hooks/useMaybeNotify'
import { required } from '../../../utils/validators'

const renderPassword = renderInputField
const renderToken = renderError

const validatePassword = required()
const validateToken = required('Token not found')

interface Props {
  token?: string
}

interface FormData {
  token: string
  newPassword: string
}

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
}))

const SetPasswordForm: FunctionComponent<Props> = (props) => {
  const { token } = props
  const classes = useStyles(props)

  const [mutate] = useMutation()
  const redirect = useRedirect()
  const notify = useMaybeNotify()

  const submit = (values: FormData) => {
    return new Promise((resolve) => {
      mutate(
        {
          type: 'update',
          resource: 'password',
          payload: { data: values },
        },
        {
          onSuccess: () => {
            notify('Password set')
            redirect('/apps')
            resolve()
          },
          onFailure: ({ body: { errors } }) => {
            resolve({
              [FORM_ERROR]: extractError(errors, ''),
              token: extractError(errors, 'token'),
              newPassword: extractError(errors, 'password'),
            })
          },
        }
      )
    })
  }
  return (
    <Form
      onSubmit={submit}
      render={({
        handleSubmit,
        submitError,
        hasValidationErrors,
        hasSubmitErrors,
        modifiedSinceLastSubmit,
        pristine,
        submitting,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className={classes.form}>
              {submitError && (
                <FormHelperText error>
                  <ErrorComponent>{submitError}</ErrorComponent>
                </FormHelperText>
              )}
              <Field
                name="token"
                initialValue={token}
                validate={validateToken}
                component={renderToken}
              />
              <div className={classes.input}>
                <Field
                  id="newPassword"
                  name="newPassword"
                  validate={validatePassword}
                  component={renderPassword}
                  fullWidth
                  placeholder="New Password"
                  type="password"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <SubmitButton
                {...{
                  hasValidationErrors,
                  hasSubmitErrors,
                  modifiedSinceLastSubmit,
                  pristine,
                  submitting,
                }}
                color="primary"
                label="Set Password"
                variant="contained"
                className={classes.button}
              />
            </div>
          </form>
        )
      }}
    />
  )
}

export default SetPasswordForm
