import React, { FunctionComponent } from 'react'
import { useMutation, useRefresh } from 'ra-core'
import { FORM_ERROR } from 'final-form'
import { Field, Form } from 'react-final-form'
import {
  makeStyles,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
} from '@material-ui/core'
import SizeSlider, { sliderMarks as marks } from './SizeSlider'
import { FreeDatabase, StandardDatabase } from '../actions'
import {
  extractError,
  extractErrorValue,
} from '../../../../shared/errorExtractors'
import ErrorComponent from '../../../../shared/components/ErrorComponent'
import CancelButton from '../../../../shared/components/CancelButton'
import SubmitButton from '../../../../shared/components/SubmitButton'
import useMaybeNotify from '../../../../shared/hooks/useMaybeNotify'
import { renderSwitch } from '../../../../shared/components/formFields'

const renderHighAvailability = renderSwitch

interface Props {
  appId: string
  database: StandardDatabase | FreeDatabase
  onSuccess: () => void
  onCancel: () => void
}
interface FormData {
  size: number
  highAvailability: boolean
}
const useStyles = makeStyles((theme) => ({
  form: {
    minWidth: 600,
  },
  size: {
    marginRight: theme.spacing(3),
  },
  // duplicated in Field.tsx
  label: {
    color: 'rgba(0,0,0,0.5)',
    padding: theme.spacing(2, 0, 1),
  },
  text: {
    padding: theme.spacing(2, 0, 0),
    margin: 0,
  },
  highAvailability: {
    marginRight: theme.spacing(3),
    display: 'flex',
    alignItems: 'baseline',
    gap: `${theme.spacing(2)}px`,
  },
}))

const ScaleDialog: FunctionComponent<Props> = (props) => {
  const { appId, database, onCancel, onSuccess } = props
  const classes = useStyles(props)
  const refresh = useRefresh()
  const notify = useMaybeNotify()
  const [mutate] = useMutation()

  // value is used to space them apart
  // label is the label, but later we
  // use the label as the value to submit

  const submit = ({ size, highAvailability }: FormData) => {
    const updatedValues = {
      ...database,
      size:
        database.tier === 'STANDARD' &&
        (marks[size] && marks[size].label ? marks[size].label : null),
      highAvailability: highAvailability ? 'enabled' : 'disabled',
      appId,
    }
    return new Promise((resolve) => {
      mutate(
        {
          type: 'update',
          resource: 'databases',
          payload: {
            id: database.id,
            data: updatedValues,
            previousData: database,
          },
        },
        {
          onSuccess: () => {
            notify('Database scaled')
            onSuccess()
            resolve()
            refresh()
          },
          onFailure: ({ body: { errors } }) => {
            resolve({
              [FORM_ERROR]: extractError(errors, [
                '',
                'app_name',
                'app_id',
                'database_id',
              ]),
              size: extractErrorValue(errors, 'size'),
              highAvailability: extractErrorValue(errors, 'high_availability'),
            })
          },
        }
      )
    })
  }

  return (
    <Form
      onSubmit={submit}
      render={(formRenderProps) => {
        const {
          handleSubmit,
          submitError,
          submitting,
          hasValidationErrors,
          hasSubmitErrors,
          modifiedSinceLastSubmit,
          pristine,
        } = formRenderProps
        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            <DialogTitle id="form-dialog-title">Scale Database</DialogTitle>
            {database.tier === 'STANDARD' ? (
              <>
                {submitError && (
                  <DialogContent>
                    <FormHelperText error>
                      <ErrorComponent>{submitError}</ErrorComponent>
                    </FormHelperText>
                  </DialogContent>
                )}
                <DialogContent>
                  <div className={classes.size}>
                    <div className={classes.label}>Size (GB of Memory)</div>
                    <Field
                      name="size"
                      initialValue={
                        marks.find((mark) => mark.label === database.size)
                          ?.value ?? marks[0].value
                      }
                      component={SizeSlider}
                    />
                  </div>
                  <div className={classes.highAvailability}>
                    <div className={classes.label}>High Availability</div>
                    <Field
                      name="highAvailability"
                      initialValue={database.highAvailability === 'enabled'}
                      component={renderHighAvailability}
                    />
                  </div>
                </DialogContent>
                <DialogActions>
                  <CancelButton onClick={onCancel} />
                  <SubmitButton
                    {...{
                      hasValidationErrors,
                      hasSubmitErrors,
                      modifiedSinceLastSubmit,
                      pristine,
                      submitting,
                    }}
                    label="Scale"
                    variant="contained"
                    size="small"
                  />
                </DialogActions>
              </>
            ) : (
              <>
                <DialogContent>
                  <DialogContentText className={classes.text}>
                    Sorry, free tier databases can not be scaled. <br />
                    Please create a standard tier database.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <CancelButton onClick={onCancel}>Dismiss</CancelButton>
                </DialogActions>
              </>
            )}
          </form>
        )
      }}
    />
  )
}

export default ScaleDialog
