import * as configurations from './actions'
import {
  UpdateConfigurationParams,
  DeleteConfigurationParams,
} from './dataProviderParams'
import { UpdateParams } from '../../../shared/dataProvider/interfaces'
import { isGetListByID } from '../../../shared/dataProvider/typeCheckers'
import { Method, Resource, Params } from '../../../shared/dataProvider/types'

const isUpdateConfiguration = (
  method: Method,
  resource: Resource,
  params: Params
): params is UpdateParams<UpdateConfigurationParams> =>
  method === 'UPDATE' && resource === 'configurations'

const isDeleteConfiguration = (
  method: Method,
  resource: Resource,
  params: Params
): params is DeleteConfigurationParams =>
  method === 'DELETE' && resource === 'configurations'

const provider = (
  method: Method,
  resource: Resource,
  params: Params
): Promise<unknown> => {
  if (isDeleteConfiguration(method, resource, params)) {
    return configurations.del(params.appId, params.name)
  }
  if (isUpdateConfiguration(method, resource, params)) {
    const { name, value } = params.data
    return configurations.update(params.id, name, value)
  }
  if (isGetListByID(method, resource, params)) {
    return configurations.get(params.id)
  }
  throw new Error(`${method} ${resource} not implemented yet`)
}

export default provider
